import {
  Actions,
  Device,
  DeviceType,
  DeviceTypes,
  RuleAction,
} from '@edgeiq/edgeiq-api-js';
import { Grid, MenuItem, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import {
  optionsPaginationsFilter,
  LWM2M_FILE,
  LWM2M_REQUEST,
} from '../../../../../app/constants';
import {
  allowActions,
  mapGatewayCommandToAction,
} from '../../../../../helpers/permissions';
import {
  checkHasLwm2mDevices,
  checkMixedTypes,
  dispatchError,
} from '../../../../../helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { setOptionsDeviceTypes } from '../../../../../redux/reducers/deviceTypes.reducer';
import { RootState } from '../../../../../redux/store';
import DeviceTypeIconName from '../../../../../components/DeviceTypeIconName';
import Lwm2mGatewayCommands from '../../../../RightDrawer/IssueCommand/Lwm2mCommands';
import {
  actions,
  lwm2mActions,
} from '../../../../RightDrawer/IssueCommand/constants';
import SelectInput from '../../../../../components/SelectInput';
import useStyles from '../../styles';
interface GatewaySectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (
    prop: string,
    value: string | number | boolean | string[],
  ) => void;
  selectedDevices?: Device[];
}

const GatewaySection: React.FC<GatewaySectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  selectedDevices,
}) => {
  const [chosenCommand, setChosenCommand] = useState<keyof Actions | string>(
    '',
  );
  const [devices, setChosenDevice] = useState<string[]>([]);

  const [actionNotAllowed, setActionNotAllowed] = useState(false);
  const [unallowedDevices, setUnallowedDevices] = useState<Device[]>([]);
  const [unsupportedDevices, setUnsupportedDevices] = useState<Device[]>([]);
  const [lwm2mDevices, setLwm2mDevices] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );

  const userType = useAppSelector((state: RootState) => state.user.userType);
  const userCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const [mixedTypes, setMixedTypes] = useState(false);

  useEffect(() => {
    if (action.request?.attached_device_ids?.length) {
      setChosenDevice(action.request.attached_device_ids);
    }
  }, [action]);

  useEffect(() => {
    if (selectedDevices?.length) {
      onInputChange(
        `${actionIndex}.request.attached_device_ids`,
        selectedDevices.map((e) => e._id),
      );
      setChosenCommand(action.request?.command_type as keyof Actions);
    }
  }, [selectedDevices]);

  useEffect(() => {
    if (devicesTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const onCommandChange = (_prop: string, value: string | number): void => {
    resetValues();
    setChosenCommand(value as keyof Actions);
  };

  const resetValues = (): void => {
    setActionNotAllowed(false);
    setUnallowedDevices([]);
    setUnsupportedDevices([]);
  };

  const handleLwm2mInputChange = (
    prop: string,
    value: string | number,
  ): void => {
    onInputChange(`${actionIndex}.request.${prop}`, value);
  };

  const getDeviceType = (id: string): DeviceType | undefined =>
    devicesTypes.find((deviceType) => deviceType._id === id);

  const renderDevices = (list: Device[], preIndex?: string): JSX.Element => (
    <div className={clsx('scrollbar mb-4', classes.drawerSelectedDevices)}>
      {list.map((device) => (
        <div
          key={`${preIndex ? `${preIndex}-` : ''}${device._id}`}
          className={clsx('mb-2 br-1 p-2', classes.drawerDeviceContainer)}
        >
          <Typography variant="body2">{device.name}</Typography>
          <div className="d-flex flex-items-center">
            <DeviceTypeIconName
              labelVariant="body2"
              deviceType={getDeviceType(device.device_type_id)}
            />
          </div>
        </div>
      ))}
    </div>
  );

  const renderSection = (list: Device[], preListText: string): JSX.Element =>
    list.length !== 0 ? (
      <>
        <Typography variant="body2" className="mb-3">
          {preListText}{' '}
          {list.length === 1 ? 'this device.' : `these ${list?.length} devices`}
        </Typography>
        {list.length !== devices?.length && renderDevices(list, 'unavailable')}
      </>
    ) : (
      <></>
    );

  const renderOptions = (): JSX.Element[] => {
    let actualActions = [];
    if (lwm2mDevices) {
      actualActions = lwm2mActions;
    } else {
      actualActions = actions;
    }
    return actualActions.map((actualAction) => (
      <MenuItem
        className="m-4 p-2"
        dense
        key={actualAction.action}
        value={actualAction.action}
      >
        {actualAction.label}
      </MenuItem>
    ));
  };

  const checkCommand = (): void => {
    const newUnallowed: Device[] = [];
    const newUnsupported: Device[] = [];
    if (
      userType &&
      chosenCommand &&
      allowActions(userType.abilities, [chosenCommand], 'device')
    ) {
      selectedDevices?.forEach((device) => {
        if (
          !allowActions(
            userType.abilities,
            [chosenCommand],
            'device',
            userCompany?._id,
            device._id,
          )
        ) {
          newUnallowed.push(device);
        } else {
          const deviceType = getDeviceType(device.device_type_id);
          const actionForChosenGatewayCommand: keyof Actions =
            mapGatewayCommandToAction(chosenCommand);
          if (
            deviceType &&
            !deviceType.capabilities.actions?.[actionForChosenGatewayCommand]
          ) {
            newUnsupported.push(device);
          }
        }
      });
      setUnallowedDevices(newUnallowed);
      setUnsupportedDevices(newUnsupported);
    } else if (chosenCommand) {
      setActionNotAllowed(true);
    }
  };

  useEffect(() => {
    if (devicesTypes.length) {
      setActionNotAllowed(false);
      checkCommand();

      const { hasLwm2mDevices, hasOtherDevices } = checkHasLwm2mDevices(
        selectedDevices || [],
        devicesTypes,
      );
      if (hasLwm2mDevices && !hasOtherDevices) {
        setLwm2mDevices(true);
      } else {
        setLwm2mDevices(false);
      }
      if (checkMixedTypes(selectedDevices || [], devicesTypes)) {
        setMixedTypes(true);
      } else {
        setMixedTypes(false);
      }
    }
  }, [selectedDevices, devicesTypes]);

  useEffect(() => {
    if (chosenCommand) {
      checkCommand();
      if (chosenCommand === LWM2M_REQUEST || chosenCommand === LWM2M_FILE) {
        onInputChange(`${actionIndex}.request.command_type`, chosenCommand);
      }
    }
  }, [chosenCommand]);

  return (
    <>
      <Grid item xs={12} className="mt-2">
        {mixedTypes ? (
          <Typography variant="body2">
            No command can be issued for LWM2M devices and devices of other
            types.
          </Typography>
        ) : (
          <div className="mb-6">
            <SelectInput
              prop="chosenCommand"
              label="Command"
              value={chosenCommand}
              onSelectChange={onCommandChange}
              options={[
                <MenuItem dense value="" key="no-value-command">
                  Select a command
                </MenuItem>,
                ...renderOptions(),
              ]}
            />

            {lwm2mDevices &&
              !actionNotAllowed &&
              unallowedDevices.length === 0 &&
              unsupportedDevices.length === 0 && (
                <Lwm2mGatewayCommands
                  chosenGatewayCommand={chosenCommand}
                  gatewayCommand={action.request}
                  onChangeCommandData={handleLwm2mInputChange}
                />
              )}
            {actionNotAllowed && (
              <Typography variant="body2">
                You have insufficient permissions to carry out this command.
              </Typography>
            )}
            {renderSection(
              unallowedDevices,
              `You don't have permission to issue this action to`,
            )}
            {renderSection(
              unsupportedDevices,
              `This action isn't supported by`,
            )}
          </div>
        )}
      </Grid>
    </>
  );
};

export default GatewaySection;
