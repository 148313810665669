import React, { ReactElement } from 'react';
import {
  Typography,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  TypographyVariant,
} from '@mui/material';

export interface SelectInputProps {
  label?: string;
  labelVariant?: TypographyVariant;
  value?: string;
  defaultValue?: string;
  prop: string;
  options: ReactElement[];
  classes?: string;
  disabled?: boolean;
  required?: boolean;
  onSelectChange?: (prop: string, value: string) => void;
  placeholder?: string;
  multiple?: boolean;
  values?: string[];
  fullWidth?: boolean;
  onMultiSelectChange?: (prop: string, value: string[]) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
  label,
  labelVariant,
  value,
  defaultValue,
  prop,
  options,
  classes,
  disabled = false,
  required = false,
  onSelectChange,
  placeholder,
  multiple = false,
  values = [],
  fullWidth = true,
  onMultiSelectChange,
}) => {
  const handleSelectChange = (event: SelectChangeEvent): void => {
    if (onSelectChange) {
      onSelectChange(prop, event.target.value);
    }
  };

  if (!multiple) {
    return (
      <>
        {label && (
          <Typography
            variant={labelVariant ?? 'body2'}
            className="custom-label"
          >
            {label} {required ? '*' : ''}
          </Typography>
        )}
        <FormControl variant="outlined" className={classes} fullWidth>
          <Select
            displayEmpty
            data-cy={prop}
            defaultValue={defaultValue}
            value={value ?? defaultValue ?? ''}
            onChange={handleSelectChange}
            disabled={disabled}
            required={required}
            data-testid="select-input"
          >
            {placeholder && (
              <MenuItem disabled dense value="">
                {placeholder}
              </MenuItem>
            )}
            {options}
          </Select>
        </FormControl>
      </>
    );
  }

  const handleMultiSelectChange = (
    event: SelectChangeEvent<string[]>,
  ): void => {
    if (onMultiSelectChange) {
      onMultiSelectChange(prop, event.target.value as string[]);
    }
  };

  return (
    <>
      {label && (
        <Typography variant="body2" className="custom-label">
          {label} {required ? '*' : ''}
        </Typography>
      )}
      <FormControl variant="outlined" className={classes} fullWidth={fullWidth}>
        <Select
          displayEmpty
          data-cy={prop}
          value={values}
          defaultValue={[defaultValue || '']}
          onChange={handleMultiSelectChange}
          disabled={disabled}
          required={required}
          multiple={multiple}
          data-testid="select-input"
          renderValue={(selectedValue): string => selectedValue.join(', ')}
        >
          {placeholder && (
            <MenuItem disabled dense value="">
              {placeholder}
            </MenuItem>
          )}
          {options}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectInput;
