import { Filters, Filter, FilterOperatorType } from '@edgeiq/edgeiq-api-js';
import {
  ACTIVATION_STATUS_KEY,
  BULK_RESPONSE_STATUS_KEY,
  DELETE_BULK_TYPE_KEY,
  METADATA_KEY,
  TRANSFER_STATUS_KEY,
} from '../app/constants';
import { getMetadataFilterKey, getMetadataFilterOperator } from './metadata';

const likeKeys = ['name', 'description', 'unique_id', 'device_name', 'email'];
const specialKeys = [
  TRANSFER_STATUS_KEY,
  ACTIVATION_STATUS_KEY,
  DELETE_BULK_TYPE_KEY,
  BULK_RESPONSE_STATUS_KEY,
];
const arrayKeys = ['tags'];

const parseFilters = (
  filters: { [key: string]: string },
  caseSensitive = false,
): Filters => {
  if (Object.keys(filters).length === 0) {
    return {};
  }

  const result: Filter[] = [];
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const value = filters[key];
      if (value !== '') {
        if (specialKeys.includes(key)) {
          parseSpecialCases(result, key, value);
        } else if (key.indexOf(METADATA_KEY) !== -1) {
          const filter: Filter = {
            key: getMetadataFilterKey(key),
            operator: getMetadataFilterOperator(key) as FilterOperatorType,
            value,
          };
          result.push(filter);
        } else {
          const isMulti = value.indexOf('|') !== -1;
          const filterValue = isMulti ? value.split('|') : value;
          const filter: Filter = {
            key,
            operator: arrayKeys.includes(key)
              ? 'incany'
              : isMulti
              ? 'in'
              : likeKeys.includes(key)
              ? `${caseSensitive ? '' : 'i'}like`
              : 'eq',
            value: filterValue,
          };
          result.push(filter);
        }
      }
    }
  }

  return {
    filters: result,
  };
};

const parseSpecialCases = (
  result: Filter[],
  key: string,
  value: string,
): void => {
  // special case for not yet activated of activation status
  if (key === ACTIVATION_STATUS_KEY && value === 'activated_at') {
    const filter: Filter = {
      key: 'activated_at',
      operator: 'eq',
      value: 'null',
    };
    result.push(filter);
    const secondFilter: Filter = {
      key: 'deactivated_at',
      operator: 'eq',
      value: 'null',
    };
    result.push(secondFilter);
  } else if (key === DELETE_BULK_TYPE_KEY) {
    // Special case to filter bulk reponses that are a of type `delete_<model>`
    const filter: Filter = {
      key: 'type',
      operator: 'like',
      value: 'delete_',
    };
    result.push(filter);
  } else if (key === BULK_RESPONSE_STATUS_KEY) {
    // Special case to filter bulk reponses by their status
    const filter: Filter = {
      key: 'total',
      operator: 'ne',
      value: 0,
    };
    result.push(filter);
    const successFilter: Filter = {
      key: 'successes',
      operator: 'eq',
      value: 0,
    };
    const failureFilter: Filter = {
      key: 'failures',
      operator: 'eq',
      value: 0,
    };
    if (value === 'success') {
      successFilter.operator = 'ne';
    } else if (value === 'fail') {
      failureFilter.operator = 'ne';
    } else {
      successFilter.operator = 'ne';
      failureFilter.operator = 'ne';
    }
    result.push(successFilter);
    result.push(failureFilter);
  } else {
    const filter: Filter = {
      key:
        value.indexOf('_ne') !== -1
          ? value.substring(0, value.indexOf('_ne'))
          : value,
      operator: value.indexOf('_ne') !== -1 ? 'ne' : 'eq',
      value: 'null',
    };
    result.push(filter);
  }
};

export default parseFilters;
