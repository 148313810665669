import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoContainer: {
      backgroundColor: theme.palette.info.dark,
      display: 'flex',
    },
    infoIcon: {
      color: theme.palette.common.white,
    },
    infoIconGrey: {
      '&:hover': {
        cursor: 'help',
      },
      color: theme.palette.grey[200],
      verticalAlign: 'middle',
    },
    invalidJson: {
      border: '1px solid red',
    },
    invalidLabel: {
      color: 'red',
    },
    resizePayload: {
      resize: 'both',
    },
  }),
);

export default useStyles;
