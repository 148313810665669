import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { set, cloneDeep } from 'lodash';

import SelectInput from '../SelectInput';
import TextInput from '../TextInput';
import { ItemList } from './constants';
import SortableList from '../SortableList';

interface RowSortableItem {
  id: number;
  item: ItemList;
}

/**
 * DynamicRows: handlers two inputs and a dropdown if need it, following a common pattern we have in some of the pages
 *
 **/
export interface DynamicRowsProps {
  buttonLabel?: string;
  disabled?: boolean;
  useOnChangeHandlerObjectIndex?: boolean;
  itemKey: string;
  itemValue: string;
  prop: string;
  propLabel?: string;
  itemKeyPlaceHolder?: string;
  itemValuePlaceHolder?: string;
  itemKeyLabel?: string;
  itemValueLabel?: string;
  /**
    objectRowsTypes: will create the input fields based on a Object key value pair
    @example defaults: {
        key: value;
    };
    component will map an input for the [key] value and an input for the [value] value 
  **/
  objectRowsTypes?: { [key: string]: unknown } | undefined;
  /**
    objectRowsTypes: will create the input fields based on an Array object key value pair
    @example defaults: [{
        name: value, label: value2
    }];
    component will map an input for the [name] value and an input for the [label] value 
  **/
  arrayRowsTypes?: ItemList[];

  dropdownKey?: string;

  /**
    dropDownData: will fill out the dropdown component
    TODO: props could be pass as parameter currently is expecting to have the [key/label] props
  **/
  dropDownData?: ItemList[] | undefined;
  /**
   * dropDownDefaultValue: use it in case you need to set a default value to the dropdown
   **/
  dropDownDefaultValue?: string;
  /**
   * topMargin: use to indicate whether to add a top margin to the component or not
   **/
  topMargin?: boolean;
  /**
   * useKeyToRemoveArrayRow: so that the removeArrayRow returns the key value instead of the index
   **/
  useKeyToRemoveArrayRow?: boolean;
  firstInputType?: string;
  secondInputType?: string;
  addReorder?: boolean;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow?: (prop: string) => void;
  onHandleDropDownChange?: (prop: string) => void;
  onRemoveRow?: (prop: string, item: string) => void;
  onRemoveArrayRow?: (prop: string, index: number | string) => void;
  onReorderObjectRows?: (objectRows: { [key: string]: unknown }) => void;
  onReorder?: (prop: string, value: ItemList[]) => void;
}

const DynamicRows: React.FC<DynamicRowsProps> = ({
  buttonLabel,
  disabled,
  dropDownData,
  dropDownDefaultValue,
  itemKey,
  itemValue,
  prop,
  propLabel,
  itemKeyPlaceHolder,
  itemValuePlaceHolder,
  itemKeyLabel,
  itemValueLabel,
  objectRowsTypes,
  arrayRowsTypes,
  topMargin = true,
  useKeyToRemoveArrayRow = false,
  firstInputType,
  secondInputType,
  dropdownKey,
  useOnChangeHandlerObjectIndex,
  addReorder = false,
  onInputChange,
  onAddRow,
  onRemoveRow,
  onRemoveArrayRow,
  onReorder,
}) => {
  const [items, setItems] = useState<RowSortableItem[]>([]);

  useEffect(() => {
    if (addReorder) {
      const auxItems: RowSortableItem[] = [];
      let index = 0;
      if (objectRowsTypes) {
        for (const key in objectRowsTypes) {
          if (Object.prototype.hasOwnProperty.call(objectRowsTypes, key)) {
            const element = objectRowsTypes[key];
            auxItems.push({
              id: index + 1,
              item: {
                key,
                label: element as string,
              },
            });
            index += 1;
          }
        }
      }
      if (arrayRowsTypes) {
        for (let i = 0; i < arrayRowsTypes.length; i++) {
          const element = arrayRowsTypes[i];
          auxItems.push({
            id: index + 1,
            item: element,
          });
          index += 1;
        }
      }
      setItems(auxItems);
    }
  }, [objectRowsTypes, arrayRowsTypes]);

  const handleDynamicChange =
    (changedProp: string, index: string) =>
    (field: string, value: string | number): void => {
      let realIndex = index;
      if (addReorder) {
        const arrayObject = {
          items: [...items],
        };
        const itemIndex = items.map((item) => item.id).indexOf(parseInt(index));
        realIndex = itemIndex.toString();
        set(arrayObject, `items.${itemIndex}.item.${field}`, value);
        setItems(arrayObject.items);
      }
      onInputChange(changedProp, value, field, realIndex);
    };

  const handleClickAddRow = (addRowProp: string) => (): void => {
    if (onAddRow) {
      if (addReorder) {
        const newItem: RowSortableItem = {
          id: items.length + 1,
          item: {
            key: '',
            label: '',
          },
        };
        if (dropdownKey && dropDownDefaultValue) {
          newItem.item.type = dropDownDefaultValue;
        }
        setItems([...items, newItem]);
      }
      onAddRow(addRowProp);
    }
  };

  const handleClickRemoveRow =
    (removeRowProp: string, index: string) => (): void => {
      if (onRemoveRow) {
        if (addReorder) {
          const itemIndex = items
            .map((item) => item.id)
            .indexOf(parseInt(index));
          const clonedItems = cloneDeep(items);
          clonedItems.splice(itemIndex, 1);
          setItems(clonedItems);
        }
        onRemoveRow(removeRowProp, index);
      }
    };

  const handleClickRemoveArrayRow =
    (removeArrayRowProp: string, index: number | string) => (): void => {
      if (onRemoveArrayRow) {
        if (addReorder) {
          const itemIndex = items
            .map((item) => item.id)
            .indexOf(typeof index === 'string' ? parseInt(index) : index);
          const clonedItems = cloneDeep(items);
          clonedItems.splice(itemIndex, 1);
          setItems(clonedItems);
        }
        onRemoveArrayRow(removeArrayRowProp, index);
      }
    };

  const reorderRows = (
    reorderedItems: { id: number; item: ItemList }[],
  ): void => {
    if (onReorder) {
      onReorder(
        prop,
        reorderedItems.map((item) => item.item),
      );
    }
    setItems(reorderedItems);
  };

  const lgFirstInputSize = !dropdownKey ? 6 : 4;
  const lgSecondInputSize = !dropdownKey ? 5 : 4;

  const renderObjectTypeRow = (
    firstInputValue: string,
    secondInputValue: string | number,
    thirdInputValue: string,
    index: string,
    removeHandler: React.MouseEventHandler<HTMLButtonElement>,
  ): JSX.Element => (
    <Grid
      key={index}
      container
      spacing={2}
      className={addReorder ? 'ml-0' : 'mt-2'}
      direction="row"
      alignItems="center"
    >
      <Grid item xs={12} lg={lgFirstInputSize}>
        <TextInput
          placeholder={itemKeyPlaceHolder || `${propLabel} ${itemKey}`}
          label={itemKeyLabel}
          type={firstInputType || 'text'}
          prop={itemKey}
          value={firstInputValue}
          onInputChange={handleDynamicChange(prop, index)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={!dropdownKey ? 10 : 12} lg={lgSecondInputSize}>
        <TextInput
          placeholder={itemValuePlaceHolder || `${propLabel} ${itemValue}`}
          label={itemValueLabel}
          type={
            secondInputType ||
            (thirdInputValue === 'integer' ? 'number' : 'string')
          }
          prop={itemValue}
          value={secondInputValue}
          onInputChange={handleDynamicChange(prop, index)}
          disabled={disabled}
        />
      </Grid>
      {dropDownData && dropdownKey && (
        <Grid item xs={10} lg={3}>
          <SelectInput
            prop={dropdownKey}
            value={thirdInputValue}
            onSelectChange={handleDynamicChange(prop, index)}
            disabled={disabled}
            options={dropDownData.map((dropdownDataItem) => (
              <MenuItem
                dense
                key={dropdownDataItem.key}
                value={dropdownDataItem.key}
              >
                {dropdownDataItem.label}
              </MenuItem>
            ))}
          />
        </Grid>
      )}
      <Grid item xs={2} lg={1}>
        <IconButton onClick={removeHandler}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderSortableList = (arrayType = true): JSX.Element => (
    <SortableList
      items={items}
      onChange={reorderRows}
      renderItem={(item) => (
        <SortableList.Item id={item.id}>
          <SortableList.DragHandle />
          {renderObjectTypeRow(
            item.item.key,
            item.item.label,
            item.item.type ?? '',
            item.id.toString(),
            arrayType
              ? handleClickRemoveArrayRow(prop, item.id.toString())
              : handleClickRemoveRow(prop, item.id.toString()),
          )}
        </SortableList.Item>
      )}
    />
  );

  return (
    <Grid item xs={12} className={topMargin ? 'mt-6' : ''}>
      {propLabel && <Typography variant="h5">{propLabel}</Typography>}
      {typeof objectRowsTypes !== 'undefined' &&
        (addReorder
          ? renderSortableList(false)
          : Object.keys(objectRowsTypes as { [key: string]: string }).map(
              (item, index) =>
                renderObjectTypeRow(
                  item,
                  objectRowsTypes ? (objectRowsTypes[item] as string) : '',
                  (objectRowsTypes && (objectRowsTypes[item] as string)) ||
                    (dropDownDefaultValue as string),
                  useOnChangeHandlerObjectIndex ? index.toString() : item,
                  handleClickRemoveRow(
                    prop,
                    useOnChangeHandlerObjectIndex ? index.toString() : item,
                  ),
                ),
            ))}

      {typeof arrayRowsTypes !== 'undefined' &&
        (addReorder
          ? renderSortableList()
          : arrayRowsTypes.map((item, index) =>
              renderObjectTypeRow(
                item.key,
                item.label,
                item.type || (dropDownDefaultValue as string),
                String(index),
                handleClickRemoveArrayRow(
                  prop,
                  useKeyToRemoveArrayRow ? item.key : index,
                ),
              ),
            ))}

      <Button
        variant="outlined"
        size="medium"
        className="mt-4"
        onClick={handleClickAddRow(prop)}
        startIcon={<AddIcon />}
        disabled={disabled}
      >
        Add New {buttonLabel}
      </Button>
    </Grid>
  );
};

export default DynamicRows;
