import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Actions,
  BulkActionResponse,
  Command,
  Device,
  DeviceType,
  DeviceTypes,
  Devices,
  GatewayCommandType,
  IssueSoftwareUpdateInput,
  Lwm2mRequest,
  SoftwareUpdate,
  SoftwareUpdates,
  Integrations,
  AwsThingGroup,
  Integration,
  AwsIotThingGroupRequest,
  EIQFiles,
  GatewayCommandRequest,
} from '@edgeiq/edgeiq-api-js';
import {
  RetryOptions,
  RetryStrategyType,
} from '@edgeiq/edgeiq-api-js/dist/retryOptions/models';
import { DateTime } from 'luxon';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setOptionsDeviceTypes } from '../../../redux/reducers/deviceTypes.reducer';
import { setOptionsIntegrations } from '../../../redux/reducers/integrations.reducer';
import { setNewIssueSoftwareUpdateInput } from '../../../redux/reducers/softwareUpdates.reducer';
import { setOptionsFiles } from '../../../redux/reducers/files.reducer';
import {
  ENDPOINT_ROLE,
  errorHighlight,
  optionsPaginationsFilter,
  LWM2M_REQUEST,
  LWM2M_FILE,
  AWSIOT_ATTACH_THING_GROUPS,
  AWSIOT_DETACH_THING_GROUPS,
  FILE_FROM_DEVICE,
  FILE_TO_DEVICE,
  retryStrategyOptions,
} from '../../../app/constants';
import {
  allowActions,
  mapGatewayCommandToAction,
} from '../../../helpers/permissions';
import { checkHasLwm2mDevices, checkMixedTypes } from '../../../helpers/utils';
import useStyles from '../../../components/RightDrawer/styles';
import DeviceTypeIconName from '../../../components/DeviceTypeIconName';
import SelectInput from '../../../components/SelectInput';
import SwitchButton from '../../../components/SwitchButton';
import TextInput from '../../../components/TextInput';
import AwsThingGroupsList from '../../../components/AwsThingGroupsList';
import { mapExecuteCommandDefaultOptions } from '../../Forms/CommandForm/helper';
import IssueUpdateForm from '../IssueUpdateDrawer/IssueUpdateForm';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import Lwm2mGatewayCommands from './Lwm2mCommands';
import {
  SOFTWARE_UPDATE,
  actions,
  lwm2mActions,
  valueRequiredActions,
} from './constants';

interface IssueCommandDrawerProps {
  open: boolean;
  devices?: Device[];
  handleCloseDrawer: () => void;
  handleSubmitSuccess: () => void;
  onRemoveDevice?: (deviceId: string) => void;
  onlyShowGatewayCommands?: boolean;
  singleDeviceType?: DeviceType;
  isDevicePage?: boolean;
}

const emptyLwm2mGatewayCommand: Lwm2mRequest = {
  command_type: LWM2M_REQUEST,
  endpoint: '',
  type: 'read',
};

const emptyAwsIotThingGroupRequest: AwsIotThingGroupRequest = {
  command_type: AWSIOT_ATTACH_THING_GROUPS,
  thing_group_arns: [],
};

const emptyRetryOptions: RetryOptions = {
  max_retries: 5,
  retry_strategy: {
    type: 'none',
  },
  timeout: 180,
};

const IssueCommandDrawer: React.FC<IssueCommandDrawerProps> = ({
  open,
  devices,
  handleCloseDrawer,
  onRemoveDevice,
  onlyShowGatewayCommands = false,
  singleDeviceType,
  isDevicePage = false,
}) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const deviceTypes = useAppSelector(
    (state: RootState) => state.deviceTypes.optionsDeviceTypes,
  );
  const integrationsState = useAppSelector(
    (state: RootState) => state.integrations,
  );
  const userType = useAppSelector((state: RootState) => state.user.userType);
  const userCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const newIssueSoftwareUpdateInput = useAppSelector(
    (state: RootState) => state.softwareUpdates.newIssueSoftwareUpdateInput,
  );
  const filesState = useAppSelector((state: RootState) => state.files);
  const [chosenGatewayCommand, setChosenGatewayCommand] = useState<
    keyof Actions | string
  >('');
  const [loading, setLoading] = useState(false);
  const [hideCommandSelection, setHideCommandSelection] = useState(false);
  const [mixedTypes, setMixedTypes] = useState(false);
  const [lwm2mDevices, setLwm2mDevices] = useState(false);
  const [actionNotAllowed, setActionNotAllowed] = useState(false);
  const [unallowedDevices, setUnallowedDevices] = useState<Device[]>([]);
  const [unsupportedDevices, setUnsupportedDevices] = useState<Device[]>([]);
  const [lwm2mGatewayCommand, setLwm2mGatewayCommand] = useState<Lwm2mRequest>(
    emptyLwm2mGatewayCommand,
  );

  const [isGatewayCommand, setIsGatewayCommand] = useState(true);
  const [isUserDefinedCommand, setIsUserDefinedCommand] = useState(false);
  const [isDifferentDeviceTypes, setIsDifferentDeviceTypes] = useState(false);
  const [commands, setCommands] = useState<Command[]>([]);
  const [chosenUserCommandId, setChosenUserCommandId] = useState('');
  const [chosenUserCommand, setChosenUserCommand] = useState<Command>();
  const [softwareUpdates, setSoftwareUpdates] = useState<SoftwareUpdate[]>([]);
  const [chosenSoftwareUpdateId, setChosenSoftwareUpdateId] = useState('');

  // only for AWS IoT Thing Group commands
  const [awsThingGroups, setAwsThingGroups] = useState<AwsThingGroup[]>([]);
  const [selectedAwsThingGroups, setSelectedAwsThingGroups] = useState<
    AwsThingGroup[]
  >([]);
  const [loadingThingGroups, setLoadingThingGroups] = useState(false);
  const [loadingIntegrations, setLoadingIntegrations] = useState(false);
  const [numberOfIntegrations, setNumberOfIntegrations] = useState(0);
  const [selectedIntegrationIds, setSelectedIntegrationIds] = useState<
    Set<string>
  >(new Set<string>());
  const [selectedIntegrations, setSelectedIntegrations] = useState<
    Integration[]
  >([]);
  const [awsIotThingGroupRequest, setAwsIotThingGroupRequest] =
    useState<AwsIotThingGroupRequest>(emptyAwsIotThingGroupRequest);

  // These 3 following values only are used when there is one device selected and its device type role is endpoint.
  const [siblingDevices, setSiblingDevices] = useState<Device[]>([]);
  const [chosenSiblingDevices, setChosenSiblingDevices] = useState<string[]>(
    [],
  );
  const [generateChildCommandExecutions, setGenerateChildCommandExecutions] =
    useState(false);

  // This state helps to show which devices are unsupported by the user defined command chosen by the user,
  // in case we have devices with multiple device types selected
  const [commandDevicesMap, setCommandDevicesMap] =
    useState<Record<string, Device[]>>();

  const [filePath, setFilePath] = useState('');
  const [fileId, setFileId] = useState('');
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [retryOptions, setRetryOptions] =
    useState<RetryOptions>(emptyRetryOptions);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleSelectedAwsThingGroupChange = (value: AwsThingGroup): void => {
    const currentSelectedAwsThingGroups = selectedAwsThingGroups;
    const newSelectedAwsThingGroups: AwsThingGroup[] =
      !currentSelectedAwsThingGroups
        ? [value]
        : currentSelectedAwsThingGroups?.some((dtg) => dtg.arn === value.arn)
        ? currentSelectedAwsThingGroups.filter((dtg) => dtg.arn !== value.arn)
        : [...currentSelectedAwsThingGroups, value];
    setSelectedAwsThingGroups(newSelectedAwsThingGroups);
    setAwsIotThingGroupRequest({
      ...awsIotThingGroupRequest,
      thing_group_arns: newSelectedAwsThingGroups.map((dtg) => dtg.arn),
    });
  };

  const updateIntegrations = (hasLwm2mDevices: boolean): void => {
    // update if all devices are edge devices, clear selected integrations otherwise
    if (!hasLwm2mDevices) {
      // update the aws thing groups if it is an aws iot thing group command
      if (
        chosenGatewayCommand ===
          (AWSIOT_ATTACH_THING_GROUPS as keyof Actions) ||
        chosenGatewayCommand === (AWSIOT_DETACH_THING_GROUPS as keyof Actions)
      ) {
        // get all integrations for all devices
        const integrationIds = new Set<string>();
        for (const device of devices ?? []) {
          if (device.device_integration_id) {
            integrationIds.add(device.device_integration_id);
          } else {
            const deviceType = getDeviceType(device.device_type_id);
            if (deviceType?.device_integration_id) {
              integrationIds.add(deviceType.device_integration_id);
            }
          }
        }
        // only allow thing group related actions if all devices share the same integration
        setNumberOfIntegrations(integrationIds.size);
        setSelectedIntegrationIds(integrationIds);
        if (integrationIds.size === 1) {
          integrationIds.forEach((id) => {
            getAwsThingGroupsForIntegration(id);
          });
        } else {
          setAwsThingGroups([]);
        }
      }
    } else {
      setSelectedIntegrationIds(new Set<string>());
    }
  };

  const getAwsThingGroupsForIntegration = (integrationId: string): void => {
    setLoadingThingGroups(true);
    Integrations.getAwsThingGroups(integrationId)
      .then((res) => {
        setAwsThingGroups(res);
      })

      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => setLoadingThingGroups(false));
  };

  const getDeviceType = (id: string): DeviceType | undefined =>
    singleDeviceType
      ? singleDeviceType
      : deviceTypes.find((deviceType) => deviceType._id === id);

  const resetValues = (fullReset = false): void => {
    if (fullReset) {
      setIsGatewayCommand(true);
      setIsUserDefinedCommand(false);
      setIsDifferentDeviceTypes(false);
      setChosenUserCommandId('');
      setChosenGatewayCommand('');
      setChosenSoftwareUpdateId('');
      setSiblingDevices([]);
      setChosenSiblingDevices([]);
      // AwsIot
      setAwsThingGroups([]);
      setSelectedAwsThingGroups([]);
      setLoadingThingGroups(false);
      setNumberOfIntegrations(0);
      setSelectedIntegrationIds(new Set<string>());
      setSelectedIntegrations([]);
      setAwsIotThingGroupRequest(emptyAwsIotThingGroupRequest);
      setRetryOptions(emptyRetryOptions);
    }
    setActionNotAllowed(false);
    setUnallowedDevices([]);
    setUnsupportedDevices([]);
    setLwm2mGatewayCommand(emptyLwm2mGatewayCommand);
  };

  useEffect(() => {
    if (onlyShowGatewayCommands) {
      setHideCommandSelection(true);
      setIsGatewayCommand(true);
    } else {
      setHideCommandSelection(false);
    }
  }, [onlyShowGatewayCommands]);

  useEffect(() => {
    // isDevicePage is used to avoid this call in the device content page (if reloading it for example)
    // as there is no need to get all device types, just the device type of the device, and this is handled in the DeviceContent.tsx
    // and from there we pass the singleDeviceType to this component.
    if (deviceTypes.length === 0 && !isDevicePage) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    setSelectedIntegrations(
      Array.from(selectedIntegrationIds)
        .map((integrationId) =>
          integrationsState.optionsIntegrations.find(
            (integration) => integration._id === integrationId,
          ),
        )
        .filter((integration) => integration !== undefined) as Integration[],
    );
  }, [selectedIntegrationIds]);

  useEffect(() => {
    setActionNotAllowed(false);
    // If only one device has been chosen, and it has a parent and its role is endpoint
    if (devices && devices.length === 1 && devices[0].parent_device_id) {
      const devType = deviceTypes.find(
        (dt) => dt._id === devices[0].device_type_id,
      );
      if (devType?.role === ENDPOINT_ROLE) {
        setGenerateChildCommandExecutions(true);
        Devices.list(
          {
            _id: {
              operator: 'ne',
              value: devices[0]._id,
            },
            parent_device_id: {
              operator: 'eq',
              value: devices[0].parent_device_id,
            },
          },
          optionsPaginationsFilter,
        )
          .then((result) => {
            setSiblingDevices(result.devices);
            setChosenSiblingDevices(result.devices.map((d) => d._id));
            checkCommand(result.devices);
          })
          .catch((error) => {
            dispatchError(error.message);
          });
      } else {
        setGenerateChildCommandExecutions(false);
      }
    } else {
      setSiblingDevices([]);
      setChosenSiblingDevices([]);
      checkCommand();
    }

    const { hasLwm2mDevices, hasOtherDevices } = checkHasLwm2mDevices(
      devices || [],
      singleDeviceType ? [singleDeviceType] : deviceTypes,
    );
    if (hasLwm2mDevices && !hasOtherDevices) {
      setLwm2mDevices(true);
      setIsGatewayCommand(true);
      setIsUserDefinedCommand(false);
    } else {
      setLwm2mDevices(false);
    }

    if (checkMixedTypes(devices || [], deviceTypes)) {
      setMixedTypes(true);
    } else {
      setMixedTypes(false);
    }

    // update integrations
    updateIntegrations(hasLwm2mDevices);
  }, [devices]);

  useEffect(() => {
    if (isUserDefinedCommand) {
      const deviceTypesFromDevices = devices?.map(
        (device) => device.device_type_id,
      );
      const deviceTypesToUse = deviceTypes.filter((dt) =>
        deviceTypesFromDevices?.includes(dt._id),
      );
      const commandOptions: Command[] = [];
      const commandsDevicesMap: Record<string, Device[]> = {};
      deviceTypesToUse.forEach(async (dt, index) => {
        await DeviceTypes.getCommands(dt._id)
          .then((res) => {
            // Check the new commands to add to the array from the response
            res.forEach((commandOption) => {
              const commandExists = commandOptions.findIndex(
                (c) => c._id === commandOption._id,
              );
              if (commandExists === -1) {
                commandOptions.push(commandOption);
              }
              // get the devices of the deviceType
              const deviceTypeDevices = devices?.filter(
                (d) => d.device_type_id === dt._id,
              );
              // map the devices that can issue the command
              if (!commandsDevicesMap[commandOption._id] && deviceTypeDevices) {
                commandsDevicesMap[commandOption._id] = deviceTypeDevices;
              } else if (deviceTypeDevices) {
                commandsDevicesMap[commandOption._id] = [
                  ...commandsDevicesMap[commandOption._id],
                  ...deviceTypeDevices,
                ];
              }
            });
            if (index === deviceTypesToUse.length - 1) {
              setCommandDevicesMap(commandsDevicesMap);
              setCommands(commandOptions);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
    }
  }, [isUserDefinedCommand, devices]);

  useEffect(() => {
    if (chosenGatewayCommand) {
      checkCommand(siblingDevices);
      if (
        chosenGatewayCommand === LWM2M_REQUEST ||
        chosenGatewayCommand === LWM2M_FILE
      ) {
        setLwm2mGatewayCommand({
          ...lwm2mGatewayCommand,
          command_type: chosenGatewayCommand,
        });
      }
      if (chosenGatewayCommand === SOFTWARE_UPDATE && devices) {
        SoftwareUpdates.list(
          {
            device_type_id: {
              operator: 'eq',
              value: devices[0].device_type_id,
            },
          },
          optionsPaginationsFilter,
        )
          .then((result) => {
            setSoftwareUpdates(result.softwareUpdates);
          })
          .catch((error) => {
            dispatchError(error.message);
          });
      }

      if (
        chosenGatewayCommand ===
          (AWSIOT_ATTACH_THING_GROUPS as keyof Actions) ||
        chosenGatewayCommand === (AWSIOT_DETACH_THING_GROUPS as keyof Actions)
      ) {
        if (integrationsState.optionsIntegrations.length === 0) {
          setLoadingIntegrations(true);
          Integrations.list({}, optionsPaginationsFilter)
            .then((result) => {
              dispatch(setOptionsIntegrations(result.integrations));
            })
            .catch((error) => dispatchError(error.message, errorHighlight))
            .finally(() => setLoadingIntegrations(false));
        }
      }

      if (
        chosenGatewayCommand === (AWSIOT_ATTACH_THING_GROUPS as keyof Actions)
      ) {
        setAwsIotThingGroupRequest({
          ...awsIotThingGroupRequest,
          command_type: AWSIOT_ATTACH_THING_GROUPS,
        });
      }
      if (
        chosenGatewayCommand === (AWSIOT_DETACH_THING_GROUPS as keyof Actions)
      ) {
        setAwsIotThingGroupRequest({
          ...awsIotThingGroupRequest,
          command_type: AWSIOT_DETACH_THING_GROUPS,
        });
      }

      if (
        chosenGatewayCommand === FILE_FROM_DEVICE ||
        chosenGatewayCommand === FILE_TO_DEVICE
      ) {
        if (filesState.optionsFiles.length === 0) {
          setLoadingFiles(true);
          EIQFiles.list({}, optionsPaginationsFilter)
            .then((result) => {
              dispatch(setOptionsFiles(result.files));
            })
            .catch((error) => dispatchError(error.message, errorHighlight))
            .finally(() => setLoadingFiles(false));
        }
      }

      const { hasLwm2mDevices } = checkHasLwm2mDevices(
        devices || [],
        deviceTypes,
      );

      updateIntegrations(hasLwm2mDevices);
    }
  }, [chosenGatewayCommand, integrationsState.optionsIntegrations]);

  useEffect(() => {
    checkCommand();
  }, [chosenUserCommandId]);

  const handleGatewayTypeChange = (): void => {
    if (isUserDefinedCommand) {
      setIsUserDefinedCommand(false);
    }
    setIsGatewayCommand(!isGatewayCommand);
  };

  const handleUserDefinedTypeChange = (): void => {
    if (isGatewayCommand) {
      setIsGatewayCommand(false);
    }
    setIsUserDefinedCommand(!isUserDefinedCommand);
  };

  const onGatewayCommandChange = (
    _prop: string,
    value: string | number,
  ): void => {
    resetValues();
    setChosenGatewayCommand(value as keyof Actions);
  };

  const onUserCommandChange = (_prop: string, value: string | number): void => {
    const chosenCommand = commands.find(
      (commandOption) => commandOption._id === value,
    );
    if (chosenCommand) {
      setChosenUserCommand(mapExecuteCommandDefaultOptions(chosenCommand));
      setChosenUserCommandId(value as string);
    }
  };

  const checkCommand = (moreDevices?: Device[]): void => {
    const newUnallowed: Device[] = [];
    const newUnsupported: Device[] = [];

    if (chosenGatewayCommand) {
      const actionForChosenGatewayCommand: keyof Actions =
        mapGatewayCommandToAction(chosenGatewayCommand);
      if (
        devices &&
        userType &&
        allowActions(
          userType.abilities,
          [actionForChosenGatewayCommand],
          'device',
        )
      ) {
        // This is to take into account that the control should be done for the devices selected from the grid/list AND the sibingDevices in case
        // it's the one device with siblings.
        let checkDevices = devices;
        if (moreDevices) {
          checkDevices = [...(devices || []), ...moreDevices];
        }
        // if the gateway command is software update, check right away if the devices are of the same device type
        if (chosenGatewayCommand === SOFTWARE_UPDATE) {
          let hasDifferentTypes = false;
          for (let i = 0; i < checkDevices.length; i++) {
            if (
              i !== checkDevices.length - 1 &&
              checkDevices[i].device_type_id !==
                checkDevices[i + 1].device_type_id
            ) {
              hasDifferentTypes = true;
              break;
            }
          }
          if (hasDifferentTypes) {
            setIsDifferentDeviceTypes(true);
            return;
          }
        }
        checkDevices.forEach((device) => {
          if (
            !allowActions(
              userType.abilities,
              [actionForChosenGatewayCommand],
              'device',
              userCompany?._id,
              device._id,
            )
          ) {
            newUnallowed.push(device);
          } else {
            const deviceType = getDeviceType(device.device_type_id);
            if (
              deviceType &&
              !deviceType.capabilities.actions?.[actionForChosenGatewayCommand]
            ) {
              newUnsupported.push(device);
            }
          }
        });
        setUnallowedDevices(newUnallowed);
        setUnsupportedDevices(newUnsupported);
      } else {
        setActionNotAllowed(true);
      }
    } else if (
      chosenUserCommandId &&
      devices &&
      commandDevicesMap &&
      commandDevicesMap[chosenUserCommandId] &&
      userType &&
      // First we check if the user has at least the execute permission
      allowActions(userType.abilities, ['execute'], 'command')
    ) {
      // get the devices that can run the chosen command from the map
      const supportedDevices = commandDevicesMap[chosenUserCommandId];
      const supportedDevicesIds = supportedDevices.map((d) => d._id);
      // the unsupported devices will be from the devices array than don't belong to this array.
      setUnsupportedDevices(
        devices.filter((d) => !supportedDevicesIds.includes(d._id)),
      );
      // Next we check if the user has the execute command permission for all the devices that support the command.
      supportedDevices.forEach((device) => {
        if (
          !allowActions(
            userType.abilities,
            ['execute'],
            'command',
            userCompany?._id,
            device._id,
          )
        ) {
          newUnallowed.push(device);
        }
      });
    }
  };

  const onSoftwareUpdateChange = (
    _prop: string,
    value: string | number,
  ): void => {
    setChosenSoftwareUpdateId(value as string);
  };

  const handleLwm2mInputChange = (
    prop: string,
    value: string | number,
  ): void => {
    setLwm2mGatewayCommand({
      ...lwm2mGatewayCommand,
      [prop]: value,
    });
  };

  const handleRemoveDevice = (deviceId: string) => (): void => {
    if (onRemoveDevice) {
      onRemoveDevice(deviceId);
    }
  };

  const checkSiblingDevice = (deviceId: string) => (): void => {
    setChosenSiblingDevices(
      chosenSiblingDevices.includes(deviceId)
        ? chosenSiblingDevices.filter((id) => id !== deviceId)
        : [...chosenSiblingDevices, deviceId],
    );
  };

  const renderDevices = (
    list: Device[],
    preIndex?: string,
    isSiblingDevices = false,
  ): JSX.Element => (
    <div className={clsx('scrollbar mb-4', classes.drawerSelectedDevices)}>
      {list.map((device) => (
        <div
          key={`${preIndex ? `${preIndex}-` : ''}${device._id}`}
          data-cy="device-container"
          className={clsx('mb-2 br-1 p-2', classes.drawerDeviceContainer)}
        >
          <Typography variant="body2" component="div" data-cy="device-name">
            {isSiblingDevices && preIndex !== 'unavailable' && (
              <Checkbox
                className="p-0 mr-2"
                checked={chosenSiblingDevices.includes(device._id)}
                onChange={checkSiblingDevice(device._id)}
              />
            )}
            {device.name}
          </Typography>
          <div className="d-flex flex-items-center">
            <DeviceTypeIconName
              labelVariant="body2"
              deviceType={getDeviceType(device.device_type_id)}
            />
            {!isSiblingDevices && onRemoveDevice && (
              <IconButton
                aria-label="close-drawer"
                data-cy="remove-device-button"
                onClick={handleRemoveDevice(device._id)}
              >
                <CloseIcon className={classes.closeDrawerIcon} />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const renderMenuItem = (value: string, label: string): JSX.Element => (
    <MenuItem className="m-4 p-2" dense key={value} value={value}>
      {label}
    </MenuItem>
  );

  const renderGatewayCommandsOptions = (): JSX.Element[] => {
    let actualActions = [];
    if (lwm2mDevices) {
      actualActions = lwm2mActions;
    } else {
      actualActions = actions;
    }
    return actualActions.map((action) =>
      renderMenuItem(action.action, action.label),
    );
  };

  const renderUserCommandsOptions = (): JSX.Element[] =>
    commands.map((commandOption) =>
      renderMenuItem(commandOption._id, commandOption.name),
    );

  const renderSoftwareUpdatesOptions = (): JSX.Element[] =>
    softwareUpdates.map((softwareUpdateOption) =>
      renderMenuItem(softwareUpdateOption._id, softwareUpdateOption.name),
    );

  const renderSection = (list: Device[], preListText: string): JSX.Element =>
    list.length !== 0 ? (
      <>
        <Typography
          variant="body2"
          className="mb-3 mt-3"
          data-cy="unavailable-avdevices-count"
        >
          {preListText}{' '}
          {list.length === 1 ? 'this device.' : `these ${list?.length} devices`}
        </Typography>
        {list.length !== [...(devices || []), ...siblingDevices].length &&
          renderDevices(list, 'unavailable', devices?.length === 1)}
      </>
    ) : (
      <></>
    );

  const renderIntegrations = (preIndex?: string): JSX.Element => (
    <div className={clsx('scrollbar mb-4', classes.drawerSelectedDevices)}>
      {selectedIntegrations.map((integration) => (
        <div
          key={`${preIndex ? `${preIndex}-` : ''}${integration._id}`}
          className={clsx('mb-2 br-1 p-2', classes.drawerDeviceContainer)}
        >
          <Typography variant="body2">{integration.name}</Typography>
        </div>
      ))}
    </div>
  );

  const renderIntegrationsSection = (): JSX.Element =>
    selectedIntegrations.length !== 0 ? (
      <>
        <Typography variant="body2" className="mb-3">
          You can only modify AWS IoT Thing Groups if all devices share the same
          integration. Right now you have selected devices with the following
          integrations:
        </Typography>
        {renderIntegrations()}
      </>
    ) : (
      <></>
    );

  const commandHasOptions = (): boolean => {
    if (chosenUserCommand && chosenUserCommand.default_options?.length !== 0) {
      return true;
    }
    return false;
  };

  const handleOptionChange = (prop: string, value: string | number): void => {
    if (chosenUserCommand) {
      setChosenUserCommand({
        ...chosenUserCommand,
        options: {
          ...chosenUserCommand.options,
          [prop]: value,
        },
      });
    }
  };

  const handleSoftwareInputChange = (
    prop: string,
    value: Array<string> | string | DateTime,
  ): void => {
    if (chosenSoftwareUpdateId) {
      if (prop.includes('schedule.')) {
        const key: string = prop.split('.')[1];
        dispatch(
          setNewIssueSoftwareUpdateInput({
            ...newIssueSoftwareUpdateInput,
            schedule: {
              ...(newIssueSoftwareUpdateInput?.schedule ?? {}),
              [key]: value,
            },
          } as IssueSoftwareUpdateInput),
        );
      } else {
        dispatch(
          setNewIssueSoftwareUpdateInput({
            ...newIssueSoftwareUpdateInput,
            [prop]: value,
          } as IssueSoftwareUpdateInput),
        );
      }
    }
  };

  const handleFileInputs = (prop: string, value: string | number): void => {
    if (prop === 'filePath') {
      setFilePath(value as string);
    } else {
      setFileId(value as string);
    }
  };

  const issueCommandAction = async (
    ids: string[],
  ): Promise<BulkActionResponse> => {
    const addRetryOptions = retryOptions.retry_strategy.type !== 'none';
    if (isGatewayCommand && chosenGatewayCommand) {
      if (chosenGatewayCommand === SOFTWARE_UPDATE) {
        if (addRetryOptions && newIssueSoftwareUpdateInput) {
          newIssueSoftwareUpdateInput.retry_options = retryOptions;
        }
        return await SoftwareUpdates.bulkSoftwareUpdate(
          {
            ...newIssueSoftwareUpdateInput,
            command_type: 'software_update',
            ids,
            software_update_id: chosenSoftwareUpdateId,
          },
          generateChildCommandExecutions,
        );
      }
      if (
        chosenGatewayCommand ===
          (AWSIOT_ATTACH_THING_GROUPS as keyof Actions) ||
        chosenGatewayCommand === (AWSIOT_DETACH_THING_GROUPS as keyof Actions)
      ) {
        const awsRequest = awsIotThingGroupRequest;
        if (addRetryOptions) {
          awsRequest.retry_options = retryOptions;
        }
        return await Devices.bulkExecuteGatewayCommand(
          ids,
          awsIotThingGroupRequest,
          generateChildCommandExecutions,
        );
      }
      const gatewayRequest: GatewayCommandRequest = {
        command_type: chosenGatewayCommand as GatewayCommandType,
      };
      if (addRetryOptions) {
        gatewayRequest.retry_options = retryOptions;
      }
      if (
        chosenGatewayCommand === FILE_FROM_DEVICE ||
        chosenGatewayCommand === FILE_TO_DEVICE
      ) {
        gatewayRequest.filepath = filePath;
        if (chosenGatewayCommand === FILE_TO_DEVICE) {
          const fileDetails = filesState.optionsFiles.find(
            (f) => f.id === fileId,
          );
          if (fileDetails) {
            let path = filePath;
            const lastChar = filePath.charAt(filePath.length - 1);
            if (lastChar === '/') {
              path = filePath.substring(0, filePath.length - 1);
            }
            gatewayRequest.filepath = `${path}/${fileDetails?.name}`;
          }
          gatewayRequest.file_id = fileId;
        }
      }
      return await Devices.bulkExecuteGatewayCommand(
        ids,
        lwm2mDevices ? lwm2mGatewayCommand : gatewayRequest,
        generateChildCommandExecutions,
      );
    }

    return await Devices.bulkExecuteCommand(
      (chosenUserCommand as Command)._id, // it cannot be null nor undefined at this point
      ids,
      //TH: it's strange that we're storing the options back on the command, but I think this was due to a
      // misunderstanding of what data needs to be sent through the execute.
      chosenUserCommand?.options,
      generateChildCommandExecutions,
    );
  };

  const handleActionCallback = (): void => {
    if (devices) {
      setLoading(true);
      let ids = devices.map((device) => device._id);
      if (ids.length === 1 && chosenSiblingDevices.length !== 0) {
        ids = [...ids, ...chosenSiblingDevices];
      }
      issueCommandAction(ids)
        .then((_res) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to issue the command to the selected devices',
              type: 'success',
            }),
          );
          resetValues(true);
          handleCloseDrawer();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleRetryOptionsChange = (
    prop: string,
    value: string | number,
  ): void => {
    switch (prop) {
      case 'retry_strategy':
        setRetryOptions({
          ...retryOptions,
          retry_strategy: {
            type: value as RetryStrategyType,
          },
        });
        break;
      case 'fixed_interval':
      case 'exponential_base_interval':
      case 'exponential_multiplier':
      case 'exponential_max_interval':
        setRetryOptions({
          ...retryOptions,
          retry_strategy: {
            ...retryOptions.retry_strategy,
            [prop]: value,
          },
        });
        break;
      default:
        setRetryOptions({
          ...retryOptions,
          [prop]: value,
        });
        break;
    }
  };

  const renderRetryStrategyOptions = (): JSX.Element[] => {
    const keys = Object.keys(retryStrategyOptions);
    return keys.map((key) => renderMenuItem(key, retryStrategyOptions[key]));
  };

  const disableDrawerAction =
    (!isGatewayCommand && !isUserDefinedCommand) ||
    actionNotAllowed ||
    unallowedDevices.length !== 0 ||
    unsupportedDevices.length !== 0 ||
    (isGatewayCommand &&
      (!chosenGatewayCommand ||
        (chosenGatewayCommand === SOFTWARE_UPDATE && isDifferentDeviceTypes) ||
        (chosenGatewayCommand === SOFTWARE_UPDATE && !chosenSoftwareUpdateId) ||
        ((chosenGatewayCommand ===
          (AWSIOT_ATTACH_THING_GROUPS as keyof Actions) ||
          chosenGatewayCommand ===
            (AWSIOT_DETACH_THING_GROUPS as keyof Actions)) &&
          numberOfIntegrations > 1) ||
        ((chosenGatewayCommand ===
          (AWSIOT_ATTACH_THING_GROUPS as keyof Actions) ||
          chosenGatewayCommand ===
            (AWSIOT_DETACH_THING_GROUPS as keyof Actions)) &&
          selectedAwsThingGroups.length === 0) ||
        (lwm2mDevices &&
          chosenGatewayCommand === LWM2M_REQUEST &&
          !lwm2mGatewayCommand.endpoint) ||
        (lwm2mDevices &&
          chosenGatewayCommand === LWM2M_FILE &&
          !lwm2mGatewayCommand.filepath) ||
        (lwm2mDevices &&
          chosenGatewayCommand === LWM2M_REQUEST &&
          valueRequiredActions.includes(lwm2mGatewayCommand.type) &&
          !lwm2mGatewayCommand.data) ||
        (lwm2mDevices &&
          chosenGatewayCommand === LWM2M_FILE &&
          valueRequiredActions.includes(lwm2mGatewayCommand.type) &&
          !lwm2mGatewayCommand.file_id))) ||
    ((chosenGatewayCommand === FILE_FROM_DEVICE ||
      chosenGatewayCommand === FILE_TO_DEVICE) &&
      !filePath) ||
    (chosenGatewayCommand === FILE_TO_DEVICE && !fileId) ||
    (isUserDefinedCommand && !chosenUserCommandId);

  return (
    <RightDrawer
      open={open}
      actionLabel="Issue Command"
      title={
        onlyShowGatewayCommands ? 'Issue Gateway Command' : 'Issue Command'
      }
      disableAction={disableDrawerAction}
      actionLoading={loading}
      actionCallback={handleActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <div>
          {mixedTypes ? (
            <Typography
              variant="button"
              component="div"
              className="mb-3"
              data-cy="mixedTypes-warning"
            >
              No command can be issued for LWM2M devices and devices of other
              types.
            </Typography>
          ) : (
            <>
              <Typography
                variant="button"
                component="div"
                className="mb-3"
                data-cy="selected-devices-count"
              >
                {devices?.length === 1
                  ? '1 Device '
                  : `${devices?.length} Devices `}
                selected
              </Typography>
              {renderDevices(devices ?? [])}
              {devices?.length === 1 &&
                siblingDevices &&
                siblingDevices.length !== 0 && (
                  <>
                    <Typography
                      variant="button"
                      component="div"
                      className="mb-3"
                      data-cy="sibling-devices-subtitle"
                    >
                      The selected device's parent also has these child devices.
                      Select to also issue the command to these sibling devices.
                    </Typography>
                    {renderDevices(siblingDevices, '', true)}
                  </>
                )}
              {!lwm2mDevices && !hideCommandSelection && (
                <>
                  <SwitchButton
                    label="Execute Gateway Command"
                    prop="gateway_command"
                    value={isGatewayCommand}
                    onSwitchChange={handleGatewayTypeChange}
                  />
                  <SwitchButton
                    label="Execute User defined Command"
                    value={isUserDefinedCommand}
                    prop="user_defined_command"
                    onSwitchChange={handleUserDefinedTypeChange}
                  />
                </>
              )}
              {isUserDefinedCommand && (
                <div className="mt-6">
                  <SelectInput
                    prop="chosenUserCommandId"
                    label="Command"
                    value={chosenUserCommandId}
                    onSelectChange={onUserCommandChange}
                    options={[
                      <MenuItem dense value="" key="no-value-command">
                        Select a command
                      </MenuItem>,
                      ...renderUserCommandsOptions(),
                    ]}
                  />
                  {!actionNotAllowed &&
                    unallowedDevices.length === 0 &&
                    unsupportedDevices.length === 0 &&
                    commandHasOptions() && (
                      <div className="mt-6">
                        <Typography variant="subtitle1">
                          Choose options to execute command:{' '}
                          {chosenUserCommand?.name}
                        </Typography>
                        {chosenUserCommand?.options &&
                          Object.keys(chosenUserCommand?.options).map(
                            (key, index) => (
                              <Grid
                                item
                                xs={12}
                                className="d-flex flex-items-center mt-8"
                                key={index}
                              >
                                <Typography variant="h6" className="mr-2">
                                  {key}
                                </Typography>
                                <TextInput
                                  prop={key}
                                  value={
                                    chosenUserCommand.options
                                      ? chosenUserCommand.options[key]
                                      : ''
                                  }
                                  onInputChange={handleOptionChange}
                                />
                              </Grid>
                            ),
                          )}
                      </div>
                    )}
                </div>
              )}
              {isGatewayCommand && (
                <div className="mt-6">
                  <div className="mb-6">
                    <SelectInput
                      prop="chosenGatewayCommand"
                      label="Command"
                      value={chosenGatewayCommand}
                      onSelectChange={onGatewayCommandChange}
                      options={[
                        <MenuItem dense value="" key="no-value-gateway-command">
                          Select a gateway command
                        </MenuItem>,
                        ...renderGatewayCommandsOptions(),
                      ]}
                    />
                  </div>
                  {!actionNotAllowed &&
                    unallowedDevices.length === 0 &&
                    unsupportedDevices.length === 0 && (
                      <>
                        {lwm2mDevices && (
                          <Lwm2mGatewayCommands
                            chosenGatewayCommand={chosenGatewayCommand}
                            gatewayCommand={lwm2mGatewayCommand}
                            onChangeCommandData={handleLwm2mInputChange}
                          />
                        )}
                        {chosenGatewayCommand === SOFTWARE_UPDATE && (
                          <>
                            {!isDifferentDeviceTypes && (
                              <div className="mt-6">
                                <SelectInput
                                  prop="softwareUpdateId"
                                  label="Software Update"
                                  value={chosenSoftwareUpdateId}
                                  onSelectChange={onSoftwareUpdateChange}
                                  options={[
                                    <MenuItem
                                      dense
                                      value=""
                                      key="no-value-software-update"
                                    >
                                      Select a software update
                                    </MenuItem>,
                                    ...renderSoftwareUpdatesOptions(),
                                  ]}
                                />
                                {chosenSoftwareUpdateId && (
                                  <div className="my-6">
                                    <Typography
                                      variant="button"
                                      component="div"
                                      className="mb-3"
                                    >
                                      Schedule this Update, or Update ASAP
                                    </Typography>
                                    <IssueUpdateForm
                                      onInputChange={handleSoftwareInputChange}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                            {isDifferentDeviceTypes && (
                              <Typography
                                variant="button"
                                component="div"
                                className="mb-3"
                                data-cy="different-profile-message"
                              >
                                You cannot issue a software update to devices of
                                different profile at the same time.
                              </Typography>
                            )}
                          </>
                        )}
                        {(chosenGatewayCommand ===
                          (AWSIOT_ATTACH_THING_GROUPS as keyof Actions) ||
                          chosenGatewayCommand ===
                            (AWSIOT_DETACH_THING_GROUPS as keyof Actions)) &&
                          !loadingIntegrations && (
                            <>
                              {numberOfIntegrations === 1 && (
                                <AwsThingGroupsList
                                  title="Available AWS IoT Thing Groups"
                                  loading={loadingThingGroups}
                                  allAvailableAwsThingGroups={Array.from(
                                    awsThingGroups,
                                  )}
                                  selectedAwsThingGroups={
                                    selectedAwsThingGroups
                                  }
                                  onSelectedAwsThingGroupChange={
                                    handleSelectedAwsThingGroupChange
                                  }
                                  readOnly={false}
                                />
                              )}
                              {numberOfIntegrations > 1 &&
                                renderIntegrationsSection()}
                            </>
                          )}
                        {loadingIntegrations && (
                          <Grid container direction="row" alignItems="center">
                            <Grid item xs={1}>
                              <CircularProgress size="2rem" />
                            </Grid>
                            <Grid item xs={11}>
                              <p>Loading integrations for devices...</p>
                            </Grid>
                          </Grid>
                        )}
                        {(chosenGatewayCommand === FILE_FROM_DEVICE ||
                          chosenGatewayCommand === FILE_TO_DEVICE) &&
                          !loadingFiles && (
                            <>
                              <TextInput
                                label="File Path"
                                prop="filePath"
                                classes="mb-6"
                                required={true}
                                value={filePath}
                                onInputChange={handleFileInputs}
                              />
                              {chosenGatewayCommand === FILE_TO_DEVICE && (
                                <SelectInput
                                  label="File"
                                  prop="fileId"
                                  value={fileId}
                                  onSelectChange={handleFileInputs}
                                  options={[
                                    <MenuItem dense value="" key="no-value">
                                      Select a file
                                    </MenuItem>,
                                    ...filesState.optionsFiles.map((file) => (
                                      <MenuItem
                                        className="m-4 p-2"
                                        dense
                                        key={file.id}
                                        value={file.id}
                                      >
                                        {file.name}
                                      </MenuItem>
                                    )),
                                  ]}
                                />
                              )}
                            </>
                          )}
                        <div>
                          <Grid
                            item
                            xs={12}
                            className={clsx('mt-2', {
                              ['mb-4']:
                                retryOptions.retry_strategy.type === 'none',
                            })}
                          >
                            <SelectInput
                              prop="retry_strategy"
                              label="Retry Strategy"
                              value={retryOptions.retry_strategy.type || 'none'}
                              onSelectChange={handleRetryOptionsChange}
                              options={[...renderRetryStrategyOptions()]}
                            />
                          </Grid>
                          {retryOptions.retry_strategy.type !== 'none' && (
                            <Grid container spacing={2}>
                              <Grid item xs={6} className="mt-4">
                                <TextInput
                                  label="Timeout (seconds)"
                                  onInputChange={handleRetryOptionsChange}
                                  placeholder="Timeout (seconds)"
                                  prop="timeout"
                                  type="number"
                                  value={retryOptions.timeout}
                                  minValue={60}
                                  maxValue={300}
                                  error={
                                    typeof retryOptions.timeout !==
                                      'undefined' &&
                                    (retryOptions.timeout < 60 ||
                                      retryOptions.timeout > 300)
                                  }
                                  helperText="Min: 60. Max: 300"
                                />
                              </Grid>
                              <Grid item xs={6} className="mt-4">
                                <TextInput
                                  label="Max retries"
                                  onInputChange={handleRetryOptionsChange}
                                  placeholder="Max retries"
                                  prop="max_retries"
                                  type="number"
                                  value={retryOptions.max_retries}
                                  minValue={1}
                                  maxValue={20}
                                  error={
                                    typeof retryOptions.max_retries !==
                                      'undefined' &&
                                    (retryOptions.max_retries < 1 ||
                                      retryOptions.max_retries > 20)
                                  }
                                  helperText="Min: 1. Max: 20"
                                />
                              </Grid>
                              {retryOptions.retry_strategy.type === 'fixed' && (
                                <Grid item xs={6} className="mt-4 mb-4">
                                  <TextInput
                                    label="Fixed Interval (seconds)"
                                    onInputChange={handleRetryOptionsChange}
                                    placeholder="Fixed Interval (seconds)"
                                    prop="fixed_interval"
                                    type="number"
                                    value={
                                      retryOptions.retry_strategy
                                        .fixed_interval ?? 180
                                    }
                                    minValue={60}
                                    maxValue={3600}
                                    error={
                                      typeof retryOptions.retry_strategy
                                        .fixed_interval !== 'undefined' &&
                                      (retryOptions.retry_strategy
                                        .fixed_interval < 60 ||
                                        retryOptions.retry_strategy
                                          .fixed_interval > 3600)
                                    }
                                    helperText="Min: 60. Max: 3600"
                                  />
                                </Grid>
                              )}
                              {retryOptions.retry_strategy.type ===
                                'exponential_backoff' && (
                                <>
                                  <Grid item xs={6} className="mt-4">
                                    <TextInput
                                      label="Exponential Base Interval (seconds)"
                                      onInputChange={handleRetryOptionsChange}
                                      placeholder="Exponential Base Interval (seconds)"
                                      prop="exponential_base_interval"
                                      type="number"
                                      value={
                                        retryOptions.retry_strategy
                                          .exponential_base_interval ?? 180
                                      }
                                      minValue={60}
                                      maxValue={3600}
                                      error={
                                        typeof retryOptions.retry_strategy
                                          .exponential_base_interval !==
                                          'undefined' &&
                                        (retryOptions.retry_strategy
                                          .exponential_base_interval < 60 ||
                                          retryOptions.retry_strategy
                                            .exponential_base_interval > 3600)
                                      }
                                      helperText="Min: 60. Max: 3600"
                                    />
                                  </Grid>
                                  <Grid item xs={6} className="mt-4">
                                    <TextInput
                                      label="Exponential Multiplier"
                                      onInputChange={handleRetryOptionsChange}
                                      placeholder="Exponential Multiplier"
                                      prop="exponential_multiplier"
                                      type="number"
                                      value={
                                        retryOptions.retry_strategy
                                          .exponential_multiplier ?? 2
                                      }
                                      minValue={2}
                                      maxValue={10}
                                      error={
                                        typeof retryOptions.retry_strategy
                                          .exponential_multiplier !==
                                          'undefined' &&
                                        (retryOptions.retry_strategy
                                          .exponential_multiplier < 2 ||
                                          retryOptions.retry_strategy
                                            .exponential_multiplier > 10)
                                      }
                                      helperText="Min: 2. Max: 10"
                                    />
                                  </Grid>
                                  <Grid item xs={6} className="mt-4 mb-4">
                                    <TextInput
                                      label="Exponential Max Interval (seconds)"
                                      onInputChange={handleRetryOptionsChange}
                                      placeholder="Exponential Max Interval (seconds)"
                                      prop="exponential_max_interval"
                                      type="number"
                                      value={
                                        retryOptions.retry_strategy
                                          .exponential_max_interval ?? 3600
                                      }
                                      minValue={180}
                                      maxValue={86400}
                                      error={
                                        typeof retryOptions.retry_strategy
                                          .exponential_max_interval !==
                                          'undefined' &&
                                        (retryOptions.retry_strategy
                                          .exponential_max_interval < 180 ||
                                          retryOptions.retry_strategy
                                            .exponential_max_interval > 86400)
                                      }
                                      helperText="Min: 180. Max: 86400"
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          )}
                        </div>
                      </>
                    )}
                </div>
              )}
              {actionNotAllowed && (
                <Typography
                  variant="button"
                  component="div"
                  className="mb-3 mt-3"
                  data-cy="insufficient-permissions-message"
                >
                  You have insufficient permissions to carry out this command.
                </Typography>
              )}
              {renderSection(
                unallowedDevices,
                `You don't have permission to issue this ${
                  isGatewayCommand ? 'action' : 'command'
                } to`,
              )}
              {renderSection(
                unsupportedDevices,
                `This ${
                  isGatewayCommand ? 'action' : 'command'
                } isn't supported by`,
              )}
            </>
          )}
        </div>
      }
    />
  );
};

export default IssueCommandDrawer;
