import React, { useState, useEffect } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import {
  Command,
  CommandInput,
  Translators,
  Workflows,
} from '@edgeiq/edgeiq-api-js';
import { Info as InfoIcon } from '@mui/icons-material';
import clsx from 'clsx';

import { setAlert } from '../../../redux/reducers/alert.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setOptionsWorkflows } from '../../../redux/reducers/workflows.reducer';
import { setTranslatorsOptions } from '../../../redux/reducers/translators.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { commandsSenderTypes } from '../../../constants/commands';
import { ItemList } from '../../../components/DynamicRows/constants';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';
import CheckboxInput from '../../../components/CheckboxInput';
import CodeEditor from '../../../components/CodeEditor';
import CommandSenderForm from './CommandSenderForm';
import CommandOptionsForm from './CommandOptionsForm';
import useStyles from './styles';

interface CommandFormProps {
  newCommand: Command | CommandInput;
  invalidSenderJson?: boolean;
  invalidOptionJson?: boolean;
  disabled?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
  onDynamicChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow: (prop: string) => void;
  onRemoveRow: (prop: string, item: string | number) => void;
  onReorder: (prop: string, value: ItemList[]) => void;
}

const CommandForm: React.FC<CommandFormProps> = ({
  newCommand,
  invalidSenderJson,
  disabled,
  onInputChange,
  onDynamicChange,
  onAddRow,
  onRemoveRow,
  onReorder,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const translatorsState = useAppSelector(
    (state: RootState) => state.translators,
  );
  const workflowsState = useAppSelector((state: RootState) => state.workflows);
  const [translatorsOptionsList, setTranslatorsOptionsList] = useState(
    translatorsState.optionsTranslators || [],
  );
  const [workflowsOptionsList, setWorkflowsOptionsList] = useState(
    workflowsState.optionsWorkflows || [],
  );

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    if (
      newCommand.sender_type === 'workflow_sender' &&
      !workflowsOptionsList.length
    ) {
      getWorkflows();
    }
  }, [newCommand.sender_type]);

  useEffect(() => {
    if (workflowsState.optionsWorkflows?.length) {
      setWorkflowsOptionsList(workflowsState.optionsWorkflows);
    }
  }, [workflowsState]);

  useEffect(() => {
    if (translatorsState.optionsTranslators?.length) {
      setTranslatorsOptionsList(translatorsState.optionsTranslators);
    } else {
      getTraslators();
    }
  }, [translatorsState]);

  const getWorkflows = (): void => {
    Workflows.list({}, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setOptionsWorkflows(result.workflows));
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const getTraslators = (): void => {
    Translators.list({}, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setTranslatorsOptions(result.translators));
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} lg={6}>
            <TextInput
              label="Command Name"
              prop="name"
              required={true}
              value={newCommand.name}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <SelectInput
              label="Sender Type"
              prop="sender_type"
              required={true}
              value={newCommand.sender_type}
              onSelectChange={onInputChange}
              disabled={disabled}
              options={Object.keys(commandsSenderTypes).map((key, index) => (
                <MenuItem className="m-4 p-2" key={index} dense value={key}>
                  {commandsSenderTypes[key]}
                </MenuItem>
              ))}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Description"
              prop="long_description"
              classes="full-height"
              multiline
              rows={2}
              value={newCommand.long_description}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container direction="row" spacing={3}>
          {(newCommand.sender_type === 'http_sender' ||
            newCommand.sender_type === 'shell_sender') && (
            <Grid item xs={12}>
              <div className={clsx('br-1 p-4', classes.infoContainer)}>
                <InfoIcon className={clsx('mr-4', classes.infoIcon)} />
                <Typography component="div" variant="button" color={'#fff'}>
                  The {commandsSenderTypes[newCommand.sender_type]} is only
                  supported on Coda (edge) devices.
                </Typography>
              </div>
            </Grid>
          )}

          {newCommand.sender_type !== 'workflow_sender' && (
            <>
              {newCommand.sender_type !== 'gcp_pubsub_sender' ? (
                <Grid item xs={12} lg={6}>
                  <SelectInput
                    label="Translator (optional)"
                    prop="translator_id"
                    value={newCommand.translator_id}
                    onSelectChange={onInputChange}
                    options={[
                      <MenuItem
                        dense
                        className="m-4 p-2"
                        key="no-translator"
                        value=""
                      >
                        None
                      </MenuItem>,
                      ...translatorsOptionsList.map((translator) => (
                        <MenuItem
                          className="m-4 p-2"
                          key={translator._id}
                          dense
                          value={translator._id}
                        >
                          {translator.name}
                        </MenuItem>
                      )),
                    ]}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} display="flex" alignItems="end">
                  <CheckboxInput
                    label="Generate child command executions"
                    prop="generate_child_command_executions"
                    value="generate_child_command_executions_value"
                    checked={
                      newCommand.generate_child_command_executions ?? false
                    }
                    onCheckboxClick={onInputChange}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12} lg={6} display="flex" alignItems="end">
            <CheckboxInput
              label="Save command output"
              prop="save_command_output"
              value="save_command_output_value"
              checked={newCommand.save_command_output ?? false}
              onCheckboxClick={onInputChange}
            />
          </Grid>

          <Grid item xs={12}>
            <CommandSenderForm
              newCommand={newCommand}
              workflowsOptionsList={workflowsOptionsList}
              invalid={invalidSenderJson}
              onAddRow={onAddRow}
              onRemoveRow={onRemoveRow}
              onInputChange={onInputChange}
              onDynamicChange={onDynamicChange}
            />
          </Grid>
          {newCommand.sender_type !== 'http_sender' &&
            newCommand.sender_type !== 'shell_sender' && (
              <Grid item xs={12}>
                <CommandOptionsForm
                  newCommand={newCommand}
                  onAddRow={onAddRow}
                  onRemoveRow={onRemoveRow}
                  onInputChange={onInputChange}
                  onDynamicChange={onDynamicChange}
                  onReorder={onReorder}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <CodeEditor
              label="Payload (optional)"
              labelVariant="subtitle2"
              prop="payload"
              mode="json"
              height={250}
              value={newCommand.payload}
              onInputChange={onInputChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommandForm;
