import React, { useState, ReactElement, useEffect } from 'react';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setFilters } from '../../../redux/reducers/filters.reducer';
import {
  heartBeatStatusLabel,
  heartbeatStatus,
  METADATA_KEY,
} from '../../../app/constants';
import Filters from '../../../containers/Filters';
import AccountFilter from '../../../containers/AccountSelect/AccountFilter';
import ActiveDevicesMoreFilters from './ActiveDevicesMoreFilters';
import useStyles from '../styles';

interface ActiveDevicesFiltersProps {
  total: number;
  deviceTypes: DeviceType[];
}

const ActiveDevicesFilters: React.FC<ActiveDevicesFiltersProps> = ({
  total,
  deviceTypes,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.devices.filters;

  const [deviceTypesSelected, setDeviceTypesSelected] = useState<string[]>([]);
  const [heartbeatSelected, setHeartbeatSelected] = useState<string[]>([]);
  const [moreFilters, setMoreFilters] = useState<Record<string, string>>({});
  const [loadingDescendants, setLoadingDescendants] = useState(false);

  const allDevicesTypeSelected =
    deviceTypes.length > 0 && deviceTypesSelected.length === deviceTypes.length;
  const allHeartbeatSelected =
    heartbeatStatus.length > 0 &&
    heartbeatSelected.length === heartbeatStatus.length;

  useEffect(() => {
    if (filters) {
      setDeviceTypesSelected(
        filters.device_type_id && filters.device_type_id !== ''
          ? filters.device_type_id.split('|')
          : [],
      );
      setHeartbeatSelected(
        filters.heartbeat_status && filters.heartbeat_status !== ''
          ? filters.heartbeat_status.split('|')
          : [],
      );
      setMoreFilters({ ...filters });
    }
  }, [filters]);

  const changeFilters = (prop: string, value: string): void => {
    dispatch(
      setFilters(
        {
          ...filters,
          [prop]: value,
        },
        'devices',
      ),
    );
  };

  const handleMultiSelect =
    (prop: string) =>
    (event: SelectChangeEvent<string[]>): void => {
      const {
        target: { value },
      } = event;

      if (value.includes('all')) {
        if (prop === 'device_type_id') {
          changeFilters(
            prop,
            deviceTypes.reduce((preVal, current, i): string => {
              return i === 0 ? current._id : `${preVal}|${current._id}`;
            }, ''),
          );
        } else {
          changeFilters(
            prop,
            heartbeatStatus.reduce((preVal, current, i): string => {
              return i === 0 ? current : `${preVal}|${current}`;
            }, ''),
          );
        }
        return;
      }

      changeFilters(
        prop,
        typeof value === 'string'
          ? value.replaceAll(',', '|')
          : value.length > 0
          ? value.join('|')
          : '',
      );
    };

  const handleMoreFiltersChange = (
    prop: string,
    value:
      | string
      | {
          [key: string]: string;
        },
  ): void => {
    if (prop === 'metadata') {
      // Delete all the old metadata keys first
      for (const key in moreFilters) {
        if (Object.prototype.hasOwnProperty.call(moreFilters, key)) {
          if (key.indexOf(METADATA_KEY) !== -1) {
            delete moreFilters[key];
          }
        }
      }
      setMoreFilters({
        ...moreFilters,
        ...(value as {
          [key: string]: string;
        }),
      });
    } else {
      setMoreFilters({
        ...moreFilters,
        [prop]: value as string,
      });
    }
  };

  return (
    <Filters
      filterModel="devices"
      inputPlaceholder="Search devices by Unique ID or Name"
      inputKey="search"
      total={total}
      hasSearchInput={true}
      moreFiltersValue={moreFilters}
      moreFiltersApplyLoading={loadingDescendants}
      showCaseSensitive={false}
      moreFilters={
        <ActiveDevicesMoreFilters
          deviceTypes={deviceTypes}
          defaultTab={stateFilters.devices.moreFiltersView || 'accounts'}
          filters={moreFilters}
          onFiltersChange={handleMoreFiltersChange}
          updateLoading={setLoadingDescendants}
        />
      }
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <Select
          className={classes.filterInput}
          multiple
          value={deviceTypesSelected}
          onChange={handleMultiSelect('device_type_id')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | ReactElement => {
            if (selected.length === 0) {
              return <Typography variant="button">Device Profile</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Profiles selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox checked={allDevicesTypeSelected} />
            <ListItemText primary="All" />
          </MenuItem>
          {deviceTypes.map((item, key) => (
            <MenuItem dense key={key} value={item._id}>
              <Checkbox checked={deviceTypesSelected.indexOf(item._id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
      }
      thirdFilter={
        <Select
          className={classes.filterInput}
          multiple
          value={heartbeatSelected}
          onChange={handleMultiSelect('heartbeat_status')}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          renderValue={(selected): string[] | ReactElement => {
            if (selected.length === 0) {
              return <Typography variant="button">Heartbeat</Typography>;
            }

            if (selected.length > 0) {
              return (
                <Typography variant="button">
                  {`Heartbeat selected (${selected.length})`}
                </Typography>
              );
            }

            return <Typography variant="button">{selected}</Typography>;
          }}
        >
          <MenuItem dense value="all">
            <Checkbox checked={allHeartbeatSelected} />
            <ListItemText primary="All" />
          </MenuItem>
          {heartbeatStatus.map((item, key) => (
            <MenuItem dense key={key} value={item}>
              <Checkbox checked={heartbeatSelected.indexOf(item) > -1} />
              <ListItemText primary={heartBeatStatusLabel[item]} />
            </MenuItem>
          ))}
        </Select>
      }
    />
  );
};

export default ActiveDevicesFilters;
