import React, { useState } from 'react';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import GradingIcon from '@mui/icons-material/Grading';
import InfoIcon from '@mui/icons-material/Info';
import { Command, Device, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import AttachCommandsDrawer from '../../RightDrawer/AttachCommands/AttachCommandsDrawer';
import useStyles from './styles';
import DryRunCommand from '../../RightDrawer/DryRunCommand/DryRunCommand';
import { SettingsEditorData } from '../../../models/settings';

interface ConfigurationCommandsProps {
  addPaper?: boolean;
  sendCommands: Command[];
  fetchCommand: Command | undefined;
  onChangeSendCommands: (commands: Command[]) => void;
  onChangeFetchCommand: (command: Command | undefined) => void;
  selectedDevices?: Device[];
  selectedDeviceTypes?: DeviceType[];
  settingsEditorData?: SettingsEditorData;
}

const ConfigurationCommands: React.FC<ConfigurationCommandsProps> = ({
  addPaper,
  fetchCommand,
  sendCommands,
  onChangeFetchCommand,
  onChangeSendCommands,
  selectedDevices,
  selectedDeviceTypes,
  settingsEditorData,
}) => {
  const classes = useStyles();
  const [openSendCommands, setOpenSendCommands] = useState(false);
  const [openFetchCommand, setOpenFetchCommand] = useState(false);
  const [commandDryRunDrawer, setCommandDryRun] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<Command>();

  const handleOpenSendCommands = (): void => {
    setOpenSendCommands(true);
  };

  const handleCloseSendCommands = (): void => {
    setOpenSendCommands(false);
  };

  const handleChooseSendCommands = (commands: Command[]): void => {
    onChangeSendCommands(commands);
    handleCloseSendCommands();
  };

  const handleDeleteSendCommands = (i: number) => (): void => {
    const newCommands = sendCommands.filter((_d, index) => i !== index);
    onChangeSendCommands([...newCommands]);
  };

  const handleOpenFetchCommand = (): void => {
    setOpenFetchCommand(true);
  };

  const handleCloseFetchCommand = (): void => {
    setOpenFetchCommand(false);
  };

  const handleChooseFetchCommand = (commands: Command[]): void => {
    onChangeFetchCommand(commands[0]);
    handleCloseFetchCommand();
  };

  const handleDeleteFetchCommand = (): void => {
    onChangeFetchCommand(undefined);
  };

  const renderInfoSection = (text: string, dataCy: string): JSX.Element => (
    <div className={clsx('br-1 p-4 mb-6', classes.infoContainer)}>
      <InfoIcon className={clsx('mr-4', classes.infoIcon)} />
      <div>
        <Typography
          color={'#fff'}
          component="div"
          data-cy={dataCy}
          variant="button"
        >
          {text}
        </Typography>
      </div>
    </div>
  );

  const renderContent = (): JSX.Element => (
    <Grid container direction="row" spacing={2}>
      <Grid
        item
        xs={12}
        className={clsx({
          ['mt-6']: !addPaper,
        })}
      >
        <Typography className="mb-6" data-cy="send-commands-title" variant="h5">
          User defined commands to send settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {renderInfoSection(
          'These user defined commands are used to deliver the setting to the device.',
          'send-commands-info-subtitle',
        )}
      </Grid>
      <Grid item xs={12}>
        {sendCommands.length === 0 ? (
          <Typography
            className={clsx('mt-4', classes.configSubtitle)}
            component="div"
            data-cy="no-send-commands-text"
            variant="overline"
          >
            No commands selected.
          </Typography>
        ) : (
          <div className={clsx('scrollbar', classes.container)}>
            {sendCommands.map((entity, i) => (
              <Typography
                className={clsx('py-2 fw-700', classes.item)}
                component="div"
                data-cy={`send-command-${entity._id}`}
                key={entity._id}
                noWrap
                variant="button"
              >
                {entity.name}
                <div>
                  <IconButton
                    className="p-0 mr-2"
                    color="primary"
                    data-cy={`remove-send-command-${entity._id}`}
                    size="large"
                    onClick={handleDeleteSendCommands(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    className="p-0 "
                    color="primary"
                    data-cy={`dry-run-send-command-${entity._id}`}
                    size="large"
                    onClick={(): void => handleDryRun(entity)}
                  >
                    <GradingIcon />
                  </IconButton>
                </div>
              </Typography>
            ))}
          </div>
        )}
        <Button
          className="mt-8"
          color="primary"
          data-cy="select-send-commands-button"
          size="large"
          type="button"
          variant="contained"
          onClick={handleOpenSendCommands}
        >
          <Typography variant="button">Select Commands</Typography>
        </Button>

        <AttachCommandsDrawer
          open={openSendCommands}
          singleSelect={false}
          itemCommands={false}
          isDeviceCommands={false}
          preSelectedCommands={sendCommands}
          handleCloseDrawer={handleCloseSendCommands}
          handleChooseCommands={handleChooseSendCommands}
        />
      </Grid>
      <Grid item xs={12} className="mt-6">
        <Typography data-cy="fetch-command-title" className="mb-6" variant="h5">
          User defined command to fetch settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {renderInfoSection(
          'This user defined command is used to fetch the current setting from the device.',
          'fetch-command-info-subtitle',
        )}
      </Grid>
      <Grid item xs={12}>
        {!fetchCommand ? (
          <Typography
            className={clsx('mt-4', classes.configSubtitle)}
            component="div"
            data-cy="no-fetch-command-text"
            variant="overline"
          >
            No command selected.
          </Typography>
        ) : (
          <div className={clsx('scrollbar', classes.container)}>
            <Typography
              className={clsx('py-2 fw-700', classes.item)}
              component="div"
              data-cy={`fetch-command-${fetchCommand._id}`}
              key={fetchCommand?._id}
              noWrap
              variant="button"
            >
              {fetchCommand?.name}
              <IconButton
                className="p-0"
                color="primary"
                data-cy={`remove-fetch-command-${fetchCommand._id}`}
                size="large"
                onClick={handleDeleteFetchCommand}
              >
                <DeleteIcon />
              </IconButton>
            </Typography>
          </div>
        )}
        <Button
          className="mt-8"
          color="primary"
          data-cy="select-fetch-command-button"
          size="large"
          type="button"
          variant="contained"
          onClick={handleOpenFetchCommand}
        >
          <Typography variant="button">Select Command</Typography>
        </Button>

        <AttachCommandsDrawer
          open={openFetchCommand}
          singleSelect={true}
          itemCommands={false}
          isDeviceCommands={false}
          preSelectedCommands={fetchCommand ? [fetchCommand] : []}
          handleCloseDrawer={handleCloseFetchCommand}
          handleChooseCommands={handleChooseFetchCommand}
        />
      </Grid>
    </Grid>
  );

  const handleCloseCommandDryRun = (): void => {
    setSelectedRecord(undefined);
    setCommandDryRun(false);
  };

  const handleDryRun = (command: Command): void => {
    setSelectedRecord(command);
    setCommandDryRun(true);
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        {addPaper ? (
          <Paper className="shadow p-8">{renderContent()}</Paper>
        ) : (
          renderContent()
        )}
      </Grid>

      {selectedRecord && (
        <DryRunCommand
          open={commandDryRunDrawer}
          command={selectedRecord}
          devices={selectedDevices}
          deviceProfile={
            selectedDeviceTypes ? selectedDeviceTypes[0] : undefined
          }
          settings={settingsEditorData}
          handleCloseDrawer={handleCloseCommandDryRun}
        />
      )}
    </Grid>
  );
};

export default ConfigurationCommands;
