import React from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import { User, UserType } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';
import { ADMIN_USER_TYPE_NAME } from '../../../users/constants';
import { titleCase } from '../../../../helpers/utils';

interface UserFormProps {
  isValidEmail: boolean;
  user: User;
  userTypes: UserType[];
  onInputChange: (prop: string, value: string | number) => void;
}

const UserForm: React.FC<UserFormProps> = ({
  onInputChange,
  user,
  userTypes,
  isValidEmail,
}) => {
  return (
    <Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="First Name"
            value={user.first_name}
            prop="first_name"
            required={true}
            onInputChange={onInputChange}
          />
        </Grid>

        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Last Name"
            value={user.last_name}
            prop="last_name"
            required={true}
            onInputChange={onInputChange}
          />
        </Grid>

        <Grid item xs={12} md={6} className="mt-6">
          <TextInput
            label="Email/Username"
            value={user.email}
            prop="email"
            required={true}
            onInputChange={onInputChange}
            error={!isValidEmail}
          />
          {!isValidEmail && (
            <Typography variant="body2" color="error">
              Invalid email
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6} className="mt-6">
          <SelectInput
            label="Role"
            placeholder="Role"
            prop="user_type_id"
            required={true}
            value={user.user_type_id}
            onSelectChange={onInputChange}
            options={userTypes
              .filter((type) => type.name !== ADMIN_USER_TYPE_NAME)
              .map((userType) => (
                <MenuItem dense key={userType._id} value={userType._id}>
                  {titleCase(userType.name)}
                </MenuItem>
              ))}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserForm;
