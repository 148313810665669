import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Integration, RuleAction } from '@edgeiq/edgeiq-api-js';
import SelectInput from '../../../../../components/SelectInput';
import CodeEditor from '../../../../../components/CodeEditor';

interface SingleIntegrationSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
  integrations: Integration[];
  codeEditorLabel?: string;
}

const SingleIntegrationSection: React.FC<SingleIntegrationSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  integrations,
  disabled,
  codeEditorLabel,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <SelectInput
          label="Integration"
          value={action.integration_id}
          prop={`${actionIndex}.integration_id`}
          onSelectChange={onInputChange}
          options={integrations.map((integration, key) => (
            <MenuItem
              className="m-4 p-2"
              key={key}
              dense
              value={integration._id}
            >
              {integration.name}
            </MenuItem>
          ))}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <CodeEditor
          label={codeEditorLabel}
          prop={`${actionIndex}.body_template`}
          onInputChange={onInputChange}
          value={action.body_template}
          height={400}
        />
      </Grid>
    </>
  );
};

export default SingleIntegrationSection;
