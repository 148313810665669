import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Box, Paper } from '@mui/material';
import { Commands, Command, Device, Devices } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getCommandSelector,
  setActualCommand,
  setNewCommand,
} from '../../redux/reducers/commands.reducer';
import Header from '../../containers/HeaderWithActionButton';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { LocationState } from '../../models/common';
import { errorHighlight } from '../../app/constants';
import { commandsSenderTypes } from '../../constants/commands';
import { dispatchError, isNotFoundFallback } from '../../helpers/utils';
import {
  handleCommandDynamicValues,
  handleCommandAddRow,
  handleCommandRemoveRow,
  handleCommandChange,
  handleCommandReorderChange,
  mapExecuteCommandDefaultOptions,
  parseDefaultOptionsValues,
} from '../../containers/Forms/CommandForm/helper';
import SelectDevicesDrawer from '../../containers/RightDrawer/SelectDevices/SelectDevicesDrawer';
import ExecuteCommandDrawer from '../../containers/RightDrawer/ExecuteCommand/ExecuteCommandDrawer';
import CommandForm from '../../containers/Forms/CommandForm';
import { ItemList } from '../../components/DynamicRows/constants';
import ContentHeader from '../../components/ContentHeader';
import FooterBar from '../../components/FooterBar';
import ActionDialog from '../../components/ActionDialog';
import CommandExecutionList from './commandExecutionList';

const CommandContent: React.FC = () => {
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const commandListRef = useRef<{
    refreshExecutionList: () => void;
  }>();
  const commandData = useAppSelector((state: RootState) =>
    getCommandSelector(state.commands, id),
  );
  const newCommand = useAppSelector(
    (state: RootState) => state.commands.newCommand,
  );
  const [commandToExecute, setCommandToExecute] = useState<Command>();
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [loading, setLoading] = useState(false);
  const [invalidSenderJson, setInvalidSenderJson] = useState(false);
  const [invalidOptionJson, setInvalidOptionJson] = useState(false);
  const [executeCommandDrawer, setExecuteCommandDrawer] = useState(false);
  const [deviceDrawerOpen, setDeviceDrawerOpen] = useState(false);
  const [executeCommandDialog, setExecuteCommandDialog] = useState(false);
  const errorDispatcher = dispatchError(dispatch);
  const [commandCompany] = useFetchCompany(
    commandData?.company_id,
    errorDispatcher,
  );
  const fallbackToMainCommand = (): void => {
    navigate('/commands');
  };

  const handleOpenDeviceDrawer = (): void => {
    setDeviceDrawerOpen(true);
  };

  const handleCloseDeviceDrawer = (): void => {
    setDeviceDrawerOpen(false);
  };

  useEffect(() => {
    if (newCommand) {
      setCommandToExecute(mapExecuteCommandDefaultOptions(newCommand));
    }
  }, [newCommand]);

  useEffect(() => {
    if (commandData && commandData._id === id) {
      dispatch(setActualCommand(commandData));
    } else if (id) {
      Commands.getOneById(id)
        .then((response) => {
          dispatch(setActualCommand(response));
        })
        .catch((err) => {
          isNotFoundFallback(fallbackToMainCommand, err.message);
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [id]);

  const handleValueChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    if (newCommand) {
      dispatch(
        setNewCommand(
          handleCommandChange(
            newCommand,
            prop,
            value,
            setInvalidSenderJson,
            setInvalidOptionJson,
          ) as Command,
        ),
      );
    }
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    dispatch(
      setNewCommand(
        handleCommandDynamicValues(
          newCommand as Command,
          prop,
          value,
          field,
          index,
        ) as Command,
      ),
    );
  };

  const handleAddRow = (prop: string): void => {
    dispatch(
      setNewCommand(
        handleCommandAddRow(newCommand as Command, prop) as Command,
      ),
    );
  };

  const handleRemoveRow = (prop: string, item: string | number): void => {
    dispatch(
      setNewCommand(
        handleCommandRemoveRow(newCommand as Command, prop, item) as Command,
      ),
    );
  };

  const handleReorderRows = (prop: string, value: ItemList[]): void => {
    dispatch(
      setNewCommand(
        handleCommandReorderChange(
          newCommand as Command,
          prop,
          value,
        ) as Command,
      ),
    );
  };

  const handleDeviceCallback = (devices: Device[]): void => {
    handleCloseDeviceDrawer();
    setSelectedDevices(devices);

    if (newCommand && newCommand.default_options?.length !== 0) {
      return setExecuteCommandDrawer(true);
    }

    setExecuteCommandDialog(true);
  };

  const handleCloseExecuteCommandDrawer = (): void => {
    setExecuteCommandDrawer(false);
  };

  const handleCloseExecuteCommandDialog = (): void => {
    setExecuteCommandDialog(false);
    setSelectedDevices([]);
  };

  const handleSaveChanges = (): void => {
    if (newCommand && newCommand !== commandData) {
      setLoading(true);
      Commands.update(parseDefaultOptionsValues(newCommand) as Command)
        .then((response) => {
          dispatch(setActualCommand(response));
          dispatch(setNewCommand(response));
          dispatch(
            setAlert({
              highlight: 'Update command',
              message: 'Command successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleExecuteOptionChange = (
    prop: string,
    value: string | number,
  ): void => {
    if (commandToExecute) {
      setCommandToExecute({
        ...commandToExecute,
        options: {
          ...commandToExecute.options,
          [prop]: value,
        },
      });
    }
  };

  const manageExecuteError = (error: Error): void => {
    setExecuteCommandDialog(false);
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: error.message,
        type: 'error',
      }),
    );
  };

  const handleExecuteCommand = (): void => {
    if (commandToExecute && selectedDevices.length) {
      if (selectedDevices.length === 1) {
        Commands.executeOnDevice(
          commandToExecute._id,
          selectedDevices[0]._id,
          commandToExecute.options,
        )
          .then((commandExecution) => {
            dispatch(
              setAlert({
                highlight: `Command Execution ID: ${commandExecution._id}`,
                message: `Command executed with success on device ${selectedDevices[0].name}`,
                type: 'success',
              }),
            );
            if (commandListRef.current) {
              commandListRef.current.refreshExecutionList();
            }
            handleCloseExecuteCommandDialog();
          })
          .catch((error) => {
            manageExecuteError(error as Error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        Devices.bulkExecuteCommand(
          commandToExecute._id,
          selectedDevices.map((d) => d._id),
          commandToExecute.options,
          commandToExecute.generate_child_command_executions,
        )
          .then((_res) => {
            dispatch(
              setAlert({
                link: 'messages#bulk-jobs',
                linkText: 'bulk job',
                message:
                  'A <link> has been created to issue the command to the selected devices',
                type: 'success',
              }),
            );
            if (commandListRef.current) {
              commandListRef.current.refreshExecutionList();
            }
            // Only reset the devices when the command was successfuly excuted
            handleCloseExecuteCommandDialog();
          })
          .catch((error) => {
            manageExecuteError(error as Error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleDeleteCommand = (): void => {
    if (!commandData) {
      return;
    }
    setLoading(true);

    Commands.delete(commandData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete command',
            message: 'Command successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/commands');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cannotBeSaved = (): boolean => {
    let result: boolean = false;
    if (newCommand?.sender_type === 'workflow_sender') {
      result =
        typeof newCommand.sender?.workflow_id === 'string' &&
        newCommand.sender.workflow_id === '';
    }
    if (result) {
      return result;
    }

    result =
      isEqual(newCommand, commandData) &&
      !invalidSenderJson &&
      !invalidOptionJson;

    return result;
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        model="command"
        title="Command content"
        goBack={goBackUrl ? goBackUrl : 'commands'}
        goBackLabel={goBackLabel || `Commands`}
      />
      {newCommand && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="command"
            title={newCommand.name}
            commandType={commandsSenderTypes[newCommand?.sender_type] as string}
            subtitle={newCommand._id}
            extraImage={commandCompany?.branding?.logo_url}
            extraTitle={commandCompany?.name}
            extraSubtitle={commandCompany?._id}
            hideOverline={false}
            copySubtitleToClipboard={true}
          />
          <Grid
            container
            columnSpacing={3}
            direction="row"
            className="py-6 px-8"
          >
            <Grid item xs={12} md={8}>
              <Paper className="p-7 shadow">
                <CommandForm
                  invalidSenderJson={invalidSenderJson}
                  invalidOptionJson={invalidOptionJson}
                  newCommand={newCommand as Command}
                  onInputChange={handleValueChange}
                  onAddRow={handleAddRow}
                  onRemoveRow={handleRemoveRow}
                  onDynamicChange={handleDynamicChange}
                  onReorder={handleReorderRows}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper className="p-7 shadow">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleOpenDeviceDrawer}
                >
                  Execute Command
                </Button>
                <CommandExecutionList
                  ref={commandListRef}
                  commandId={id as string}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this command"
        loading={loading}
        disableSaveButton={cannotBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteCommand}
      />

      <SelectDevicesDrawer
        actionLabel="Execute"
        open={deviceDrawerOpen}
        selectedDevices={[]}
        onCloseDrawer={handleCloseDeviceDrawer}
        onChoosingDevices={handleDeviceCallback}
        companyId={commandCompany?._id}
      />

      <ActionDialog
        open={executeCommandDialog}
        loading={loading}
        onDeleteCallback={handleExecuteCommand}
        onCloseCallback={handleCloseExecuteCommandDialog}
        content={`You are about to execute the command: ${newCommand?.name}`}
        actionButtonLabel="Execute"
      />

      <ExecuteCommandDrawer
        open={executeCommandDrawer}
        command={commandToExecute}
        handleCloseDrawer={handleCloseExecuteCommandDrawer}
        onExecuteCommand={handleExecuteCommand}
        onOptionChange={handleExecuteOptionChange}
      />
    </Grid>
  );
};

export default CommandContent;
