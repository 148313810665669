import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
// import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  PaginationFilter,
  BulkResponse,
  BulkResponses,
} from '@edgeiq/edgeiq-api-js';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setSorting } from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  DELETE_BULK_TYPE_KEY,
  errorHighlight,
} from '../../../app/constants';
import { SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import ListSelection from '../../../components/ListSelection';
import SharedTable from '../../../components/SharedTable';
import BulkJobInformationDrawer from '../../../containers/RightDrawer/BulkJobInformationDrawer/BulkJobInformationDrawer';
import { OLDEST_NEWEST_SORTING } from '../../../constants/sortings';
import useStyles from '../styles';
import BulkJobsFilters from './BulkJobsFilters';
import { BulkJobsColumns } from './columns';

const BulkJobs: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.bulkJobs.sortBy, OLDEST_NEWEST_SORTING),
  );
  const [bulkResponses, setBulkResponses] = useState<BulkResponse[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const [bulkjobInformationDrawer, setBulkjobInformationDrawer] =
    useState(false);
  const [selectedBulkResponse, setSelectedBulkResoponse] = useState<
    undefined | BulkResponse
  >();

  const handleCloseJobInformationDrawer = (): void => {
    setBulkjobInformationDrawer(false);
  };

  const handleOpenExecutionInformationDrawer = (item: BulkResponse): void => {
    setSelectedBulkResoponse(item);
    setBulkjobInformationDrawer(true);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  // In case there is a filter for the type and the value has `delete_`, a change is needed so that the `parseFilters` logic works correctly
  const parseBulkFilters = (bulkFilters: {
    [key: string]: string;
  }): { [key: string]: string } => {
    const newFilters = cloneDeep(bulkFilters);
    if (newFilters.type && newFilters.type.indexOf('delete') !== -1) {
      // We delete the entry `type` and replace it with `delete_bulk_type` that will be parsed in `parseFilters`
      // the value is irrelevant in this case.
      delete newFilters.type;
      return {
        ...newFilters,
        [DELETE_BULK_TYPE_KEY]: 'delete',
      };
    }
    return newFilters;
  };

  const getBulkResponses = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    const bulkFilters: { [key: string]: string } = filters.bulkJobs.filters
      ? {
          ...parseBulkFilters(filters.bulkJobs.filters),
        }
      : {};
    bulkFilters.lazy_loading = 'true';
    BulkResponses.list(
      parseFilters(bulkFilters, filters.case_sensitive),
      pagination,
    )
      .then((result) => {
        const results: BulkResponse[] = addPage
          ? [...bulkResponses, ...result.bulkResponses]
          : result.bulkResponses;
        setBulkResponses(results);
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getBulkResponses(1);
  }, [filters.bulkJobs]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getBulkResponses(page + 1, true);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'commandsExecutions'));
    setSelectedSorting(option);
  };

  const handleActionClick = (item: BulkResponse, action: 'details'): void => {
    switch (action) {
      case 'details':
        handleOpenExecutionInformationDrawer(item);
        break;
      default:
        break;
    }
  };

  return (
    <Grid container direction="column" spacing={0}>
      <BulkJobsFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={'list'}
        sortingOptions={OLDEST_NEWEST_SORTING}
        viewsOptions={[]}
        itemsSelected={false}
        allSelected={false}
        deleteAction={true}
        xPadding={false}
        sortingCallback={handleSorting}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <SharedTable
            columns={BulkJobsColumns(handleActionClick)}
            rows={bulkResponses}
            sortBy={selectedSorting.value}
            sortDirection={
              selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
            }
            allSelected={false}
            loading={loading}
            selectedItemsIds={[]}
            xPadding={false}
            onRequestSort={handleTableSorting}
            viewOnly={true}
          />

          {bulkResponses.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      {selectedBulkResponse && (
        <BulkJobInformationDrawer
          open={bulkjobInformationDrawer}
          handleCloseDrawer={handleCloseJobInformationDrawer}
          bulkResponse={selectedBulkResponse}
        />
      )}
    </Grid>
  );
};

export default BulkJobs;
