import React from 'react';
import { Grid, Typography } from '@mui/material';
import { SoftwareVersion } from '@edgeiq/edgeiq-api-js';

import timeHelpers from '../../../helpers/timeHelpers';
import DetailsDrawer from '../../../components/DetailsDrawer';
import { useUserWithType } from '../../../hooks/useUserWithType';

export interface SoftwareVersionDetailsDrawerProps {
  open: boolean;
  softwareVersion: SoftwareVersion;
  handleCloseDrawer: () => void;
}
const SoftwareVersionDetailsDrawer: React.FC<
  SoftwareVersionDetailsDrawerProps
> = ({ open, softwareVersion, handleCloseDrawer }) => {
  const { user } = useUserWithType(softwareVersion.user_id);
  return (
    <DetailsDrawer
      open={open}
      handleCloseDrawer={handleCloseDrawer}
      title={`Software Version: ${softwareVersion.name}`}
      date={
        softwareVersion.created_at
          ? timeHelpers.getDate(softwareVersion.created_at)
          : '--'
      }
      dateLabel="First seen at"
      userName={user ? `${user?.first_name} ${user?.last_name}` : ''}
      subtitle1={softwareVersion.source}
      subtitle1Label="Source"
      subtitle2={softwareVersion.version_raw}
      subtitle2Label="Version"
    >
      <>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className="custom-label">
            Revision
          </Typography>
          <Typography variant="subtitle2" className="custom-label">
            {softwareVersion.revision}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className="custom-label">
            Publisher
          </Typography>
          <Typography variant="subtitle2" className="custom-label">
            {softwareVersion.publisher ? softwareVersion.publisher : '--'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className="custom-label">
            Track
          </Typography>
          <Typography variant="subtitle2" className="custom-label">
            {softwareVersion.track ? softwareVersion.track : '--'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" className="custom-label">
            Notes
          </Typography>
          <Typography variant="subtitle2" className="custom-label">
            {softwareVersion.notes ? softwareVersion.notes : '--'}
          </Typography>
        </Grid>
      </>
    </DetailsDrawer>
  );
};

export default SoftwareVersionDetailsDrawer;
