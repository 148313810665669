import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Box,
  Paper,
} from '@mui/material';
import {
  IntegrationEvent,
  Integrations,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { defaultItemsPerPage, errorHighlight } from '../../../app/constants';
import timeHelpers from '../../../helpers/timeHelpers';
import TimelineCard from '../../../components/TimelineCard';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';

interface IntegrationEventsProps {
  integrationId: string;
}

const IntegrationEvents: React.FC<IntegrationEventsProps> = ({
  integrationId,
}) => {
  const dispatch = useAppDispatch();

  const [integrationEvents, setIntegrationEvents] = useState<
    IntegrationEvent[]
  >([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedIntegrationEvent, setSelectedIntegrationEvent] =
    useState<IntegrationEvent>();
  const [infoDrawer, setInfoDrawer] = useState(false);

  const handleOpenDrawer = (
    r: IntegrationEvent | undefined = undefined,
  ): void => {
    setInfoDrawer(!infoDrawer);
    if (r) {
      setSelectedIntegrationEvent(r);
    }
  };

  useEffect(() => {
    setLoading(true);
    getReports(1, false);
  }, [integrationId]);

  const getReports = (pageNumber: number, addPage: boolean): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      page: pageNumber,
    };

    Integrations.listIntegrationEvents(
      {
        integration_id: {
          key: 'integration_id',
          operator: 'eq',
          value: integrationId,
        },
      },
      pagination,
    )
      .then((res) => {
        const events = addPage
          ? [...integrationEvents, ...res.integrationEvents]
          : res.integrationEvents;

        setIntegrationEvents(events);
        setTotalAndPage(res.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };
  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getReports(page + 1, true);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const openActivityDrawer = (activity: IntegrationEvent) => (): void => {
    handleOpenDrawer(activity);
  };

  return (
    <Grid item xs={12} md={4}>
      <Paper className="p-7 shadow">
        <Typography className={clsx('mb-4')} variant="h5">
          Integration Events
        </Typography>
        {loading ? (
          <Grid container className="loading-container">
            <CircularProgress size={75} thickness={5} />
          </Grid>
        ) : (
          <>
            {integrationEvents.map((activity, i) => (
              <TimelineCard
                key={i}
                extraLabel="View Details"
                titleWithToolTip={true}
                title={activity.origin}
                tag={activity.type}
                type={activity.type}
                isLast={i === integrationEvents.length - 1}
                caption={timeHelpers.getDate(activity.created_at, false, true)}
                extraCallback={openActivityDrawer(activity)}
              />
            ))}
            {integrationEvents.length !== total && (
              <Grid item xs={12} className={clsx('mb-9 loading-container')}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleLoadMore}
                >
                  {!loadingMore ? (
                    <Typography variant="button">Load more</Typography>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Button>
              </Grid>
            )}
            {integrationEvents.length === 0 && (
              <Grid item xs={12}>
                <Typography
                  variant="button"
                  data-cy="no-integration-events-message"
                >
                  No events recored for this integration.
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Paper>

      <RightDrawer
        open={infoDrawer}
        actionLabel="Done"
        title={'Error Details'}
        disableAction={false}
        actionCallback={handleOpenDrawer}
        onCloseDrawer={handleOpenDrawer}
        content={
          <Box>
            <div>
              <Typography variant="h5" className="custom-label">
                Type
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedIntegrationEvent?.type}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Origin
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedIntegrationEvent?.origin}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Date
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedIntegrationEvent?.created_at}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Message
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedIntegrationEvent?.message}
              </Typography>
            </div>
          </Box>
        }
      />
    </Grid>
  );
};

export default IntegrationEvents;
