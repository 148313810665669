import { Typography, TypographyVariant } from '@mui/material';
import React, { useRef, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-json5';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import clsx from 'clsx';

import { useWindowSize } from '../../hooks/useWindowSize';
import SwitchButton from '../SwitchButton';

export interface CodeEditorProps {
  prop: string;
  value?: string;
  onInputChange?: (prop: string, value: string) => void;
  label?: string;
  labelVariant?: TypographyVariant;
  height?: number;
  mode?: string; // check https://securingsincity.github.io/react-ace/ to see available languages modes
  disabled?: boolean;
  showInvalid?: boolean;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  prop,
  value,
  onInputChange,
  label,
  labelVariant,
  height,
  mode,
  disabled,
  showInvalid,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [theme, setTheme] = useState('monokai');
  // Just using this hook to get the resizing values, makes the rerender runs everytime the screen is resizing and so
  // the AceEditor width is adjusted to its parent div
  const _windowSize = useWindowSize();

  const handleChange = (newValue: string): void => {
    if (onInputChange) {
      onInputChange(prop, newValue);
    }
  };

  const handleTheme = (_prop: string, themeValue: boolean): void => {
    setTheme(themeValue ? 'monokai' : 'github');
  };

  return (
    <div className="w-100" ref={anchorRef}>
      {label && (
        <Typography
          data-testid="label"
          className="mb-2"
          variant={labelVariant ?? 'body2'}
        >
          {label}
        </Typography>
      )}
      <div>
        <div
          className={clsx('mb-2 d-flex', {
            ['flex-justify-between']: showInvalid,
            ['flex-justify-end']: !showInvalid,
          })}
        >
          {showInvalid && (
            <Typography color="red" variant="button">
              Invalid JSON
            </Typography>
          )}
          <SwitchButton
            label="Dark theme"
            value={theme !== 'github'}
            prop="theme"
            topMargin={false}
            onSwitchChange={handleTheme}
          />
        </div>
        {anchorRef.current && (
          <AceEditor
            data-cy={prop}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            enableSnippets={true}
            height={`${height}px`}
            mode={mode ?? 'javascript'}
            onChange={handleChange}
            readOnly={disabled ?? false}
            theme={theme}
            value={value}
            width={`${anchorRef.current?.clientWidth ?? 500}px`}
          />
        )}
      </div>
    </div>
  );
};

export default CodeEditor;
