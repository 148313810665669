import React from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import clsx from 'clsx';

import { SortingOption } from '../../models/common';

export interface ToggleOptionsProps {
  label?: string;
  inlineLabel?: boolean;
  rightSideOptions?: boolean;
  chosenView?: string;
  optionsKey: string;
  options: SortingOption[];
  onViewChange: React.Dispatch<React.SetStateAction<string>>;
}

const ToggleOptions: React.FC<ToggleOptionsProps> = ({
  label,
  inlineLabel,
  rightSideOptions,
  chosenView,
  optionsKey,
  options,
  onViewChange,
}) => {
  const handleView = (
    _event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ): void => {
    if (newView) {
      onViewChange(newView);
    }
  };

  return (
    <div
      className={clsx({
        ['d-flex flex-justify-between flex-items-center']: rightSideOptions,
      })}
    >
      {label && (
        <Typography
          variant="subtitle2"
          component={inlineLabel ? 'span' : 'div'}
          className={clsx({
            ['mr-3']: inlineLabel && !rightSideOptions,
          })}
        >
          {label}
        </Typography>
      )}
      <div>
        <Typography variant="button" className="mr-3">
          Select a view:
        </Typography>
        <ToggleButtonGroup exclusive value={chosenView} onChange={handleView}>
          {options.map((option) => (
            <ToggleButton
              key={`${optionsKey}_${option.value}`}
              aria-label={`${option.value} view`}
              color="primary"
              size="small"
              value={option.value}
            >
              <Typography variant="button">{option.label}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default ToggleOptions;
