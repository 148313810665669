import React, { PropsWithChildren, ReactElement } from 'react';
import { Grid, Typography, Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  AccountTree,
  DevicesOther,
  HomeMax,
  Devices,
  DeviceHub,
  Aod,
  OnDeviceTraining,
  HearingOutlined,
  SettingsInputComponent,
  WorkHistory,
} from '@mui/icons-material';
import clsx from 'clsx';

import { profileTypesMap, profileRolesMap } from '../../app/constants';
import { StatusTheme } from '../../models/common';
import ColoredBox from '../ColoredBox';
import useStyles from './styles';
import TypographyWithCopy from '../TypographyWithCopy';
import {
  INGESTOR_LISTENER_TYPES_MAP,
  INGESTOR_TYPES_MAP,
} from '../../constants/ingestors';

export interface ContentHeaderProps {
  title: string; // Main title of the header
  image?: string; // Main image used at the side of the title
  tag?: string; // Tag used after the title
  tagTheme?: { [key: string]: StatusTheme }; // Color Theme of the tag
  subtitle?: string; // Text set below the title
  rightContent?: ReactElement; // If more complicated content needs to be rendered at the right side on the header. Replaces all 'extra' content
  extraImage?: string; // Image set at the right part of the header, used most as the companies image
  extraTitle?: string; // Title set at the right part of the header, used most as the companies title
  extraSubtitle?: string; // Text set at the right part of the header, used most as the companies id
  profileName?: string;
  profileType?: string;
  jobType?: string;
  profileRole?: string;
  profileId?: string;
  integrationType?: string;
  commandType?: string;
  nameInitials?: string;
  origin?: string;
  hideTitleImage?: boolean;
  ingestorType?: string;
  listenerType?: string;
  policyType?: string;
  copySubtitleToClipboard?: boolean;
  copyExtraSubtitleToClipboard?: boolean;
  titleTooltip?: string;
  subtitleTooltip?: string;
  extraTitleTooltip?: string;
  extraSubtitleTooltip?: string;
  goBackUrl?: string;
  hideOverline?: boolean;
  contentType:
    | 'device'
    | 'device-type'
    | 'company'
    | 'integration'
    | 'discovered-device'
    | 'user'
    | 'command'
    | 'scheduled-job'
    | 'software-package'
    | 'translator'
    | 'ingestor'
    | 'pollable-attribute'
    | 'configuration'
    | 'config-connection'
    | 'policy'
    | 'workflow';
}

const ContentHeader: React.FC<PropsWithChildren<ContentHeaderProps>> = ({
  title,
  image,
  tag,
  tagTheme,
  subtitle,
  rightContent,
  extraImage,
  extraTitle,
  extraSubtitle,
  contentType,
  profileName,
  profileType,
  profileRole,
  profileId,
  integrationType,
  policyType,
  commandType,
  jobType,
  nameInitials,
  origin,
  hideTitleImage = false,
  ingestorType,
  listenerType,
  copySubtitleToClipboard,
  copyExtraSubtitleToClipboard,
  titleTooltip,
  subtitleTooltip,
  extraTitleTooltip,
  extraSubtitleTooltip,
  goBackUrl,
  hideOverline = true,
  children,
}) => {
  const classes = useStyles({ image: '' });

  const renderOverline = (): ReactElement => {
    switch (contentType) {
      case 'device':
        return (
          <Typography variant="overline">
            Profile:{' '}
            <Link
              to={`/device-profile/${profileId}`}
              className={classes.overlineProfileName}
              data-cy="device-profile-link"
              state={{
                goBackLabel: `Device: ${title}`,
                goBackUrl,
              }}
            >
              {profileName}
            </Link>
            <Devices className={clsx('ml-2 mr-1', classes.overlineIcon)} />
            Type: {profileTypesMap[profileType as string]}, Role:{' '}
            {profileRolesMap[profileRole as string]}
          </Typography>
        );
      case 'device-type':
        return (
          <Typography variant="overline">
            <Devices className={clsx('mr-1', classes.overlineIcon)} />
            Type: {profileTypesMap[profileType as string]}, Role:{' '}
            {profileRolesMap[profileRole as string]}
          </Typography>
        );
      case 'integration':
        return (
          <Typography variant="overline">
            Type:{' '}
            <span className={classes.overlineProfileName}>
              {integrationType}
            </span>
          </Typography>
        );
      case 'user':
        return (
          <Typography variant="overline">
            Parent Account:{' '}
            <Link
              to={`/account/${profileName}`}
              className={classes.overlineProfileName}
              data-cy="parent-account-link"
            >
              {profileName}
            </Link>
          </Typography>
        );
      case 'command':
        return (
          <Typography variant="overline">
            Sender Type:{' '}
            <span className={classes.overlineProfileName}>{commandType}</span>
          </Typography>
        );
      case 'translator':
        return (
          <Typography variant="overline">
            Origin:{' '}
            <span className={classes.overlineProfileName}>{origin}</span>
          </Typography>
        );
      case 'ingestor':
        return (
          <Typography variant="overline">
            Type:{' '}
            <span className={classes.overlineProfileName}>
              {INGESTOR_TYPES_MAP[ingestorType as string]}
            </span>
            <HearingOutlined
              className={clsx('ml-2 mr-1', classes.overlineIcon)}
            />
            {INGESTOR_LISTENER_TYPES_MAP[listenerType as string]}
          </Typography>
        );
      case 'policy':
        return (
          <Typography variant="overline">
            Type:{' '}
            <span className={classes.overlineProfileName}>{policyType}</span>
          </Typography>
        );
      case 'scheduled-job':
        return (
          <Typography variant="overline">
            Job Type:{' '}
            <span className={classes.overlineProfileName}>{jobType}</span>
          </Typography>
        );
      default:
        return <></>;
    }
  };

  const renderSubtitle = (): string => {
    switch (contentType) {
      case 'device':
      case 'discovered-device':
        return `Unique Id: ${subtitle}`;
      default:
        return `Id: ${subtitle}`;
    }
  };

  const renderIcon = (): ReactElement | null => {
    switch (contentType) {
      case 'device':
        return <HomeMax data-testid="device-icon" fontSize="inherit" />;
      case 'device-type':
      case 'config-connection':
        return (
          <DevicesOther
            data-testid="config-connection-icon"
            fontSize="inherit"
          />
        );
      case 'integration':
        return <DeviceHub fontSize="inherit" data-testid="integration-icon" />;
      case 'command':
        return <Aod fontSize="inherit" data-testid="command-icon" />;
      case 'translator':
        return (
          <OnDeviceTraining fontSize="inherit" data-testid="translator-icon" />
        );
      case 'ingestor':
        return (
          <SettingsInputComponent
            fontSize="inherit"
            data-testid="ingestor-icon"
          />
        );
      case 'scheduled-job':
        return (
          <WorkHistory fontSize="inherit" data-testid="scheduled-job-icon" />
        );
      case 'workflow':
        return <AccountTree fontSize="inherit" data-testid="workflow-icon" />;
      default:
        return null;
    }
  };

  const renderTitle = (): JSX.Element => (
    <Typography
      data-cy="header-title"
      variant="h3"
      noWrap
      className={classes.title}
    >
      {title}
    </Typography>
  );

  const renderExtraTitle = (): JSX.Element => (
    <Typography
      data-cy="header-extraTitle"
      variant="body2"
      noWrap
      className={classes.extraTitle}
    >
      {extraTitle}
    </Typography>
  );

  return (
    <Grid item xs={12} className={clsx('px-8 pb-6', classes.container)}>
      {nameInitials ? (
        <Typography className={clsx('loading-container', classes.initials)}>
          {nameInitials}
        </Typography>
      ) : (
        <>
          {!hideTitleImage && !image && (
            <Box data-testid="icon-box" className={clsx(classes.imageBox)}>
              {renderIcon()}
            </Box>
          )}
          {image && (
            <div className={classes.logoContainer}>
              <img
                src={image}
                className={classes.extraImage}
                alt="account-logo"
              />
            </div>
          )}
        </>
      )}
      <Box className={clsx('px-6')}>
        {!hideOverline && (
          <Box className={clsx('pb-5', classes.overline)}>
            {renderOverline()}
          </Box>
        )}
        <Box className={clsx(classes.titleContainer)}>
          {titleTooltip ? (
            <Tooltip placement="top" title={titleTooltip}>
              {renderTitle()}
            </Tooltip>
          ) : (
            renderTitle()
          )}
          {tag && tagTheme && (
            <ColoredBox
              className={clsx('ml-4', classes.tag)}
              type="heartbeat_status"
              value={tag}
              colorTheme={tagTheme[tag]}
            />
          )}
        </Box>

        {subtitle && copySubtitleToClipboard && (
          <TypographyWithCopy
            component="div"
            dataCy="header-subtitle"
            textClassName={classes.subtitle}
            typographyVariant="subtitle1"
            text={renderSubtitle()}
            textToCopy={subtitle}
            textMaxWidth={700}
            tooltipText={subtitleTooltip}
          />
        )}
        {subtitle && !copySubtitleToClipboard && (
          <Typography
            data-cy="header-subtitle"
            className={clsx(classes.subtitle)}
            variant="subtitle1"
            noWrap
          >
            {renderSubtitle()}
          </Typography>
        )}
      </Box>

      <Box className={clsx('ml-a', classes.extraContainer)}>
        {rightContent ? (
          rightContent
        ) : (
          <>
            {extraImage && (
              <div>
                <img
                  src={extraImage}
                  className={classes.extraImage}
                  alt="account-logo"
                />
              </div>
            )}
            <Box className={clsx('ml-2')}>
              {extraTitleTooltip ? (
                <Tooltip placement="top" title={extraTitleTooltip}>
                  {renderExtraTitle()}
                </Tooltip>
              ) : (
                renderExtraTitle()
              )}
              {extraSubtitle && copyExtraSubtitleToClipboard && (
                <TypographyWithCopy
                  dataCy="header-extraSubtitle"
                  textClassName={classes.subtitle}
                  typographyVariant="overline"
                  text={extraSubtitle}
                  textToCopy={extraSubtitle}
                  textMaxWidth={300}
                  tooltipText={extraSubtitleTooltip}
                />
              )}
              {extraSubtitle && !copyExtraSubtitleToClipboard && (
                <Typography
                  data-cy="header-extraSubtitle"
                  className={clsx(classes.subtitle)}
                  variant="subtitle1"
                  noWrap
                >
                  {extraSubtitle}
                </Typography>
              )}
            </Box>
          </>
        )}
      </Box>
      <div className={classes.actionContainer}>{children}</div>
    </Grid>
  );
};

export default ContentHeader;
