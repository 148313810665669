import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { Divider, Grid, Typography } from '@mui/material';
import {
  // Device,
  // Devices,
  Execution,
  WorkflowExecution,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { workflowExecutionColorThemeMap } from '../../../app/constants';
import timeHelpers from '../../../helpers/timeHelpers';
import {
  getExecutionStatus,
  sortExecutionStatuses,
} from '../../../helpers/executionStatuses';
import { useUserWithType } from '../../../hooks/useUserWithType';
import UserAvatar from '../../../components/UserAvatar';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import ColoredBox from '../../../components/ColoredBox';
import useStyles from '../../../pages/commandContent/commandExecutionList/styles';

interface WorkflowExecutionInfoDrawerProps {
  open: boolean;
  workflowExecution: WorkflowExecution;
  handleCloseDrawer: () => void;
}

const WorkflowExecutionInfoDrawer: React.FC<
  WorkflowExecutionInfoDrawerProps
> = ({ open, workflowExecution, handleCloseDrawer }) => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const { user } = useUserWithType(workflowExecution.user_id);
  const [executionStatus, setExecutionStatus] = useState<string>();
  // const [executionDevice, setExecutionDevice] = useState<Device>();
  const [executionStatuses, setExecutionStatuses] = useState<Execution[]>([]);

  // const getWorkflowExecutionDevice = (): void => {
  //   if (workflowExecution) {
  //     Devices.getOneById(workflowExecution.device_id)
  //       .then((result) => {
  //         setExecutionDevice(result);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // };

  useEffect(() => {
    if (workflowExecution) {
      if (workflowExecution) {
        const statuses = sortExecutionStatuses(workflowExecution);
        setExecutionStatuses(statuses);
        setExecutionStatus(getExecutionStatus(statuses));
      }
      // getWorkflowExecutionDevice();
    }
  }, [workflowExecution]);

  // const goToDevice = (): void => {
  //   if (executionDevice) {
  //     navigate(`/device/${executionDevice._id}`);
  //   }
  // };

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      titleComponent={
        <>
          <Typography variant="h5" className="custom-label">
            {`Execution Id: ${workflowExecution?._id} `}
          </Typography>
          {executionStatus && (
            <ColoredBox
              colorTheme={workflowExecutionColorThemeMap[executionStatus]}
              type="command_execution_state"
              value={executionStatus}
            />
          )}
        </>
      }
      content={
        <Grid container spacing={2} className={classes.container}>
          {/* <Grid item xs={8} className={classes.deviceName}>
            {executionDevice ? (
              <Typography variant="button">
                Device:
                <Button
                  color="primary"
                  variant="text"
                  type="button"
                  onClick={goToDevice}
                >
                  {executionDevice.unique_id}
                </Button>
              </Typography>
            ) : (
              <Typography variant="body2" className="custom-label">
                {`Device: ${workflowExecution?.device_id}`}
              </Typography>
            )}
          </Grid> */}
          <Grid item xs={4}>
            <UserAvatar
              preAvatarText="Executed by:"
              preAvatarTextClass="custom-label"
              preAvatarTextVariant="body2"
              name={user ? `${user?.first_name} ${user?.last_name}` : ''}
            />
          </Grid>
          {executionStatuses &&
            executionStatuses.map((item) => (
              <div key={item._id} className="w-100">
                <Divider className="my-2" />
                <Grid
                  item
                  xs={12}
                  className={clsx('scrollbar', classes.executionInfoContainer)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="caption" color="white">
                        {timeHelpers.getDate(item.created_at, false, true)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ColoredBox
                        colorTheme={workflowExecutionColorThemeMap[item.status]}
                        type="command_execution_state"
                        value={item.status}
                      />
                    </Grid>
                    {item.status !== 'initiated' && (
                      <Grid item xs={12}>
                        <pre className={classes.executionInfo}>
                          {item?.message}
                        </pre>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))}
        </Grid>
      }
    />
  );
};

export default WorkflowExecutionInfoDrawer;
