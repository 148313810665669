import React, { PropsWithChildren, ReactElement } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import clsx from 'clsx';
import useStyles from './styles';

export interface HeaderProps {
  title?: string; // Main title of the header
  subtitle?: string; // Sub title of the header
  nextTitleContent?: ReactElement;
  goBack?: To;
  goBackLabel?: string;
  isCreatePage?: boolean;
}

const Header: React.FC<PropsWithChildren<HeaderProps>> = ({
  title,
  subtitle,
  nextTitleContent,
  goBack = '',
  goBackLabel,
  isCreatePage = false,
  children,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const handleGoBack = (): void => {
    if (goBack === 'last') {
      navigate(-1);
    } else {
      navigate(`/${goBack}`);
    }
  };

  return (
    <Grid
      data-testid="parent-grid-element"
      item
      xs={12}
      className={clsx('mw-100', {
        ['px-8 py-6 shadow']: !isCreatePage,
        [classes.container]: !isCreatePage,
        // [classes.headerPadding]: !isCreatePage,
      })}
    >
      {goBack && (
        <Button
          className={clsx('pl-0', classes.backButton)}
          color="inherit"
          variant="text"
          type="button"
          data-cy="button-back-to"
          startIcon={<KeyboardBackspaceIcon />}
          onClick={handleGoBack}
        >
          <Typography variant="button">
            {`Back to ${goBackLabel ?? goBack}`}
          </Typography>
        </Button>
      )}
      {!goBack && (
        <>
          <div className={classes.titlesWrapper}>
            <div data-cy={title} className={classes.titleContainer}>
              <Typography variant="h4">{title}</Typography>
              {nextTitleContent}
            </div>
            {subtitle && (
              <Typography variant="body2" className="mt-2">
                {subtitle}
              </Typography>
            )}
          </div>
          <div className={classes.actionContainer}>{children}</div>
        </>
      )}
    </Grid>
  );
};

export default Header;
