import React, { useState, ReactElement } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  DeviceType,
  DeviceTypes,
  NetworkConnection,
} from '@edgeiq/edgeiq-api-js';

import TabsPage from '../../../components/TabsPage';
import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  setNewDeviceType,
  setActualDeviceType,
} from '../../../redux/reducers/deviceTypes.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { connectionsLabel, errorHighlight } from '../../../app/constants';
import ConnectionsDrawer from '../../../containers/RightDrawer/AttachConnections/ConnectionsDrawer';

const columns: GridColDef[] = [
  {
    field: 'type',
    flex: 0.5,
    headerName: 'Connection Type',
    renderCell: (params: GridRenderCellParams): ReactElement => (
      <strong>{connectionsLabel[params.row.type]}</strong>
    ),
  },
  {
    field: 'name',
    flex: 0.5,
    headerName: 'Connection Name',
  },
];

const DeviceTypeConnections: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  const handleAddConnections = (connections: NetworkConnection[]): void => {
    setOpen(false);
    setLoading(true);

    if (editableDeviceType) {
      const updateDeviceType: DeviceType = {
        ...editableDeviceType,
        capabilities: {
          ...editableDeviceType?.capabilities,
          actions: {
            ...editableDeviceType.capabilities.actions,
            network_connectivity_monitoring: !!connections.length,
          },
          network_connections: connections,
        },
      };

      dispatch(setNewDeviceType(updateDeviceType as DeviceType));

      DeviceTypes.update(updateDeviceType as DeviceType)
        .then((response) => {
          dispatch(setActualDeviceType(response));
          dispatch(
            setAlert({
              highlight: 'Connections updated',
              message: 'Device type connections successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const toggleDrawer = (): void => {
    setOpen(!open);
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={editableDeviceType?.capabilities.network_connections}
        addButtonLabel="Manage Connections"
        addButtonIcon={false}
        onAddClick={toggleDrawer}
        tableTitle="Connections"
        loading={loading}
      />

      <ConnectionsDrawer
        open={open}
        connections={
          editableDeviceType?.capabilities
            .network_connections as NetworkConnection[]
        }
        handleCloseDrawer={toggleDrawer}
        onConnectionsAdd={handleAddConnections}
      />
    </Box>
  );
};

export default DeviceTypeConnections;
