import React, { useEffect, useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { SettingsApplicationRecord } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useUserWithType } from '../../../hooks/useUserWithType';
import { settingApplicationRecordThemeMap } from '../../../app/constants';
import timeHelpers from '../../../helpers/timeHelpers';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import CodeEditor from '../../../components/CodeEditor';
import ColoredBox from '../../../components/ColoredBox';
import UserAvatar from '../../../components/UserAvatar';
import useStyles from '../../../components/RightDrawer/styles';

interface SettingApplicationRecordDrawerProps {
  open: boolean;
  settingApplicationRecord: SettingsApplicationRecord;
  deviceLink?: boolean;
  configLink?: boolean;
  handleCloseDrawer: () => void;
  openOtherData: (
    settingApplication: SettingsApplicationRecord,
    type: string,
  ) => void;
}

const SettingApplicationRecordDrawer: React.FC<
  SettingApplicationRecordDrawerProps
> = ({
  open,
  settingApplicationRecord,
  deviceLink = false,
  configLink = false,
  handleCloseDrawer,
  openOtherData,
}) => {
  const classes = useStyles({});
  const [settingValues, setSettingValues] = useState('');
  const creationDate = timeHelpers.getDate(settingApplicationRecord.created_at);

  const { user } = useUserWithType(settingApplicationRecord.user_id, false);

  useEffect(() => {
    const appliedSettingsValues = settingApplicationRecord.setting.values;
    if (appliedSettingsValues) {
      try {
        setSettingValues(JSON.stringify(appliedSettingsValues, null, 2));
      } catch (error) {
        console.info(error);
      }
    } else {
      setSettingValues('');
    }
  }, [settingApplicationRecord]);

  const handleOpenData = (type: string) => (): void => {
    openOtherData(settingApplicationRecord, type);
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Close"
      title="Setting Application Record"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Grid
          container
          direction="column"
          spacing={2}
          className="position-relative"
        >
          {settingApplicationRecord.setting && (
            <Grid item xs={12}>
              <Typography variant="h6" className="mb-2">
                Settings:
              </Typography>
              <Tooltip placement="top-start" title="Open Setting">
                <Typography
                  data-cy={`open-application-setting-${settingApplicationRecord._id}`}
                  variant="button"
                  component="div"
                  noWrap
                  className={clsx('mb-1', classes.link)}
                  onClick={handleOpenData('settings')}
                >
                  {settingApplicationRecord.setting.name}
                  <Typography
                    variant="overline"
                    component="span"
                    className={clsx(classes.subtitle, 'fw-700 ml-2')}
                  >
                    {`V${settingApplicationRecord.version}`}
                  </Typography>
                </Typography>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" className="mb-2">
              Executed at:
            </Typography>
            <Typography variant="button">{creationDate}</Typography>
          </Grid>
          <Grid item xs={12}>
            <UserAvatar
              preAvatarText="Executed by:"
              preAvatarTextClass="mb-2"
              preAvatarTextVariant="h6"
              name={user ? `${user?.first_name} ${user?.last_name}` : ''}
            />
          </Grid>
          {settingApplicationRecord.configuration && (
            <Grid item xs={12}>
              <Typography variant="h6" className="mb-2">
                Configuration:
              </Typography>
              {configLink ? (
                <Tooltip placement="top-start" title="Open Configuration">
                  <Typography
                    data-cy={`open-application-configuration-${settingApplicationRecord._id}`}
                    variant="button"
                    component="div"
                    noWrap
                    className={clsx('mb-1', classes.link)}
                    onClick={handleOpenData('configuration')}
                  >
                    {settingApplicationRecord.configuration.name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="button">
                  {settingApplicationRecord.configuration.name}
                </Typography>
              )}
            </Grid>
          )}
          {settingApplicationRecord.device && (
            <Grid item xs={12}>
              <Typography variant="h6" className="mb-2">
                Device:
              </Typography>
              {deviceLink ? (
                <Tooltip placement="top-start" title="Open Device">
                  <Typography
                    data-cy={`open-application-device-${settingApplicationRecord._id}`}
                    variant="button"
                    component="div"
                    noWrap
                    className={clsx('mb-1', classes.link)}
                    onClick={handleOpenData('device')}
                  >
                    {settingApplicationRecord.device.name}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="button">
                  {settingApplicationRecord.device.name}
                </Typography>
              )}
            </Grid>
          )}
          {settingApplicationRecord.command && (
            <Grid item xs={12}>
              <Typography variant="h6" className="mb-2">
                Command:
              </Typography>
              <Tooltip placement="top-start" title="Open Command">
                <Typography
                  data-testid="tooltip-setting-drawer"
                  data-cy={`open-application-command-${settingApplicationRecord._id}`}
                  variant="button"
                  component="div"
                  noWrap
                  className={clsx('mb-1', classes.link)}
                  onClick={handleOpenData('command')}
                >
                  {settingApplicationRecord.command.name}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12} className="my-4">
            <Typography variant="h6" className="mb-2">
              Settings Values:
            </Typography>
            <Box>
              {settingValues !== '' ? (
                <CodeEditor
                  mode="json"
                  prop="json_schema"
                  height={200}
                  disabled={true}
                  value={settingValues}
                />
              ) : (
                <Typography variant="button">
                  The settings selected have no values
                </Typography>
              )}
            </Box>
          </Grid>
          <div className={classes.statusContainer}>
            <ColoredBox
              type="application_type"
              value={settingApplicationRecord.type}
              colorTheme={
                settingApplicationRecordThemeMap[settingApplicationRecord.type]
              }
            />
          </div>
        </Grid>
      }
    />
  );
};

export default SettingApplicationRecordDrawer;
