import React from 'react';
import { Grid } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../../components/TextInput';
import CodeEditor from '../../../../../components/CodeEditor';

interface SendTcpSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const SendTcpSection: React.FC<SendTcpSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <CodeEditor
          label="TCP Message"
          prop={`${actionIndex}.body_template`}
          onInputChange={onInputChange}
          value={action.body_template}
          disabled={disabled}
          height={400}
        />
      </Grid>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="TCP Server Address"
          placeholder=""
          prop={`${actionIndex}.send_to`}
          value={action.send_to}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default SendTcpSection;
