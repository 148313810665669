import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Command,
  CommandInput,
  Integration,
  Integrations,
  PaginationFilter,
  Workflow,
} from '@edgeiq/edgeiq-api-js';

import { setAlert } from '../../../redux/reducers/alert.reducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { errorHighlight } from '../../../app/constants';
import { httpSenderMethodTypes } from '../../../constants/commands';
import ToggleOptions from '../../../components/ToggleOptions';
import TextInput from '../../../components/TextInput';
import DynamicRows from '../../../components/DynamicRows';
import SelectInput from '../../../components/SelectInput';
import CodeEditor from '../../../components/CodeEditor';
import CheckboxInput from '../../../components/CheckboxInput';
import { prettifyCommandJSON } from './helper';
import useStyles from './styles';

// Only these sender types have specific values that we know we need in the sender object,
// therefore, when choosing one of them the user will have the option to choose between seeing the sender object as a JSON editor
// or as specific inputs for the values we know are needed for these types
const customSenderTypes = [
  'gcp_pubsub_sender',
  'http_sender',
  'shell_sender',
  'workflow_sender',
];

interface CommandSenderFormProps {
  newCommand: Command | CommandInput;
  workflowsOptionsList: Workflow[];
  invalid?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
  onDynamicChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow: (prop: string) => void;
  onRemoveRow: (prop: string, item: string) => void;
}

const CommandSenderForm: React.FC<CommandSenderFormProps> = ({
  newCommand,
  workflowsOptionsList,
  invalid,
  onInputChange,
  onDynamicChange,
  onAddRow,
  onRemoveRow,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const filters = useAppSelector((state: RootState) => state.filters);
  const [integrationList, setIntegrations] = useState<Integration[]>([]);
  const [chosenView, setChosenView] = useState('input');
  const [sender, setSender] = useState(prettifyCommandJSON(newCommand.sender));

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    setSender(prettifyCommandJSON(newCommand.sender));
  }, [chosenView, newCommand.sender_type]);

  useEffect(() => {
    getIntegrations(1);
  }, [filters.integrations]);

  const getIntegrations = (pageNumber: number): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 1000,
      page: pageNumber,
    };

    Integrations.list(
      {
        type: { operator: 'like', value: 'gcp' },
      },
      pagination,
    )
      .then((result) => {
        setIntegrations(result.integrations);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const handleDynamicChange =
    (prop: string, index?: string) =>
    (field: string, value: string | number): void => {
      onDynamicChange(prop, value, field, index ?? '');
    };

  const onSenderEditorChange = (prop: string, value: string | number): void => {
    setSender(value as string);
    onInputChange(prop, value);
  };

  const renderCodeEditor = (): JSX.Element => (
    <CodeEditor
      prop="sender"
      mode="json"
      height={250}
      value={sender}
      showInvalid={invalid}
      onInputChange={onSenderEditorChange}
    />
  );

  const renderTimeout = (): JSX.Element => (
    <Grid item xs={12} lg={6}>
      <TextInput
        prop="timeout"
        label="Timeout (seconds)"
        type="number"
        value={(newCommand?.sender?.timeout as number) ?? 5}
        onInputChange={handleDynamicChange('sender')}
      />
    </Grid>
  );

  const renderGCPSender = (): JSX.Element => (
    <>
      <Grid item xs={12} lg={6}>
        <TextInput
          prop="gcp_topic"
          label="GCP Topic"
          type="text"
          value={(newCommand?.sender?.gcp_topic as string) ?? ''}
          onInputChange={handleDynamicChange('sender')}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SelectInput
          label="Integration"
          prop="integration_id"
          value={(newCommand?.sender?.integration_id as string) ?? ''}
          onSelectChange={handleDynamicChange('sender')}
          options={integrationList.map((integration) => (
            <MenuItem
              className="m-4 p-2"
              key={integration._id}
              dense
              value={integration._id}
            >
              {integration.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" className="custom-label">
          Attributes
        </Typography>
        <DynamicRows
          prop="attributes"
          objectRowsTypes={
            (newCommand.sender?.attributes as { [key: string]: string }) ?? {}
          }
          itemKey="name"
          itemKeyPlaceHolder="Name"
          itemValue="value"
          itemValuePlaceHolder="Value"
          buttonLabel="Attributes"
          useOnChangeHandlerObjectIndex={true}
          topMargin={false}
          onInputChange={onDynamicChange}
          onAddRow={onAddRow}
          onRemoveRow={onRemoveRow}
        />
      </Grid>
    </>
  );

  const renderHTTPSender = (): JSX.Element => {
    const unix_socket_enabled = newCommand?.sender
      ?.unix_socket_enabled as boolean;
    return (
      <>
        <Grid item xs={12}>
          <TextInput
            prop="url"
            label="URL"
            type="text"
            value={(newCommand?.sender?.url as string) ?? ''}
            onInputChange={handleDynamicChange('sender')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="end"
          lg={unix_socket_enabled ? 5 : 12}
          xl={unix_socket_enabled ? 4 : 12}
        >
          <CheckboxInput
            label={
              <div className="d-flex ">
                <Typography variant="button" className="mr-2">
                  UNIX Socket Enabled
                </Typography>
                <Tooltip
                  placement="top-start"
                  title="Enable if the rest API is provided by a UNIX Socket locally"
                >
                  <HelpOutlineIcon
                    fontSize="small"
                    className={classes.infoIconGrey}
                  />
                </Tooltip>
              </div>
            }
            prop="unix_socket_enabled"
            value="unix_socket_enabled"
            checked={
              unix_socket_enabled ??
              typeof newCommand?.sender?.['unix-socket'] !== 'undefined'
            }
            onCheckboxClick={handleDynamicChange('sender')}
          />
        </Grid>
        {unix_socket_enabled && (
          <Grid item xs={12} lg={7} xl={8}>
            <TextInput
              prop="unix-socket"
              placeholder="UNIX Socket"
              type="text"
              value={(newCommand?.sender?.['unix-socket'] as string) ?? ''}
              onInputChange={handleDynamicChange('sender')}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={6}>
          <SelectInput
            label="HTTP Method"
            prop="method"
            required={true}
            value={(newCommand.sender?.method as string) ?? 'GET'}
            onSelectChange={handleDynamicChange('sender')}
            options={httpSenderMethodTypes.map((method, index) => (
              <MenuItem className="m-4 p-2" dense key={index} value={method}>
                {method}
              </MenuItem>
            ))}
          />
        </Grid>
        {renderTimeout()}
        <Grid item xs={12}>
          <Typography variant="body2" className="custom-label">
            Headers
          </Typography>
          <DynamicRows
            prop="headers"
            objectRowsTypes={
              (newCommand.sender?.headers as { [key: string]: string }) || {}
            }
            itemKey="key"
            itemKeyPlaceHolder="Key"
            itemValue="value"
            itemValuePlaceHolder="Value"
            buttonLabel="Headers"
            useOnChangeHandlerObjectIndex={true}
            topMargin={false}
            onInputChange={onDynamicChange}
            onAddRow={onAddRow}
            onRemoveRow={onRemoveRow}
          />
        </Grid>
      </>
    );
  };

  const renderShellSender = (): JSX.Element => (
    <>
      <Grid item xs={12}>
        <TextInput
          label="Command"
          prop="command"
          classes="full-height"
          helperText={`Shell command or script to be executed on the device. The script output can be captured if "Save Command Output" is enabled`}
          multiline
          rows={2}
          value={(newCommand?.sender?.command as string) ?? ''}
          onInputChange={handleDynamicChange('sender')}
        />
      </Grid>
      {renderTimeout()}
    </>
  );

  const renderWorkflowSender = (): JSX.Element => (
    <>
      <Grid item xs={12} lg={6}>
        <SelectInput
          label="Workflow"
          prop="workflow_id"
          required={true}
          value={(newCommand.sender?.workflow_id as string) ?? ''}
          onSelectChange={handleDynamicChange('sender')}
          options={workflowsOptionsList.map((workflow, index) => (
            <MenuItem
              className="m-4 p-2"
              key={index}
              dense
              value={workflow._id}
            >
              {workflow.name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <TextInput
          label="Unique Identifier (optional)"
          prop="unique_identifier"
          required={false}
          value={(newCommand.sender?.unique_identifier as string) ?? ''}
          onInputChange={handleDynamicChange('sender')}
        />
      </Grid>
    </>
  );

  return (
    <Grid container direction="row" spacing={3}>
      {!customSenderTypes.includes(newCommand.sender_type) ? (
        <Grid item xs={12}>
          {renderCodeEditor()}
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <ToggleOptions
              label="Sender"
              optionsKey="sender_toggle"
              inlineLabel={true}
              rightSideOptions={true}
              chosenView={chosenView}
              options={[
                { label: 'Inputs', value: 'input' },
                { label: 'JSON editor', value: 'json' },
              ]}
              onViewChange={setChosenView}
            />
          </Grid>
          {chosenView !== 'input' ? (
            <Grid item xs={12}>
              {renderCodeEditor()}
            </Grid>
          ) : (
            <>
              {newCommand.sender_type === 'gcp_pubsub_sender' &&
                renderGCPSender()}
              {newCommand.sender_type === 'http_sender' && renderHTTPSender()}
              {newCommand.sender_type === 'shell_sender' && renderShellSender()}
              {newCommand.sender_type === 'workflow_sender' &&
                renderWorkflowSender()}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default CommandSenderForm;
