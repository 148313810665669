import { CommandInput } from '@edgeiq/edgeiq-api-js';
import { ItemList } from '../components/DynamicRows/constants';

export const EMPTY_COMMAND: CommandInput = {
  company_id: '',
  device_id: '',
  long_description: '',
  name: '',
  options: {},
  payload: '',
  sender: {
    headers: {},
    method: 'GET',
    timeout: 5,
    'unix-socket': '',
    unix_socket_enabled: false,
    url: '',
  },
  sender_type: 'http_sender',
  translator_id: '',
};

export const commandsSenderTypes: {
  [key: string]: string;
} = {
  bacnet_sender: 'BACNET Sender',
  dev_sender: 'Dev Sender',
  gcp_pubsub_sender: 'GCP PubSub Sender',
  http_sender: 'HTTP/REST API Sender',
  modbus_sender: 'Modbus Sender',
  opcua_sender: 'OPCUA Sender',
  plugin_sender: 'Plugin Sender',
  shell_sender: 'Shell Sender',
  tcp_sender: 'TCP Sender',
  workflow_sender: 'Workflow Sender',
};

export const httpSenderMethodTypes = [
  'GET',
  'POST',
  'PUT',
  'DELETE',
  'PATCH',
  'HEAD',
  'OPTIONS',
];

export const optionDataTypes: ItemList[] = [
  {
    key: 'integer',
    label: 'Integer',
  },
  {
    key: 'string',
    label: 'String',
  },
];
