import { useState } from 'react';
import { MenuItem } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setFilters } from '../../../redux/reducers/filters.reducer';
import Filters from '../../../containers/Filters';
import AccountFilter from '../../../containers/AccountSelect/AccountFilter';
import SelectInput from '../../../components/SelectInput';
import {
  BULK_RESPONSE_STATUS,
  BULK_RESPONSE_STATUS_KEY,
  BULK_RESPONSE_TYPES,
} from '../../../app/constants';
import { actions } from '../../../containers/RightDrawer/IssueCommand/constants';

interface BulkJobsFiltersProps {
  total: number;
}

const BulkJobsFilters: React.FC<BulkJobsFiltersProps> = ({ total }) => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.bulkJobs.filters;

  const [showGatewayTypes, setShowGatewayTypes] = useState(false);

  const changeFilters = (prop: string, value: string): void => {
    if (prop === 'type' && value === 'gateway_command') {
      setShowGatewayTypes(true);
    } else {
      dispatch(
        setFilters(
          {
            ...filters,
            [prop === 'gateway_command_type' ? 'type' : prop]: value,
          },
          'bulkJobs',
        ),
      );
    }
  };

  return (
    <Filters
      hasSearchInput={false}
      total={total}
      filterModel="bulkJobs"
      firstFilter={
        <AccountFilter
          accountsSelected={filters?.company_id}
          updateFilters={changeFilters}
        />
      }
      secondFilter={
        <SelectInput
          placeholder="Status"
          prop={BULK_RESPONSE_STATUS_KEY}
          value={filters?.[BULK_RESPONSE_STATUS_KEY]}
          onSelectChange={changeFilters}
          options={Object.keys(BULK_RESPONSE_STATUS).map((status) => (
            <MenuItem className="m-4 p-2" key={status} dense value={status}>
              {BULK_RESPONSE_STATUS[status]}
            </MenuItem>
          ))}
        />
      }
      thirdFilter={
        <SelectInput
          placeholder="Bulk Job Type"
          prop="type"
          value={filters?.type}
          onSelectChange={changeFilters}
          options={Object.keys(BULK_RESPONSE_TYPES).map((type) => (
            <MenuItem className="m-4 p-2" key={type} dense value={type}>
              {BULK_RESPONSE_TYPES[type]}
            </MenuItem>
          ))}
        />
      }
      fourthFilter={
        !showGatewayTypes ? (
          <></>
        ) : (
          <SelectInput
            placeholder="Gateway Command Type"
            prop="gateway_command_type"
            value={filters?.type}
            onSelectChange={changeFilters}
            options={actions.map((type) => (
              <MenuItem
                className="m-4 p-2"
                key={type.action}
                dense
                value={type.action}
              >
                {type.label}
              </MenuItem>
            ))}
          />
        )
      }
    />
  );
};

export default BulkJobsFilters;
