import React, { useState } from 'react';
import { Authentication } from '@edgeiq/edgeiq-api-js';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  OutlinedInput,
  FormControl,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import PasswordStrengthBar from 'react-password-strength-bar';

import { Alert as ToastAlert } from '../../components/ToastAlert/ToastAlert';
import { errorHighlight } from '../../app/constants';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { useAppDispatch } from '../../redux/hooks';
import useStyles from '../login/styles';

function useQuery(): URLSearchParams {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [values, setValues] = useState({
    password: '',
    passwordConfirm: '',
    showPassword: false,
    showPasswordConfirm: false,
  });

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const navigate = useNavigate();
  const query = useQuery();
  const token = query.get('token');

  const handleChange =
    (prop: string) =>
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = (): void => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowPasswordConfirm = (): void => {
    setValues({
      ...values,
      showPasswordConfirm: !values.showPasswordConfirm,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  const onSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    setSubmitting(true);
    setError('');
    if (!token) {
      setError('Invalid token');
    } else {
      Authentication.passwordReset(
        token,
        values.password,
        values.passwordConfirm,
      )
        .then(() => {
          setSuccessOpen(true);
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        })
        .catch((e) => {
          const message = e.error || e.message || e.toString() || e || 'error';
          setError(message);
          setSubmitting(false);
          dispatchError(message);
        });
    }
  };

  return (
    <Grid container direction="column">
      <Snackbar
        open={successOpen}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Password updated succesfully
        </Alert>
      </Snackbar>
      {error && (
        <Grid item xs={12} className="mb-7">
          <ToastAlert type="error" message={error} highlight="Error" />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="subtitle1" className={classes.title}>
          Reset password
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <form onSubmit={onSubmit} className={classes.formContainer}>
          <Typography variant="body2" className="custom-label">
            Password
          </Typography>
          <FormControl variant="outlined" className="mb-7">
            <OutlinedInput
              name="password"
              placeholder="New Password"
              disabled={submitting}
              type={values.showPassword ? 'text' : 'password'}
              required={true}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {values.password && (
              <PasswordStrengthBar password={values.password} />
            )}
          </FormControl>
          <Typography variant="body2" className="custom-label">
            Repeat Password
          </Typography>
          <FormControl variant="outlined" className="mb-7">
            <OutlinedInput
              name="passwordConfirm"
              disabled={submitting}
              placeholder="Confirm new password"
              type={values.showPasswordConfirm ? 'text' : 'password'}
              required={true}
              value={values.passwordConfirm}
              onChange={handleChange('passwordConfirm')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordConfirm}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPasswordConfirm ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            variant="contained"
            type="submit"
            disabled={submitting}
          >
            {!submitting ? (
              <Typography variant="button">Change Password</Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
