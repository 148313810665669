import React from 'react';
import { Grid, MenuItem, Typography, Divider } from '@mui/material';
import { RuleInput } from '@edgeiq/edgeiq-api-js';

import SelectInput from '../../../../components/SelectInput';
import { POLICY_CONDITION_RULE_LABEL } from '../../../../constants/policies';

interface PolicyConditionRuleSectionProps {
  newPolicy: RuleInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  disabled?: boolean;
}

const PolicyConditionRuleSection: React.FC<PolicyConditionRuleSectionProps> = ({
  newPolicy,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body2">
          Every time a report is generated, if
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SelectInput
          prop="type"
          value={newPolicy.rule_condition.type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(POLICY_CONDITION_RULE_LABEL).map(
            (key, index) => (
              <MenuItem className="m-4 p-2" key={index} dense value={key}>
                {POLICY_CONDITION_RULE_LABEL[key]}
              </MenuItem>
            ),
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">
          of the following conditions are true, the next section actions will be
          executed.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );
};

export default PolicyConditionRuleSection;
