import React, { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  InfoOutlined as InfoIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';
import { DeviceType, ImportantMetadata } from '@edgeiq/edgeiq-api-js';
import { set, cloneDeep } from 'lodash';
import clsx from 'clsx';

import SortableList from '../../../../components/SortableList';
import TextInput from '../../../../components/TextInput';
import useStyles from './styles';

interface DeviceTypeImportantMetadataProps {
  deviceType: DeviceType;
  updateImportantMetadata: (metadata: ImportantMetadata[]) => void;
}

interface MetadataSortableItem {
  id: number;
  metadata: ImportantMetadata;
}

const DeviceTypeImportantMetadata: React.FC<
  DeviceTypeImportantMetadataProps
> = ({ deviceType, updateImportantMetadata }) => {
  const classes = useStyles();
  const [items, setItems] = useState<MetadataSortableItem[]>([]);

  useEffect(() => {
    if (deviceType.important_metadata) {
      setItems(
        deviceType.important_metadata.map((metadata, index) => {
          return { id: index + 1, metadata };
        }),
      );
    }
  }, []);

  const mapSortableItemsToMetadata = (
    newItems: MetadataSortableItem[],
  ): void => {
    updateImportantMetadata(
      newItems.map((item) => {
        return { label: item.metadata.label, value: item.metadata.value };
      }),
    );
  };

  const onImportantMetadataChange = (
    prop: string,
    value: string | number,
  ): void => {
    // prop in this case will always be 'value.<index>' or 'label.<index>'
    const propsArray = prop.split('.');
    // Creating an object this way as it is necessary for the lodash set function to work properly
    const metadataObject = {
      important_metadata: [...items],
    };
    // Because of the reordering logic, the id does not change no matter how many times the list is reordered.
    // We could update the ids each time the list is reordered but the visual behaviour seems buggy even though it does reorder perfectly.
    // So, for a better visual experience that's the best approach. Use the item.id set in the prop of each input to update the right item.
    const itemId = propsArray[1];
    const itemIndex = items.map((item) => item.id).indexOf(parseInt(itemId));
    set(
      metadataObject,
      `important_metadata.${itemIndex}.metadata.${propsArray[0]}`,
      value,
    );
    updateImportantMetadata(
      metadataObject.important_metadata.map((item) => {
        return { label: item.metadata.label, value: item.metadata.value };
      }),
    );
    setItems(metadataObject.important_metadata);
  };

  const handleRemoveImportantMetadata = (index: number) => (): void => {
    const updatedMetadata = cloneDeep(items);
    updatedMetadata.splice(index, 1);
    setItems(updatedMetadata);
  };

  const addImportantMetadata = (): void => {
    const newItems = [
      ...items,
      {
        id: items.length + 1,
        metadata: {
          label: '',
          value: '',
        },
      },
    ];
    mapSortableItemsToMetadata(newItems);
    setItems(newItems);
  };

  const reorderMetadata = (
    reorderedItems: { id: number; metadata: ImportantMetadata }[],
  ): void => {
    updateImportantMetadata(
      reorderedItems.map((item) => {
        return { label: item.metadata.label, value: item.metadata.value };
      }),
    );
    setItems(reorderedItems);
  };

  return (
    <>
      <Typography
        variant="h6"
        className="mt-6 mb-3"
        data-cy="important-data-title"
      >
        Important Metadata
      </Typography>
      <Typography variant="body2" className="custom-label">
        Add a list of device metadata to display more prominently on the detail
        view of devices assigned this profile:
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.info)}
      >
        <InfoIcon fontSize="small" className={clsx('mr-1', classes.infoIcon)} />
        Click and drag this icon
        <DragIndicatorIcon
          fontSize="small"
          className={clsx('mr-1', classes.infoIcon)}
        />
        to reorder the metadata on the device detail view.
      </Typography>
      <SortableList
        items={items}
        onChange={reorderMetadata}
        renderItem={(item) => (
          <SortableList.Item id={item.id}>
            <SortableList.DragHandle />
            {/* {item.label} */}
            <Grid
              container
              direction="row"
              spacing={2}
              className="ml-2"
              alignItems="center"
            >
              <Grid item xs={10} lg={5} className="pl-0">
                <TextInput
                  label={`Key`}
                  placeholder="Key"
                  prop={`value.${item.id}`}
                  value={item.metadata.value}
                  onInputChange={onImportantMetadataChange}
                />
              </Grid>

              <Grid item xs={10} lg={5} className={classes.labelContainer}>
                <TextInput
                  label="Label"
                  placeholder="Label"
                  prop={`label.${item.id}`}
                  value={item.metadata.label}
                  onInputChange={onImportantMetadataChange}
                />
              </Grid>
              <Grid
                item
                xs={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                className="mt-6"
              >
                <IconButton onClick={handleRemoveImportantMetadata(1)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          </SortableList.Item>
        )}
      />
      <Button
        variant="outlined"
        size="medium"
        className="mt-4"
        onClick={addImportantMetadata}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </>
  );
};

export default DeviceTypeImportantMetadata;
