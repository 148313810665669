import React, { useEffect } from 'react';
import {
  CircularProgress,
  MenuItem,
  Typography,
  TextField,
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { Company } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { EMPTY_COMPANY } from '../../constants/companies';
import useStyles from './styles';

interface AccountAutocompleteProps {
  allowAdd?: boolean;
  isDisabled?: boolean;
  includeParentId?: boolean;
  placeHolder?: string;
  selectedAccount?: string;
  options: Company[];
  dataCy?: string;
  includeInfo?: boolean;
  handleChangeAccount: (value: string) => void;
  getAccountModel: (accountId: string) => Company | undefined;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}

const AccountAutocomplete: React.FC<AccountAutocompleteProps> = ({
  allowAdd = false,
  isDisabled,
  includeParentId = false,
  placeHolder,
  selectedAccount,
  options,
  dataCy,
  includeInfo,
  handleChangeAccount,
  getAccountModel,
  setSearchValue,
  loading,
}) => {
  const classes = useStyles();
  // Track input field value separately from selected value to handle both search and selection states
  const [inputValue, setInputValue] = React.useState('');

  // Sync input value with selectedAccount when it changes externally
  useEffect(() => {
    // In the onChange below the input value already is set to be the name of the chosen account,
    // and in the same logic the accountChange is triggered, which by default will trigger this useEffect
    // So to make sure the inputValue shows the name of the chosen account and not the ID.
    // we only update the inputValue if it's not the name of the chosen account.
    // The chosen account is the one clicked from the dropdown.
    const company = getAccountModel(selectedAccount || '');
    if (company && company.name !== inputValue) {
      setInputValue(selectedAccount || '');
    }
  }, [selectedAccount]);

  const renderOptionsAutoComplete = (
    props: React.HTMLAttributes<HTMLLIElement>,
    company: Company,
  ): React.ReactElement => {
    return (
      <MenuItem {...props} key={company._id} value={company._id}>
        {company.branding?.logo_url ? (
          <div className={clsx('mr-2', classes.logo)}>
            <img
              src={company.branding?.logo_url}
              className={classes.logoImage}
            />
          </div>
        ) : (
          <div className={clsx('mr-2', classes.logo, classes.nameLogo)}>
            <Typography variant="h5" noWrap color="white">
              {company.name.charAt(0)}
              {company.name.charAt(1)}
            </Typography>
          </div>
        )}
        <div>
          <Typography variant="button" component="div">
            {company.name || company._id}
          </Typography>
          <Typography variant="caption" component="div">
            ID: {company._id}
            {includeParentId ? ` - Parent ID: ${company.company_id}` : ''}
          </Typography>
        </div>
      </MenuItem>
    );
  };

  return (
    <>
      {includeInfo && (
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.info)}
        >
          <InfoIcon
            fontSize="small"
            className={clsx('mr-1', classes.infoIcon)}
          />
          Type to search for accounts by name.
        </Typography>
      )}
      <Autocomplete
        id="account-autocomplete"
        placeholder={placeHolder}
        disabled={isDisabled}
        // Use null instead of empty string for empty state to avoid MUI warnings
        value={selectedAccount || null}
        selectOnFocus
        handleHomeEndKeys
        // Only allow free text input when allowAdd is true
        freeSolo={allowAdd}
        fullWidth
        data-cy={dataCy}
        options={options}
        renderOption={renderOptionsAutoComplete}
        // Update both local input state and parent's search value when typing
        onInputChange={(_event, newInputValue, reason): void => {
          if (reason === 'input') {
            setInputValue(newInputValue);
            setSearchValue(newInputValue);
          }
          if (reason === 'clear') {
            setSearchValue('');
          }
        }}
        noOptionsText={
          <MenuItem key="_no-options">
            <Typography variant="button" component="div">
              No accounts found
            </Typography>
          </MenuItem>
        }
        renderInput={(params): React.ReactNode => (
          <TextField
            {...params}
            placeholder={placeHolder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        // Handle both Company object selection and custom string input
        onChange={(_event, newValue): void => {
          if (!newValue) {
            setInputValue('');
            handleChangeAccount('');
            return;
          }

          if (typeof newValue === 'string') {
            if (allowAdd) {
              setInputValue(`Use company_id "${newValue}"`);
            } else {
              const company = getAccountModel(newValue);
              setInputValue(company?.name || newValue);
              handleChangeAccount(newValue);
              setSearchValue(newValue);
            }
          } else {
            handleChangeAccount(newValue._id);
            setInputValue(newValue.name);
            setSearchValue(newValue._id);
          }
        }}
        // Custom filter to search by both company name and ID
        filterOptions={(availableOptions, state) => {
          const filtered = availableOptions.filter((option) => {
            const searchStr = state.inputValue.toLowerCase();

            // Handle Company options
            const company = option as Company;
            return (
              (company.name || '').toLowerCase().includes(searchStr) ||
              (company._id || '').toLowerCase().includes(searchStr)
            );
          });

          // Add option to use custom input as company_id when allowAdd is true
          if (allowAdd && state.inputValue !== '') {
            const isExisting = filtered.some((option) => {
              const company = option as Company;
              return (
                state.inputValue === (company.name || '') ||
                state.inputValue === company._id
              );
            });

            if (!isExisting) {
              filtered.push({
                ...EMPTY_COMPANY,
                _id: state.inputValue,
                created_at: '',
                name: `Use company_id "${state.inputValue}"`,
                origin: '',
                updated_at: '',
                user_id: '',
              });
            }
          }

          return filtered;
        }}
        // Compare options with current value, handling both string IDs and Company objects
        isOptionEqualToValue={(option: Company, value: string | Company) => {
          if (!value) {
            return false;
          }

          if (typeof value === 'string') {
            return option._id === value;
          }
          const valueCompany = value as Company;
          return option._id === valueCompany._id;
        }}
        // Get display text for options, handling both string IDs and Company objects
        getOptionLabel={(option: string | Company): string => {
          if (typeof option === 'string') {
            const value = getAccountModel(option);
            if (value) {
              return value.name;
            }
            return option;
          }
          return (option as Company).name || (option as Company)._id;
        }}
        // Control input field value independently from selected value
        inputValue={inputValue}
      />
    </>
  );
};

export default AccountAutocomplete;
