import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import clsx from 'clsx';

import { commandExecutionColorThemeMap } from '../../app/constants';
import ColoredBox from '../ColoredBox';
import { convertCommandStatus } from '../../helpers/utils';
import DetailsDrawer from '../DetailsDrawer';
import timeHelpers from '../../helpers/timeHelpers';
import useStyles from './styles';
import { ChildExecution } from '../../models/commandExecution';

export interface CommandExecutionDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  title: string;
  subtitle1?: string;
  subtitle1Label?: string;
  subtitle2?: string;
  subtitle2Label?: string;
  executionDate: string | undefined;
  executionStatus: string | undefined;
  executedBy: string;
  childExecutions: ChildExecution[];
}
const CommandExecutionDrawer: React.FC<CommandExecutionDrawerProps> = ({
  open,
  handleCloseDrawer,
  title,
  subtitle1,
  subtitle1Label,
  subtitle2,
  subtitle2Label,
  childExecutions,
  executedBy,
  executionDate,
  executionStatus,
}) => {
  const classes = useStyles();

  return (
    <DetailsDrawer
      open={open}
      handleCloseDrawer={handleCloseDrawer}
      title={title}
      subtitle1={subtitle1}
      subtitle1Label={subtitle1Label}
      subtitle2={subtitle2}
      subtitle2Label={subtitle2Label}
      date={executionDate ? timeHelpers.getDate(executionDate) : '--'}
      badgeColor={
        executionStatus
          ? commandExecutionColorThemeMap[convertCommandStatus(executionStatus)]
          : ''
      }
      badgeText={executionStatus ? executionStatus : ''}
      userName={executedBy}
    >
      <>
        {childExecutions.map((item, i) => (
          <div key={i} className="w-100">
            <Divider className="my-2" />
            <Grid
              item
              xs={12}
              className={clsx('scrollbar', classes.executionInfoContainer)}
            >
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="caption" color="white">
                    {item.deviceId}
                  </Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                  <ColoredBox
                    value={item.status}
                    colorTheme={
                      commandExecutionColorThemeMap[
                        convertCommandStatus(item.status)
                      ]
                    }
                    type="command_execution_state"
                  />
                </Grid>
                {item.message && (
                  <Grid item xs={12}>
                    <pre className={classes.executionInfo}>{item.message}</pre>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </div>
        ))}
      </>
    </DetailsDrawer>
  );
};

export default CommandExecutionDrawer;
