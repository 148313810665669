import React, { useEffect, useState } from 'react';
import { Typography, FormControl, MenuItem, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { DeviceConfig, DeviceConfigs, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../../redux/hooks';
import { setAlert } from '../../../../redux/reducers/alert.reducer';
import {
  errorHighlight,
  GATEWAY_TYPE,
  optionsPaginationsFilter,
} from '../../../../app/constants';
import useStyles from './styles';
import SelectInput from '../../../../components/SelectInput';

interface DeviceNetworkConfigProps {
  deviceNetworkConfigId: string;
  deviceType?: DeviceType | null;
  onSelectChange: (prop: string, value: string) => void;
}
const DeviceNetworkConfig: React.FC<DeviceNetworkConfigProps> = ({
  deviceNetworkConfigId,
  deviceType,
  onSelectChange,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // TODO: add network configs into redux

  const [networkConfigs, setNetworkConfigs] = useState<DeviceConfig[]>([]);

  useEffect(() => {
    DeviceConfigs.list({}, optionsPaginationsFilter)
      .then((result) => {
        setNetworkConfigs(result.deviceConfigs);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      });
  }, []);

  const isDisabled = !deviceType || deviceType.type !== GATEWAY_TYPE;

  return (
    <Box>
      <Typography className="mt-3 mb-3" variant="h5">
        Network configuration
      </Typography>
      {isDisabled ? (
        <Typography variant="body2" className="custom-label">
          {!deviceType &&
            'Select a device type to see the available network configs.'}
          {deviceType &&
            `There are no network configs for this device profile: ${deviceType?.name}.`}
        </Typography>
      ) : (
        <Grid item xs={6}>
          <Typography variant="body2" className="custom-label">
            Network Configuration
          </Typography>
          <FormControl variant="outlined" fullWidth>
            <SelectInput
              value={deviceNetworkConfigId ?? ''}
              onSelectChange={onSelectChange}
              prop="device_config_id"
              classes={clsx('mb-4', classes.filter)}
              options={[
                <MenuItem key="network_config" dense value="">
                  Network Configuration
                </MenuItem>,
                ...networkConfigs.map((config, index) => (
                  <MenuItem dense key={index} value={config._id}>
                    {config.name}
                  </MenuItem>
                )),
              ]}
            />
          </FormControl>
        </Grid>
      )}
    </Box>
  );
};

export default DeviceNetworkConfig;
