import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, CircularProgress, Box } from '@mui/material';
import {
  DeviceError,
  DeviceErrors,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { setAlert } from '../../../../../redux/reducers/alert.reducer';
import { RootState } from '../../../../../redux/store';
import {
  activityColorThemeMap,
  defaultItemsPerPage,
  errorHighlight,
} from '../../../../../app/constants';
import timeHelpers from '../../../../../helpers/timeHelpers';
import TimelineCard from '../../../../../components/TimelineCard';
import RightDrawer from '../../../../../components/RightDrawer/RightDrawer';
import CodeEditor from '../../../../../components/CodeEditor';

const DeviceErrorsActivity: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [errors, setErrors] = useState<DeviceError[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedError, setSelectedError] = useState<DeviceError>();
  const [infoDrawer, setInfoDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOpenDrawer = (r: DeviceError | undefined = undefined): void => {
    setInfoDrawer(!infoDrawer);
    if (r) {
      setSelectedError(r);
    }
  };

  useEffect(() => {
    setLoading(true);
    getReports(1, false);
  }, []);

  const getReports = (pageNumber: number, addPage: boolean): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      page: pageNumber,
    };

    DeviceErrors.list(
      {
        device_id: {
          key: 'device_id',
          operator: 'eq',
          value: editableDevice?._id as string,
        },
      },
      pagination,
    )
      .then((res) => {
        const newReports = addPage
          ? [...errors, ...res.resources]
          : res.resources;

        setErrors(newReports);
        setTotalAndPage(res.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };
  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getReports(page + 1, true);
  };

  const dispatchError = (errorToDispatch: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorToDispatch,
        type: 'error',
      }),
    );
  };

  const openActivityDrawer = (activity: DeviceError) => (): void => {
    handleOpenDrawer(activity);
    if (activity.error) {
      let prettifiedMessage = activity.error;
      try {
        // If the error message is a full JSON it will be prettified without a problem
        const parsedMessage = JSON.parse(activity.error);
        prettifiedMessage = JSON.stringify(parsedMessage, null, 2);
      } catch (error) {
        // If not we can check if it has a part where it IS a JSON and show it as such
        console.info(error);
        try {
          const jsonPart = prettifiedMessage.substring(
            prettifiedMessage.indexOf('{'),
            prettifiedMessage.lastIndexOf('}') + 1,
          );
          const nonJsonPart = prettifiedMessage.substring(
            0,
            prettifiedMessage.indexOf('{'),
          );
          const parsedMessage = JSON.parse(jsonPart);
          prettifiedMessage = `${nonJsonPart}\n${JSON.stringify(
            parsedMessage,
            null,
            2,
          )}`;
        } catch (err) {
          console.info(err);
        }
      }
      setErrorMessage(prettifiedMessage);
    }
  };

  return (
    <>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {errors.map((activity, i) => (
            <TimelineCard
              key={i}
              extraLabel="View Details"
              titleWithToolTip={true}
              title={activity.error}
              tag={activity.type}
              type={activity.type}
              tagTheme={activityColorThemeMap}
              isLast={i === errors.length - 1}
              caption={timeHelpers.getDate(activity.created_at, false, true)}
              extraCallback={openActivityDrawer(activity)}
            />
          ))}
          {errors.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <RightDrawer
        open={infoDrawer}
        actionLabel="Done"
        title={'Error Details'}
        disableAction={false}
        actionCallback={handleOpenDrawer}
        onCloseDrawer={handleOpenDrawer}
        content={
          <Box>
            <div>
              <Typography variant="h5" className="custom-label">
                Error
              </Typography>
              {errorMessage ? (
                <CodeEditor
                  mode="json"
                  prop="json_schema"
                  height={350}
                  disabled={true}
                  value={errorMessage}
                />
              ) : (
                <Typography variant="body2" className="custom-label">
                  No error message to display.
                </Typography>
              )}
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Level
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedError?.level}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Date
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedError?.created_at
                  ? timeHelpers.getDate(selectedError.created_at, false, true)
                  : '--'}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Rule Action Count
              </Typography>
              <Typography variant="body2" className="custom-label">
                {selectedError?.rule_action_count}
              </Typography>
            </div>
          </Box>
        }
      />
    </>
  );
};

export default DeviceErrorsActivity;
