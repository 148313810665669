import React, { useEffect } from 'react';
import { Typography, Link, IconButton, Tooltip } from '@mui/material';
import {
  InsertDriveFile as InsertDriveFileIcon,
  FileDownloadDone as FileDownloadDoneIcon,
  FileDownload as FileDownloadIcon,
  CancelOutlined as CancelIcon,
} from '@mui/icons-material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import clsx from 'clsx';

import useStyles from './styles';
import { EIQFile } from '@edgeiq/edgeiq-api-js';
import { downloadEIQFile } from '../../helpers/utils';

export interface DropZoneProps {
  stateFiles: File[] | EIQFile[];
  title?: string;
  acceptTypes?: string[];
  maxFiles?: number;
  specialLink?: string; // A special link to add under to "Drag and Drop" text
  specialLinkCallback?: () => void;
  onFileUpload: (file: File) => void;
  onRemoveFile: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const DropZone: React.FC<DropZoneProps> = ({
  stateFiles,
  title,
  acceptTypes = [],
  maxFiles,
  specialLink,
  specialLinkCallback,
  onFileUpload,
  onRemoveFile,
}) => {
  const classes = useStyles();
  const options: DropzoneOptions = {
    noClick: true,
    noKeyboard: true,
  };
  if (acceptTypes.length) {
    options.accept = acceptTypes;
  }
  if (maxFiles) {
    options.maxFiles = maxFiles;
  }
  const { acceptedFiles, getRootProps, getInputProps, open } =
    useDropzone(options);

  useEffect(() => {
    acceptedFiles.forEach((element) => {
      onFileUpload(element);
    });
  }, [acceptedFiles]);

  const onDownloadFile = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const _targetFileName = e.currentTarget.id;
    const link = (stateFiles[0] as EIQFile).link;
    const linkParts = link?.split('/');
    if (linkParts) {
      const fileId = linkParts[linkParts?.length - 2];
      downloadEIQFile({
        ...(stateFiles[0] as EIQFile),
        id: fileId,
      });
    }
  };

  return (
    <>
      {title && <Typography variant="body2">{title}</Typography>}
      <div>
        <section className="container">
          <div {...getRootProps()} className={classes.dragContainer}>
            <input data-testid="dropzone-input" {...getInputProps()} />
            <div>
              <InsertDriveFileIcon color="primary" />
            </div>
            <Typography variant="button" component="div">
              Drag & Drop or{' '}
              <Link
                className={clsx('ml-1', classes.link)}
                onClick={open}
                underline="none"
              >
                Upload from browser
              </Link>
            </Typography>
            {specialLink && (
              <Typography variant="button" component="div">
                <Link
                  className={clsx('ml-1', classes.link)}
                  onClick={specialLinkCallback}
                  underline="none"
                >
                  {specialLink}
                </Link>
              </Typography>
            )}
          </div>
          <div>
            {!!stateFiles.length && (
              <Typography variant="button">Uploaded file</Typography>
            )}
            {stateFiles.map((file, index) => (
              <div
                data-testid={`file-container-${index}`}
                key={`upload-file-${index}`}
                className={clsx('mt-4 p-4 br-1', classes.fileContainer)}
              >
                <div className={classes.fileName}>
                  <FileDownloadDoneIcon className="mr-2" />
                  {file.name}
                </div>
                <div>
                  <Tooltip placement="top" title="Download file">
                    <IconButton
                      id={file.name}
                      aria-label="download-file"
                      onClick={onDownloadFile}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip placement="top" title="Remove file">
                    <IconButton
                      id={file.name}
                      aria-label="delete-file"
                      onClick={onRemoveFile}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default DropZone;
