import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Command,
  Commands,
  CommandsFilters,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  errorHighlight,
  warningHighlight,
} from '../../../app/constants';
import { AdaptedCommand } from '../../../models/command';
import CardsGrid from '../../../components/CardsGrid';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import CommandCard from '../../../pages/commands/CommandCard';

interface AttachCommandsDrawerProps {
  open: boolean;
  itemCommands: boolean;
  isDeviceCommands: boolean;
  singleSelect?: boolean;
  preSelectedCommands?: Command[];
  handleCloseDrawer: () => void;
  handleChooseCommands: (commands: Command[]) => void;
}

const AttachCommandsDrawer: React.FC<AttachCommandsDrawerProps> = ({
  open,
  itemCommands,
  isDeviceCommands,
  singleSelect,
  preSelectedCommands,
  handleCloseDrawer,
  handleChooseCommands,
}) => {
  const dispatch = useAppDispatch();
  const stateCommands = useAppSelector((state: RootState) => state.commands);

  const [commands, setCommands] = useState<Command[]>([]);
  const [selectedCommands, setSelectedCommands] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [disableAction, setDisableAction] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const getCommands = (
    pageNumber: number,
    searchName?: string,
    addPage = false,
  ): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: '-created_at',
      page: pageNumber,
    };
    const filters: CommandsFilters = {};
    if (searchName) {
      filters.name = {
        operator: 'ilike',
        value: searchName,
      };
    }

    Commands.list(filters, pagination)
      .then((result) => {
        const newCommands = addPage
          ? [...commands, ...result.commands]
          : result.commands;
        setCommands(newCommands);
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getCommands(1);
  }, []);

  useEffect(() => {
    if (itemCommands && stateCommands.selectedCommands.length !== 0) {
      setSelectedCommands(
        stateCommands.selectedCommands.map((command) => command._id),
      );
    } else if (preSelectedCommands && !!preSelectedCommands?.length) {
      setSelectedCommands(preSelectedCommands.map((command) => command._id));
    } else {
      setSelectedCommands([]);
    }
  }, [stateCommands.selectedCommands, preSelectedCommands, open]);

  useEffect(() => {
    setDisableAction(
      isEqual(
        preSelectedCommands?.map((item) => item._id),
        selectedCommands,
      ),
    );
  }, [selectedCommands]);

  const handleOnChangeCallback = (value: string): void => {
    setSearchValue(value);
    getCommands(1, value);
  };

  const attachedInDbButNotLocally = (): AdaptedCommand[] => {
    const result = stateCommands.selectedCommands.filter(
      ({ _id }) => !selectedCommands.some((x) => x === _id),
    );
    return result;
  };

  const attachedInDbAndLocally = (): AdaptedCommand[] => {
    const result = stateCommands.selectedCommands.filter(({ _id }) =>
      selectedCommands.some((x) => x === _id),
    );
    return result;
  };

  const checkCommandCallback =
    (commandId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (!singleSelect) {
        if (isDeviceCommands) {
          const fromDeviceType = stateCommands.originalSelectedCommands.find(
            (originalCommand) =>
              originalCommand._id === commandId &&
              originalCommand.is_from_device_type,
          );

          if (fromDeviceType) {
            return dispatch(
              setAlert({
                highlight: warningHighlight,
                message:
                  "You can't remove commands that belongs to the Device Profile.",
                type: 'warning',
              }),
            );
          }
        }

        if (checked) {
          setSelectedCommands([...selectedCommands, commandId]);
        } else {
          setSelectedCommands(
            selectedCommands.filter((item) => item !== commandId),
          );
        }
      } else {
        setSelectedCommands([commandId]);
      }
    };

  const handleCommandsCallback = (): void => {
    if (
      attachedInDbButNotLocally().length < 1 &&
      attachedInDbAndLocally().length >= selectedCommands.length
    ) {
      setSelectedCommands([]);
      handleChooseCommands([]);
    } else {
      handleChooseCommands(
        commands.filter((command) => selectedCommands.includes(command._id)),
      );
    }
  };

  const handleSelectAll = (): void => {
    if (selectedCommands.length !== commands.length) {
      setSelectedCommands(commands.map((command) => command._id));
    } else {
      setSelectedCommands([]);
    }
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getCommands(page + 1, searchValue, true);
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Save changes"
      title="Select Commands"
      disableAction={disableAction}
      actionCallback={handleCommandsCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          allSelected={selectedCommands.length === commands.length}
          itemsSelected={selectedCommands.length !== 0}
          hasItems={commands.length !== 0}
          hideSelectAll={singleSelect}
          searchPlaceholder="Search Commands"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <>
                <CardsGrid
                  twoColumns={true}
                  containerPadding={false}
                  cards={commands.map((command) => (
                    <AttachItemCard
                      content={
                        <CommandCard
                          key={command._id}
                          command={command}
                          showCopy={false}
                        />
                      }
                      checked={selectedCommands.includes(command._id)}
                      checkboxCallback={checkCommandCallback}
                      id={command._id}
                    />
                  ))}
                />
                {commands.length !== total && (
                  <Grid item xs={12} className={clsx('my-4 loading-container')}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleLoadMore}
                    >
                      {!loadingMore ? (
                        <Typography variant="button">Load more</Typography>
                      ) : (
                        <CircularProgress size={25} />
                      )}
                    </Button>
                  </Grid>
                )}
              </>
            )
          }
        />
      }
    />
  );
};

export default AttachCommandsDrawer;
