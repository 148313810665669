import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CommandInput, Commands } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import {
  cleanNewDeviceConfig,
  setNewCommandInput,
} from '../../redux/reducers/commands.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import { LocationState } from '../../models/common';
import {
  handleCommandDynamicValues,
  handleCommandAddRow,
  handleCommandRemoveRow,
  handleCommandChange,
  handleCommandReorderChange,
  parseDefaultOptionsValues,
} from '../../containers/Forms/CommandForm/helper';
import { ItemList } from '../../components/DynamicRows/constants';
import CommandForm from '../../containers/Forms/CommandForm';
import Header from '../../containers/HeaderWithActionButton';
import Publish from '../../containers/Publish';
import useStyles from './styles';

const CreateCommandPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const commandInput = useAppSelector(
    (state: RootState) => state.commands.newCommandInput,
  );

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [invalidSenderJson, setInvalidSenderJson] = useState(false);
  const [invalidOptionJson, setInvalidOptionJson] = useState(false);

  const checkSubmitEnable = (): void => {
    let missingValues = false;
    if (
      commandInput?.sender_type === 'workflow_sender' &&
      typeof commandInput.sender?.workflow_id !== 'string'
    ) {
      missingValues = true;
    }
    setEnableSubmit(
      commandInput?.company_id !== '' &&
        commandInput?.name !== '' &&
        !invalidSenderJson &&
        !invalidOptionJson &&
        !missingValues,
    );
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [commandInput, invalidSenderJson, invalidOptionJson]);

  const handleValueChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    if (commandInput) {
      dispatch(
        setNewCommandInput(
          handleCommandChange(
            commandInput,
            prop,
            value,
            setInvalidSenderJson,
            setInvalidOptionJson,
          ) as CommandInput,
        ),
      );
    }
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    dispatch(
      setNewCommandInput(
        handleCommandDynamicValues(
          commandInput as CommandInput,
          prop,
          value,
          field,
          index,
        ),
      ),
    );
  };

  const handleAddRow = (prop: string): void => {
    dispatch(
      setNewCommandInput(
        handleCommandAddRow(commandInput as CommandInput, prop),
      ),
    );
  };

  const handleRemoveRow = (prop: string, item: string | number): void => {
    dispatch(
      setNewCommandInput(
        handleCommandRemoveRow(commandInput as CommandInput, prop, item),
      ),
    );
  };

  const handleReorderRows = (prop: string, value: ItemList[]): void => {
    dispatch(
      setNewCommandInput(
        handleCommandReorderChange(commandInput as CommandInput, prop, value),
      ),
    );
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleValueChange('company_id', companyId);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    if (commandInput) {
      Commands.create(parseDefaultOptionsValues(commandInput))
        .then((_response) => {
          dispatch(
            setAlert({
              highlight: 'Command created successfully',
              type: 'success',
            }),
          );
          navigate('/commands');
          dispatch(cleanNewDeviceConfig());
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <Grid container direction="column" spacing={3} className="p-9 pt-6">
      <Header
        model="command"
        isCreatePage={true}
        goBack={goBackUrl ? goBackUrl : 'commands'}
        goBackLabel={goBackLabel || `Commands`}
      />

      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} lg={8}>
            <Paper className="shadow p-8">
              <Box className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-command-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new command
                </Typography>
              </Box>
              <CommandForm
                invalidSenderJson={invalidSenderJson}
                invalidOptionJson={invalidOptionJson}
                newCommand={commandInput as CommandInput}
                onInputChange={handleValueChange}
                onAddRow={handleAddRow}
                onRemoveRow={handleRemoveRow}
                onDynamicChange={handleDynamicChange}
                onReorder={handleReorderRows}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Publish
              label="account"
              submitting={submitting}
              companyId={commandInput?.company_id as string}
              onChangeAccount={handleOnAccountChange}
              onSubmit={handlePublishSubmit}
              enableSubmit={enableSubmit && !submitting}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateCommandPage;
