import React from 'react';
import { Typography, Grid, Box, Tooltip } from '@mui/material';
import { Device, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import TextInput from '../../../components/TextInput';
import useStyles from '../styles';
import { getLinkFromValue, openExtraLink } from '../../../helpers/utils';

interface ImportantMetadataProps {
  device: Device;
  deviceType?: DeviceType | null;
  onInputChange?: (key: string, value: string | number) => void;
}

const ImportantMetadata: React.FC<ImportantMetadataProps> = ({
  device,
  deviceType,
  onInputChange,
}) => {
  const classes = useStyles();

  const handleOpenMetadataLink = (url: string) => (): void => {
    openExtraLink(url);
  };

  return deviceType?.important_metadata &&
    deviceType.important_metadata.length !== 0 &&
    device.metadata !== undefined ? (
    <Box className="mt-6">
      <Typography variant="h5">Important Metadata</Typography>
      <p className="mt-3">
        Selected device metadata. This list can be configured on the device
        profile.
      </p>
      <Grid container direction="row" spacing={2}>
        {deviceType.important_metadata.map((importantMetadata, index) => {
          const key = importantMetadata.value;
          const value = device.metadata?.[key] as string;
          return (
            value && (
              <Grid
                item
                xs={12}
                lg={6}
                className="mt-2"
                key={`important-metadata-${index}`}
              >
                {value.indexOf('http') !== -1 ? (
                  <>
                    <Typography variant="body2" className="custom-label">
                      {importantMetadata.label}
                    </Typography>
                    <div className={classes.linkContainer}>
                      <Tooltip placement="top-start" title="Open link">
                        <Typography
                          data-cy={`metadata-link-${key}`}
                          variant="subtitle1"
                          component="div"
                          noWrap
                          className={clsx('mb-1', classes.linkButton)}
                          onClick={handleOpenMetadataLink(
                            getLinkFromValue(value),
                          )}
                        >
                          {value}
                        </Typography>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <TextInput
                    label={importantMetadata.label}
                    prop={key}
                    disabled={true}
                    dataCy={`important-metadata-${index}-value`}
                    value={value ?? ''}
                    onInputChange={onInputChange}
                  />
                )}
              </Grid>
            )
          );
        })}
      </Grid>
    </Box>
  ) : (
    <></>
  );
};

export default ImportantMetadata;
