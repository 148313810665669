import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Command, CommandInput } from '@edgeiq/edgeiq-api-js';

import { optionDataTypes } from '../../../constants/commands';
import ToggleOptions from '../../../components/ToggleOptions';
import CodeEditor from '../../../components/CodeEditor';
import DynamicRows from '../../../components/DynamicRows';
import { ItemList } from '../../../components/DynamicRows/constants';
import { prettifyCommandJSON } from './helper';

interface CommandOptionsFormProps {
  newCommand: Command | CommandInput;
  invalid?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
  onDynamicChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ) => void;
  onAddRow: (prop: string) => void;
  onRemoveRow: (prop: string, key: number | string) => void;
  onReorder: (prop: string, value: ItemList[]) => void;
}

const CommandOptionsForm: React.FC<CommandOptionsFormProps> = ({
  newCommand,
  invalid,
  onDynamicChange,
  onInputChange,
  onAddRow,
  onRemoveRow,
  onReorder,
}) => {
  const [chosenView, setChosenView] = useState('input');
  const [optionsValue, setOptionsValue] = useState(
    prettifyCommandJSON(newCommand.options),
  );
  const [optionsRows, setOptionsRows] = useState<ItemList[]>();

  useEffect(() => {
    if (newCommand.default_options) {
      setOptionsRows(
        newCommand.default_options.map((option) => {
          return {
            key: option.key,
            label: option.default,
            type: option.type,
          };
        }),
      );
    } else {
      setOptionsRows([]);
    }
  }, [newCommand.default_options]);

  useEffect(() => {
    setOptionsValue(prettifyCommandJSON(newCommand.default_options));
  }, [chosenView, newCommand.sender_type]);

  const onOptionsEditorChange = (
    _prop: string,
    value: string | number,
  ): void => {
    setOptionsValue(value as string);
    onInputChange('options', value);
  };

  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12}>
        <ToggleOptions
          label="Options"
          optionsKey="options_toggle"
          inlineLabel={true}
          rightSideOptions={true}
          chosenView={chosenView}
          options={[
            { label: 'Inputs', value: 'input' },
            { label: 'JSON editor', value: 'json' },
          ]}
          onViewChange={setChosenView}
        />
      </Grid>
      {chosenView !== 'input' ? (
        <Grid item xs={12}>
          <CodeEditor
            prop="default_options"
            mode="json"
            height={250}
            value={optionsValue}
            showInvalid={invalid}
            onInputChange={onOptionsEditorChange}
          />
        </Grid>
      ) : (
        <DynamicRows
          prop="default_options"
          arrayRowsTypes={optionsRows}
          objectRowsTypes={undefined}
          itemKey="key"
          itemKeyPlaceHolder="Key"
          itemValue="label"
          itemValuePlaceHolder="Default Value"
          buttonLabel="Options"
          useOnChangeHandlerObjectIndex={true}
          dropDownData={optionDataTypes}
          dropdownKey="type"
          dropDownDefaultValue="string"
          topMargin={false}
          addReorder={true}
          onInputChange={onDynamicChange}
          onAddRow={onAddRow}
          onRemoveArrayRow={onRemoveRow}
          onReorder={onReorder}
        />
      )}
    </Grid>
  );
};

export default CommandOptionsForm;
