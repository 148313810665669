import React, { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { Command } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setNewCommandInput } from '../../redux/reducers/commands.reducer';
import { commandsSenderTypes } from '../../constants/commands';
import useStyles from '../../components/Card/styles';

interface CommandCardProps {
  command: Command;
  showCopy?: boolean;
}

const CommandCard: React.FC<CommandCardProps> = ({
  command,
  showCopy = true,
}) => {
  const cardClasses = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCopyCommand = (event: MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    dispatch(
      setNewCommandInput({
        ...command,
        name: `${command.name} - copy`,
      }),
    );
    navigate('/new-command');
  };

  return (
    <Box>
      <Tooltip placement="top" title={command.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', cardClasses.title)}
        >
          {command.name}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Sender Type">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', cardClasses.tag)}
        >
          {commandsSenderTypes[command.sender_type]}
        </Typography>
      </Tooltip>
      {showCopy && (
        <div className={cardClasses.statusContainer}>
          <Tooltip placement="top" title="Copy Command">
            <IconButton
              size="small"
              data-y={`command-${command._id}-copy-button`}
              onClick={handleCopyCommand}
            >
              <ContentCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </Box>
  );
};

export default CommandCard;
