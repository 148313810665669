import { set, uniq } from 'lodash';
import { METADATA_ARRAY_OPERATORS } from '../app/constants';
import { MetadataFilter } from '../models/common';

export const parseMetadataFilters = (
  metadata: MetadataFilter[],
): {
  [key: string]: string;
} => {
  const parsed: { [key: string]: string } = {};
  for (let i = 0; i < metadata.length; i++) {
    if (!parsed[`metadata.${metadata[i].key}`]) {
      parsed[`metadata.${metadata[i].key}`] = Array.isArray(metadata[i].value)
        ? (metadata[i].value as string[]).join(',')
        : String(metadata[i].value);
    }
  }
  return parsed;
};

export const updateMetadataFilters = (
  filters: MetadataFilter[],
  index: number,
  key: string,
  value: string | string[],
  // the value will be number or string[] only when the key is `value`
): MetadataFilter[] => {
  const auxArray = [...filters];
  const isArray = METADATA_ARRAY_OPERATORS.includes(filters[index].operator);
  if (key === 'operator') {
    const oldValue = filters[index].value;
    const isNowArray = METADATA_ARRAY_OPERATORS.includes(value as string);
    set(
      auxArray,
      `${index}.key`,
      `${getMetadataFilterKey(filters[index].key)}_${value}`,
    );
    /**
     * Keeps the value when changing the operator.
     * cases:
     * - 'eq' or 'ne' => 'in' or 'nin'
     * - 'in' or 'nin' => 'eq' or 'ne'
     * for: 'eq' <=> 'ne' and 'in' <=> 'nin' the value is kept as is
     */
    if (!isArray && isNowArray) {
      set(auxArray, `${index}.value`, oldValue ? [oldValue] : []);
    } else if (isArray && !isNowArray) {
      set(auxArray, `${index}.value`, oldValue[0] ?? '');
    }
  }
  if (key === 'key') {
    set(auxArray, `${index}.key`, `${value}_${filters[index].operator}`);
  } else {
    set(
      auxArray,
      `${index}.${key}`,
      isArray && key === 'value' ? uniq(value as string[]) : value,
    );
  }
  return auxArray;
};

export const getMetadataFilterKey = (filter: string, start = 0): string => {
  return filter.substring(start, filter.lastIndexOf('_'));
};

export const getMetadataFilterOperator = (filter: string): string => {
  return filter.substring(filter.lastIndexOf('_') + 1, filter.length);
};
