import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import RightDrawer from '../../../components/RightDrawer/RightDrawer';
import { Device, Devices, Report } from '@edgeiq/edgeiq-api-js';
import { DateTime } from 'luxon';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight, hearbeatFields } from '../../../app/constants';
import { useAppDispatch } from '../../../redux/hooks';

interface ReportInformationDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  selectedReport: Report | undefined;
  header: string;
}

const ReportInformationDrawer: React.FC<ReportInformationDrawerProps> = ({
  open,
  handleCloseDrawer,
  selectedReport,
  header,
}) => {
  const dispatch = useAppDispatch();
  const [device, setDevice] = useState<undefined | Device>();

  useEffect(() => {
    getDevice();
  }, [selectedReport]);

  const getDevice = (): void => {
    if (selectedReport) {
      Devices.getOneById(selectedReport.device_id)
        .then((res) => {
          if (res) {
            setDevice(res);
          }
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        });
    }
  };

  if (!selectedReport) {
    return <></>;
  }
  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      title={header}
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Box>
          <div>
            <Typography variant="h5" className="custom-label">
              Device Unique ID
            </Typography>
            <Typography variant="body2" className="custom-label">
              {selectedReport.device_id}
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h5" className="custom-label">
              Created at
            </Typography>
            <Typography variant="body2" className="custom-label">
              {`${DateTime.fromISO(selectedReport.created_at).toFormat(
                'TT',
              )} ${DateTime.fromISO(selectedReport.created_at).toLocaleString(
                DateTime.DATE_SHORT,
              )}`}
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h5" className="custom-label">
              Payload
            </Typography>
            <Typography variant="body2" className="custom-label title-case">
              {selectedReport.payload ? (
                <>
                  {Object.entries(selectedReport.payload).map(
                    ([k, v], index) => {
                      return (
                        <Box display={'flex'} key={`${k}-${index}`}>
                          <Typography>{hearbeatFields[k] || k}:</Typography>
                          <Typography>{` ${v}` as string}</Typography>
                        </Box>
                      );
                    },
                  )}
                </>
              ) : (
                '-'
              )}
            </Typography>
          </div>

          <div className="mt-4">
            <Typography variant="h5" className="custom-label">
              Device
            </Typography>
            <Typography variant="body2" className="custom-label title-case">
              {device?.name || '-'}
            </Typography>
          </div>
        </Box>
      }
    />
  );
};

export default ReportInformationDrawer;
