import {
  Command,
  CommandInput,
  CommandDefaultOption,
  CommandSender,
  CommandSenderItem,
  SenderType,
} from '@edgeiq/edgeiq-api-js';
import cloneDeep from 'lodash.clonedeep';

import { ItemList } from '../../../components/DynamicRows/constants';
import { EMPTY_COMMAND } from '../../../constants/commands';

type commandInputKey = keyof CommandInput;

export const handleCommandChange = (
  command: Command | CommandInput,
  prop: string,
  value: string | number | string[],
  setInvalidSenderJson: React.Dispatch<React.SetStateAction<boolean>>,
  setInvalidOptionJson: React.Dispatch<React.SetStateAction<boolean>>,
): Command | CommandInput => {
  const result = cloneDeep(command);
  switch (prop) {
    case 'sender_type':
      result.sender_type = value as SenderType;
      if (result.sender_type === 'gcp_pubsub_sender') {
        result.sender = {
          attributes: {},
          gcp_topic: '',
          integration_id: '',
        };
      } else if (result.sender_type === 'http_sender') {
        result.sender = EMPTY_COMMAND.sender;
      } else if (result.sender_type === 'shell_sender') {
        result.sender = {
          command: '',
          timeout: 5,
        };
      } else if (result.sender_type === 'workflow_sender') {
        result.sender = {
          unique_identifier: '',
          workflow_id: '',
          workflow_instance_id: '',
        };
      } else {
        result.sender = {};
      }
      result.default_options = [];
      result.payload = '';
      setInvalidSenderJson(false);
      setInvalidOptionJson(false);
      break;
    case 'sender':
      try {
        // Even if the value is invalid, we have to set the value so that it is seen
        const formattedJson = JSON.parse(value as string);
        result.sender = formattedJson;
        // JSON.parse doesn't count an array as an error, so this is one more control to make sure it is shown as invalid
        if (Array.isArray(formattedJson)) {
          setInvalidSenderJson(true);
        } else {
          setInvalidSenderJson(false);
        }
      } catch (e) {
        if (!value) {
          setInvalidSenderJson(false);
          break;
        }
        setInvalidSenderJson(true);
      }
      break;
    case 'default_options':
      try {
        const formattedJson = JSON.parse(value as string);
        result[prop] = formattedJson;
        // JSON.parse doesn't count an array as an error, so this is one more control to make sure it is shown as invalid
        if (Array.isArray(formattedJson)) {
          setInvalidOptionJson(true);
        } else {
          setInvalidOptionJson(false);
        }
      } catch (e) {
        if (!value) {
          setInvalidOptionJson(false);
          break;
        }
        setInvalidOptionJson(true);
      }
      break;
    case 'save_command_output':
    case 'generate_child_command_executions':
      result[prop] = !command?.[prop];
      break;
    default:
      result[prop as commandInputKey] = value as never;
      break;
  }
  return result;
};

export const handleCommandDynamicValues = (
  command: Command | CommandInput,
  prop: string,
  value: string | number,
  field: string,
  index: string,
): Command | CommandInput => {
  switch (prop) {
    case 'sender':
      if (field === 'workflow_id') {
        return {
          ...(command as CommandInput | Command),
          sender: {
            ...(command?.sender ?? {}),
            workflow_id: value,
            workflow_instance_id: value,
          },
        };
      }
      if (field === 'unix_socket_enabled') {
        return {
          ...(command as CommandInput | Command),
          sender: {
            ...(command?.sender ?? {}),
            unix_socket_enabled: !command?.sender?.unix_socket_enabled,
          },
        };
      }
      return {
        ...(command as CommandInput | Command),
        sender: {
          ...(command?.sender ?? {}),
          [field]: value,
        },
      };
    case 'attributes':
    case 'headers':
      const attbArray =
        (prop === 'attributes'
          ? (command.sender?.attributes as CommandSender)
          : (command.sender?.headers as CommandSender)) || {};
      const tempArray = Object.entries(attbArray);
      if (field === 'name' || field === 'key') {
        tempArray[Number(index)][0] = value as string;
      }

      if (field === 'value') {
        tempArray[Number(index)][1] = value as string;
      }
      return {
        ...(command as CommandInput | Command),
        sender: {
          ...command?.sender,
          [prop === 'attributes' ? 'attributes' : 'headers']:
            formatSenderObject(tempArray) || {},
        },
      };
    case 'default_options':
      const optionsArray = command.default_options;
      if (optionsArray) {
        const optionItem = optionsArray[Number(index)];
        if (optionItem) {
          if (field === 'key') {
            optionItem.key = value as string;
          } else if (field === 'label') {
            const type = optionItem.type;
            optionItem.default =
              type === 'integer' ? (value as number) : (value as string);
          } else if (field === 'type') {
            optionItem.type = value as 'integer' | 'string';
          }
        }
      }

      return {
        ...(command as CommandInput | Command),
        default_options: optionsArray,
      };
    default:
      return command;
  }
};

export const handleCommandAddRow = (
  commandInput: Command | CommandInput,
  prop: string,
): Command | CommandInput => {
  switch (prop) {
    case 'attributes':
    case 'headers':
      const attbArray =
        (prop === 'attributes'
          ? commandInput?.sender?.attributes
          : commandInput?.sender?.headers) || {};
      return {
        ...(commandInput as CommandInput),
        sender: {
          ...commandInput?.sender,
          [prop === 'attributes' ? 'attributes' : 'headers']: {
            ...(attbArray as { [key: string]: string }),
            '': '',
          },
        },
      };
    case 'default_options':
      const newOption: CommandDefaultOption = {
        default: '',
        key: '',
        type: 'string',
      };
      return {
        ...(commandInput as CommandInput),
        default_options: commandInput.default_options
          ? [...commandInput.default_options, newOption]
          : [newOption],
      };
    default:
      return commandInput;
  }
};

export const handleCommandRemoveRow = (
  commandInput: Command | CommandInput,
  prop: string,
  item: string | number,
): Command | CommandInput => {
  switch (prop) {
    case 'attributes':
    case 'headers':
      const attbArray: { [key: string]: string } =
        (prop === 'attributes'
          ? (commandInput?.sender?.attributes as { [key: string]: string })
          : (commandInput?.sender?.headers as { [key: string]: string })) || {};
      delete attbArray[item];
      return {
        ...commandInput,
        sender: {
          ...commandInput?.sender,
          [prop === 'attributes' ? 'attributes' : 'headers']: {
            ...(attbArray as { [key: string]: string }),
          },
        },
      };
    case 'default_options':
      const optionsArray = commandInput.default_options;
      if (optionsArray) {
        // Item is the index in the array.
        optionsArray.splice(item as number, 1);
        return {
          ...(commandInput as CommandInput | Command),
          default_options: optionsArray,
        };
      }
      return commandInput;
    default:
      return commandInput;
  }
};

export const handleCommandReorderChange = (
  command: Command | CommandInput,
  prop: string,
  value: ItemList[],
): Command | CommandInput => {
  switch (prop) {
    case 'default_options':
      return {
        ...command,
        default_options: value.map((item) => {
          return {
            default: item.label,
            key: item.key,
            type: item.type ? (item.type as 'string' | 'integer') : 'string',
          };
        }),
      };
    default:
      return command;
  }
};

const formatSenderObject = (
  tempArray: [string, CommandSenderItem][],
): { [key: string]: string | number } => {
  return tempArray.reduce((acc, current) => {
    const key = current[0];
    const value = current[1];
    return { ...acc, [key]: value };
  }, {});
};

export const prettifyCommandJSON = (
  jsonObject?: CommandSender | CommandDefaultOption[],
): string => JSON.stringify(jsonObject || {}, null, 3);

/**
 * Parse the default_options of a command from:
 * [{ key: 'some key', type:'string', default: 'some value'}]
 * to:
 * {"some key": "some value"}
 * so before executing the command the user can update the default value.
 * @param command
 * @returns Command
 */
export const mapExecuteCommandDefaultOptions = (command: Command): Command => {
  if (command.default_options && command.default_options.length !== 0) {
    const parsedOptions: { [key: string]: string | number } = {};
    command.default_options.forEach((option) => {
      parsedOptions[option.key] = option.default;
    });
    return {
      ...command,
      options: parsedOptions,
    };
  }
  return command;
};

export const parseDefaultOptionsValues = (
  command: Command | CommandInput,
): Command | CommandInput => {
  const options = command.default_options;
  if (options && options.length) {
    const newOptions = options.map((option) => {
      return {
        ...option,
        default: String(option.default),
      };
    });
    return {
      ...command,
      default_options: newOptions,
    };
  }
  return command;
};
