import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Chip,
  createFilterOptions,
  FilterOptionsState,
  TextField,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  CancelOutlined as CancelIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import useStyles from './styles';

const filter = createFilterOptions<string>();

export interface TagsProps {
  originalTags: string[];
  onChangeTags: (prop: string, tags: string[]) => void;
  prop?: string;
  label?: string;
  placeholder?: string;
  width?: number;
  tagsOptions?: boolean;
  tags?: string[];
}

const Tags: React.FC<TagsProps> = ({
  originalTags,
  onChangeTags,
  prop = 'tags',
  tagsOptions = true,
  label,
  tags,
  placeholder,
  width = 200,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string | null>(null);
  const [tagList, setTagList] = useState<string[]>([]);

  useEffect(() => {
    if (tags && tagsOptions) {
      setTagList(tags);
    }
  }, [tags]);

  const filterOptions = (
    options: string[],
    params: FilterOptionsState<string>,
  ): string[] => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option);
    if (inputValue && !isExisting) {
      filtered.push(inputValue);
    }

    return filtered;
  };

  const renderOption = (
    props: React.HtmlHTMLAttributes<HTMLElement>,
    option: string,
  ): React.ReactNode => <li {...props}>{option}</li>;

  const handleTypingChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  const renderInput = (
    params: AutocompleteRenderInputParams,
  ): React.ReactNode => (
    <TextField
      {...params}
      placeholder={placeholder ?? `Add a tag...`}
      onChange={handleTypingChange}
    />
  );

  // Adds the value of the autocomplete to the tags
  const addTag = (): void => {
    if (value) {
      // Making sure the same value doesn't exist on the tags array and then add it.
      const newTags = originalTags.filter((tag) => tag !== value).concat(value);
      onChangeTags(prop, newTags);
      setTagList(tagList.filter((tag) => tag !== value));
      setValue(null);
    }
  };

  const removeTag = (tagToDelete: string) => (): void => {
    const newTags = originalTags.filter((tag) => tag !== tagToDelete);
    onChangeTags(prop, newTags);
    setTagList(tagList.concat(tagToDelete));
  };

  const handleChange = (
    _e: React.SyntheticEvent,
    newValue: string | null,
  ): void => {
    setValue(newValue);
  };

  return (
    <div>
      {label && (
        <Typography
          variant="body2"
          className="custom-label"
          data-cy={`${prop}-tags-label`}
        >
          {label}
        </Typography>
      )}
      <div className={clsx('mb-6', classes.inputContainer)}>
        <Autocomplete
          data-cy={`${prop}-tags-input`}
          data-testid="tags-input"
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          freeSolo
          value={value}
          options={tagList}
          onChange={handleChange}
          className="mr-2"
          sx={{ width }}
          filterOptions={filterOptions}
          renderInput={renderInput}
          renderOption={renderOption}
          getOptionLabel={(option): string => option}
        />
        <Button
          data-cy={`${prop}-tags-add-button`}
          variant="outlined"
          size="large"
          startIcon={<AddIcon />}
          onClick={addTag}
        >
          <Typography variant="button">Add</Typography>
        </Button>
      </div>
      <div>
        {originalTags.map((tag, index) => (
          <Chip
            key={`${tag}-${index}`}
            data-cy={`${prop}-tag-${index}`}
            className={clsx('br-5 mr-4', classes.chip)}
            label={
              <Typography variant="caption" component="span" color="white">
                {tag}
              </Typography>
            }
            onDelete={removeTag(tag)}
            deleteIcon={
              <CancelIcon
                className={classes.chipIcon}
                data-cy={`${prop}-tag-${index}-delete`}
              />
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Tags;
