import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  SoftwareVersion,
  SoftwareVersions,
  SoftwareVersionsFilters,
} from '@edgeiq/edgeiq-api-js';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { CREATED_NEWEST_SORTING } from '../../../constants/sortings';
import timeHelpers from '../../../helpers/timeHelpers';
import TabsPage from '../../../components/TabsPage';
import SoftwareVersionDetailsDrawer from '../../../containers/RightDrawer/SoftwareVersion/SoftwareVersionDetailsDrawer';

const mockVersions: SoftwareVersion[] = [
  {
    _id: '675858a3b34f3200010629b1',
    company_id: 'edgeiq_dev',
    created_at: '2024-12-10T15:07:01.103725Z',
    device_id: '6758583f0d544e00010b07a4',
    name: 'bare',
    notes: 'active',
    origin: 'cloud',
    publisher: 'canonical',
    revision: 5,
    source: 'snap',
    track: '',
    updated_at: '2024-12-10T15:07:01.103725Z',
    user_id: '6509d0d742b80c0001c27a48',
    version_major: 1,
    version_minor: 0,
    version_patch: 0,
    version_raw: '1.0',
  },
];

const DeviceSoftwareVersions: React.FC = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [softwareVersions, setSoftwareVersions] = useState<SoftwareVersion[]>(
    [],
  );
  const [loading, setLoading] = useState(false);
  const [selectedSoftwareVersion, setSelectedSoftwareVersion] =
    useState<SoftwareVersion>();
  const [infoDrawer, setInfoDrawer] = useState(false);

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography noWrap variant="button" fontWeight={700} component="div">
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'source',
      flex: 0.3,
      headerName: 'Source',
    },
    {
      field: 'version_raw',
      flex: 0.3,
      headerName: 'Version',
    },
    {
      field: 'revision',
      flex: 0.3,
      headerName: 'Revision',
    },
    {
      field: 'created_at',
      flex: 0.5,
      headerName: 'First seen at',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{timeHelpers.getDate(params.row.created_at, false, true)}</span>
      ),
    },
    {
      field: 'action',
      flex: 0.1,
      headerName: 'Action',
      renderCell: (params: GridRenderCellParams): ReactElement => {
        return (
          <Typography
            variant="button"
            style={{ cursor: 'pointer' }}
            onClick={handleShowDetails(params.row._id)}
          >
            View Details
          </Typography>
        );
      },
    },
  ];

  const handleShowDetails = (versionId: string) => (): void => {
    const selected = softwareVersions.find((s) => s._id === versionId);
    if (selected) {
      setSelectedSoftwareVersion(selected);
      handleOpenDrawer();
    }
  };

  const handleOpenDrawer = (): void => {
    setInfoDrawer(!infoDrawer);
  };

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);

      const filters: SoftwareVersionsFilters = {
        device_id: {
          operator: 'eq',
          value: id as string,
        },
      };

      SoftwareVersions.list(filters, {
        ...optionsPaginationsFilter,
        order_by: CREATED_NEWEST_SORTING.value,
      })
        .then((result) => {
          const foundNames: string[] = [];
          const versionsToShow: SoftwareVersion[] = [];
          result.softwareVersions.forEach((version) => {
            if (!foundNames.includes(version.name)) {
              foundNames.push(version.name);
              versionsToShow.push(version);
            }
          });
          if (versionsToShow.length) {
            setSoftwareVersions(versionsToShow);
          } else {
            setSoftwareVersions(mockVersions);
          }
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  return (
    <Box>
      <TabsPage
        tableTitle="Software Versions"
        columns={columns}
        rows={softwareVersions}
        loading={loading}
      />
      {selectedSoftwareVersion && (
        <SoftwareVersionDetailsDrawer
          open={infoDrawer}
          softwareVersion={selectedSoftwareVersion}
          handleCloseDrawer={handleOpenDrawer}
        />
      )}
    </Box>
  );
};

export default DeviceSoftwareVersions;
