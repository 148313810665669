import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  SoftwareUpdates,
  SoftwareUpdate,
  SoftwareUpdatesFilters,
} from '@edgeiq/edgeiq-api-js';

import {
  setOriginalSelectedSoftwareUpdate,
  setSelectedSoftwareUpdate,
} from '../../../redux/reducers/softwareUpdates.reducer';

import TabsPage from '../../../components/TabsPage';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { ContentPageTabProps } from '../../../models/common';
import timeHelpers from '../../../helpers/timeHelpers';
import SoftwareUpdateDrawer from '../../../containers/RightDrawer/SoftwarePackage/SoftwarePackageDrawer';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import useStyles from '../styles';

const DeviceSoftwareUpdates: React.FC<ContentPageTabProps> = ({ goToItem }) => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const selectedSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.selectedSoftwareUpdate,
  );
  const originalSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.originalSelectedSoftwareUpdate,
  );
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [manageSoftwareUpdateDrawer, setManageSoftwareUpdateDrawer] =
    useState(false);
  const [selectedSoftwareUpdates, setSelectedSoftwareUpdates] = useState<
    string[]
  >([]);

  const columns: GridColDef[] = [
    {
      field: '_id',
      flex: 0.7,
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <TypographyWithCopy
          dataCy={`device-ingestor-${params.row._id}`}
          text={params.row._id}
          typographyVariant="button"
        />
      ),
    },
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
          onClick={goToItem('software-update', params.row._id)}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'created_at',
      flex: 0.5,
      headerName: 'Date created',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{timeHelpers.getPlainDate(params.row.created_at)}</span>
      ),
    },
    {
      field: 'summary',
      flex: 0.3,
      headerName: 'Summary',
    },
    {
      field: 'is_from_device_type',
      flex: 0.3,
      headerName: 'Attach via Device Profile',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.is_from_device_type ? 'True' : 'False'}</span>
      ),
    },
  ];

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);

      const filters: SoftwareUpdatesFilters = {
        device_type_id: {
          operator: 'eq',
          value: editableDevice.device_type_id,
        },
      };

      SoftwareUpdates.list(filters)
        .then((resUpdates) => {
          const results = resUpdates.softwareUpdates.map((sw) => {
            return {
              ...sw,
              is_from_device_type: true,
            };
          });
          setSelectedSoftwareUpdates(results.map((e) => e._id));
          dispatch(setSelectedSoftwareUpdate(results));
          dispatch(setOriginalSelectedSoftwareUpdate(results));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  const handleOpenManageSoftwareUpdateDrawer = (): void => {
    setManageSoftwareUpdateDrawer(true);
  };

  const handleCloseSoftwareUpdateDrawer = (): void => {
    setManageSoftwareUpdateDrawer(false);
  };

  const handleManageSoftwareUpdate = (
    softwareUpdateToDetach: SoftwareUpdate[],
  ): void => {
    const newSoftwareUpdate = originalSoftwareUpdate.filter((sw) => {
      return !softwareUpdateToDetach.find((e) => e._id === sw._id);
    });
    dispatch(setSelectedSoftwareUpdate(newSoftwareUpdate));
    handleCloseSoftwareUpdateDrawer();
    setLoading(true);

    const attachSoftwareUpdate = softwareUpdateToDetach.filter(
      (softwareUpdate) =>
        originalSoftwareUpdate.every(
          (originalSoftwareUpdateItem) =>
            softwareUpdate._id !== originalSoftwareUpdateItem._id,
        ),
    );

    Promise.all([
      Promise.all(
        attachSoftwareUpdate.map(async (attachCommand) => {
          await SoftwareUpdates.assignToDevice(attachCommand._id, id as string);
        }),
      ),
      Promise.all(
        softwareUpdateToDetach.map(async (detachCommand) => {
          await SoftwareUpdates.removeFromDevice(
            detachCommand._id,
            id as string,
          );
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalSelectedSoftwareUpdate(newSoftwareUpdate));
        dispatch(
          setAlert({
            highlight: 'Software Packages updated',
            message: 'Device software packages successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateSoftwareUpdate = (): void => {
    goToItem('software-update')();
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedSoftwareUpdate}
        addButtonLabel="Manage Software Updates"
        addButtonIcon={false}
        createButtonLabel="Create Software Package"
        onCreateClick={handleCreateSoftwareUpdate}
        onAddClick={handleOpenManageSoftwareUpdateDrawer}
        tableTitle="Software Updates added"
        loading={loading}
      />

      <SoftwareUpdateDrawer
        device_type_id={editableDevice?.device_type_id}
        selectedSoftwareUpdates={selectedSoftwareUpdates}
        open={manageSoftwareUpdateDrawer}
        onCloseDrawer={handleCloseSoftwareUpdateDrawer}
        onChoosingSoftwareUpdate={handleManageSoftwareUpdate}
      />
    </Box>
  );
};

export default DeviceSoftwareUpdates;
