import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      '&:not(:first-child)': {
        paddingLeft: '10px',
      },
      paddingBottom: '10px',
      paddingRight: '0',
      paddingTop: '10px',
      textAlign: 'left',
    },
    cellContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    divider: {
      backgroundColor: 'rgb(224, 224, 224)',
      height: '23px',
      width: '2px',
    },
    header: {
      '&:not(:first-child)': {
        paddingLeft: '10px',
      },
      borderBottom: '1px solid rgb(224, 224, 224)',
      paddingBottom: '10px',
      paddingRight: '0',
      paddingTop: '10px',
      textAlign: 'left',
    },
    row: {
      '&:not(:last-child)': {
        borderBottom: '1px solid rgb(224, 224, 224)',
      },
    },
    table: {
      borderSpacing: '0',
      paddingLeft: '12px',
      paddingRight: '32px',
      // width: 'calc(100% - 44px)',
    },
    tableContainer: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

export default useStyles;
