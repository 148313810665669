import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  // CircularProgress,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Device,
  Devices,
  DeviceType,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setOptionsDeviceTypes } from '../../../redux/reducers/deviceTypes.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { checkMixedTypes } from '../../../helpers/utils';
import useStyles from '../../../components/RightDrawer/styles';
import DeviceTypeIconName from '../../../components/DeviceTypeIconName';
import SelectInput from '../../../components/SelectInput';
import RightDrawer from '../../../components/RightDrawer/RightDrawer';

interface IssueWorkflowDrawerProps {
  open: boolean;
  devices?: Device[];
  handleCloseDrawer: () => void;
  handleSubmitSuccess: () => void;
  onRemoveDevice?: (deviceId: string) => void;
}

const IssueWorkflowDrawer: React.FC<IssueWorkflowDrawerProps> = ({
  open,
  devices,
  handleCloseDrawer,
  onRemoveDevice,
}) => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const deviceTypes = useAppSelector(
    (state: RootState) => state.deviceTypes.optionsDeviceTypes,
  );
  const { optionsWorkflows } = useAppSelector(
    (state: RootState) => state.workflows,
  );
  const [chosenWorkflow, setChosenWorkflow] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [mixedTypes, setMixedTypes] = useState(false);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getDeviceType = (id: string): DeviceType | undefined =>
    deviceTypes.find((deviceType) => deviceType._id === id);

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    if (checkMixedTypes(devices || [], deviceTypes)) {
      setMixedTypes(true);
    } else {
      setMixedTypes(false);
    }
  }, [devices]);

  const onWorkflowChange = (_prop: string, value: string | number): void => {
    setChosenWorkflow(value as string);
  };

  const handleRemoveDevice = (deviceId: string) => (): void => {
    if (onRemoveDevice) {
      onRemoveDevice(deviceId);
    }
  };

  const renderDevices = (list: Device[], preIndex?: string): JSX.Element => (
    <div className={clsx('scrollbar mb-4', classes.drawerSelectedDevices)}>
      {list.map((device) => (
        <div
          key={`${preIndex ? `${preIndex}-` : ''}${device._id}`}
          data-cy="device-container"
          className={clsx('mb-2 br-1 p-2', classes.drawerDeviceContainer)}
        >
          <Typography variant="body2" component="div" data-cy="device-name">
            {device.name}
          </Typography>
          <div className="d-flex flex-items-center">
            <DeviceTypeIconName
              labelVariant="body2"
              deviceType={getDeviceType(device.device_type_id)}
            />
            {onRemoveDevice && (
              <IconButton
                aria-label="close-drawer"
                data-cy="remove-device-button"
                onClick={handleRemoveDevice(device._id)}
              >
                <CloseIcon className={classes.closeDrawerIcon} />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const renderMenuItem = (value: string, label: string): JSX.Element => (
    <MenuItem className="m-4 p-2" dense key={value} value={value}>
      {label}
    </MenuItem>
  );

  const renderWorkflowOptions = (): JSX.Element[] => {
    return optionsWorkflows.map((workflow) =>
      renderMenuItem(workflow._id, workflow.name),
    );
  };

  const handleActionCallback = (): void => {
    if (devices) {
      setLoading(true);
    }
    const ids = devices?.map((d) => d._id);
    if (ids && chosenWorkflow) {
      Devices.bulkExecuteWorkflow(chosenWorkflow, ids)
        .then((_res) => {
          dispatch(
            setAlert({
              link: 'messages#bulk-jobs',
              linkText: 'bulk job',
              message:
                'A <link> has been created to issue the workflow to the selected devices',
              type: 'success',
            }),
          );
          handleCloseDrawer();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const disableDrawerAction = !chosenWorkflow;

  return (
    <RightDrawer
      open={open}
      actionLabel="Issue Workflow"
      title="Issue Workflow"
      disableAction={disableDrawerAction}
      actionLoading={loading}
      actionCallback={handleActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <div>
          {mixedTypes ? (
            <Typography
              variant="button"
              component="div"
              className="mb-3"
              data-cy="mixedTypes-warning"
            >
              No workflow can be issued for LWM2M devices and devices of other
              types.
            </Typography>
          ) : (
            <>
              <Typography
                variant="button"
                component="div"
                className="mb-3"
                data-cy="selected-devices-count"
              >
                {devices?.length === 1
                  ? '1 Device '
                  : `${devices?.length} Devices `}
                selected
              </Typography>
              {renderDevices(devices ?? [])}
              <div className="mt-6 mb-6">
                <SelectInput
                  prop="chosenWorkflow"
                  label="Workflow"
                  value={chosenWorkflow}
                  onSelectChange={onWorkflowChange}
                  options={[
                    <MenuItem dense value="" key="no-value-workflow">
                      Select a workflow
                    </MenuItem>,
                    ...renderWorkflowOptions(),
                  ]}
                />
              </div>
            </>
          )}
        </div>
      }
    />
  );
};

export default IssueWorkflowDrawer;
