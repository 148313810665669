import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { Companies, Company, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import { set, get } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setHierarchyFilters,
  setHierarchyLevels,
  setHierarchySearchAccount,
} from '../../redux/reducers/filters.reducer';
import { EMPTY_COMPANY } from '../../constants/companies';
import { errorHighlight } from '../../app/constants';
import AccountAutocomplete from '../AccountAutocomplete';
import AccountsExplorer from '../../components/AccountExplorer';
import {
  ExplorerItem,
  ExplorerLevel,
} from '../../components/AccountExplorer/AccountsExplorer';

interface ContainerProps {
  selectedAccountsIds: string[];
  ancestorId?: string;
  onChoosingAccount: (id: string) => void;
  onChoosingAncestor: (id: string) => void;
  updateLoading?: Dispatch<React.SetStateAction<boolean>>;
}

const defaultPagination: PaginationFilter = {
  itemsPerPage: 24,
  order_by: 'name',
  page: 1,
};

const initialLevel = {
  children: [],
  page: 1,
};

const AccountsExplorerContainer: React.FC<ContainerProps> = ({
  selectedAccountsIds,
  ancestorId,
  onChoosingAccount,
  onChoosingAncestor,
  updateLoading,
}) => {
  const dispatch = useAppDispatch();
  const stateUser = useAppSelector((state: RootState) => state.user);
  const hierarchyFiltersState = useAppSelector(
    (state: RootState) => state.filters.hierarchyFilters,
  );
  const levels = hierarchyFiltersState.levels;
  const searchAccount = hierarchyFiltersState.searchAccount;
  const [searchAccountAncestors, setSearchAccountAncestors] = useState<
    Company[]
  >([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const manageLoadings = (isLoading: boolean): void => {
    if (updateLoading) {
      updateLoading(isLoading);
    }
    setLoading(isLoading);
  };

  const scrollRight = (): void => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        left: containerRef.current.scrollWidth,
      });
    }
  };

  const fetchAccounts = (
    parent: Company,
    updatedLevels: ExplorerLevel[],
    levelIndex?: number,
    addPage = false,
  ): void => {
    const pagination = {
      ...defaultPagination,
    };
    let level: ExplorerLevel | undefined;
    if ((levelIndex || levelIndex === 0) && addPage) {
      level = levels[levelIndex];
      if (level) {
        pagination.page = level.page + 1;
      }
    }

    Companies.list(
      { company_id: { operator: 'eq', value: parent._id } },
      pagination,
    )
      .then((result) => {
        const levelsObject = {
          levels: [...updatedLevels],
        };
        const newAccounts =
          pagination.page !== 1
            ? [
                ...(level?.children?.map((child) => child.item) as Company[]),
                ...result.companies,
              ]
            : result.companies;
        set(levelsObject, `levels.${levelIndex || '0'}`, {
          children: newAccounts.map((newAccount) => {
            return {
              item: newAccount,
              selectBranch: ancestorId === newAccount._id,
              selected: selectedAccountsIds.includes(newAccount._id),
            };
          }),
          loading: false,
          page: pagination.page,
          total: result.pagination.total,
        });
        dispatch(setHierarchyLevels(levelsObject.levels));
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        manageLoadings(false);
        // Scroll to the right side of the container parent
        if (levelIndex) {
          scrollRight();
        }
      });
  };

  const createAncesotrsHierarchy = async (
    ancestors: Company[],
    accountId: string,
  ): Promise<void> => {
    const updatedLevels: ExplorerLevel[] = [];
    const levelsObject = {
      levels: updatedLevels,
    };
    manageLoadings(true);
    for (let i = 0; i < ancestors.length; i++) {
      const ancestorAccount = ancestors[i];
      if (i === 0) {
        const item = {
          item: ancestorAccount,
          selectBranch: accountId === ancestorAccount._id,
          selected: selectedAccountsIds.includes(ancestorAccount._id),
        };
        set(levelsObject, `levels.0`, {
          children: [item],
          chosenItem: item,
          loading: false,
          page: 1,
          total: 1,
        });
      }
      const { companies, pagination } = await Companies.list(
        { company_id: { operator: 'eq', value: ancestorAccount._id } },
        defaultPagination,
      );
      set(levelsObject, `levels.${i + 1}`, {
        children: companies.map((newAccount) => {
          return {
            item: newAccount,
            selectBranch: accountId === newAccount._id,
            selected: selectedAccountsIds.includes(newAccount._id),
          };
        }),
        chosenItem:
          i !== ancestors.length - 1
            ? {
                item: ancestors[i + 1],
                selectBranch: accountId === ancestors[i + 1]._id,
                selected: selectedAccountsIds.includes(ancestors[i + 1]._id),
              }
            : undefined,
        loading: false,
        page: pagination.page,
        total: pagination.total,
      });
      if (i === ancestors.length - 1) {
        manageLoadings(false);
        dispatch(setHierarchyLevels(levelsObject.levels));
        scrollRight();
      }
    }
  };

  const getAccountAncestors = (id: string, createLevels = false): void => {
    Companies.getAncestors(id)
      .then((res) => {
        let array = res;
        if (res.length > 1) {
          array = res
            .slice(1)
            .reverse()
            .map((element: Company) => {
              return element;
            });
        }
        if (createLevels) {
          createAncesotrsHierarchy(array, id);
        }
        setSearchAccountAncestors(array);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => manageLoadings(false));
  };

  const checkAncestorAccount = (id: string): void => {
    let exists = false;
    // Chose a for loop to be able to break it
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      for (let j = 0; j < level.children.length; j++) {
        const explorerItem = level.children[j];
        if (explorerItem.item._id === id) {
          exists = true;
          break;
        }
      }
    }
    if (!exists) {
      // If the account doesn't exist on the levels available then we get the ancestors of the account
      getAccountAncestors(id, true);
    }
  };

  useEffect(() => {
    const firstLevelItem: ExplorerItem = {
      item: {
        ...EMPTY_COMPANY,
        _id: '',
        created_at: '',
        origin: '',
        updated_at: '',
        user_id: '',
      },
      selectBranch: false,
      selected: false,
    };
    if (searchAccount) {
      // Case 1: The id of the search account is the same as the first item level in the present levels => don't fetch
      //          This case is when we closed the drawer and opened it again. We want to maintain the state in this case
      // Case 2: The id of the search account is NOT the same as the first item level in the present levels => fetch accounts
      //          This case is when we reopened the drawer and chose to search for a different account. We should be able to get new results
      if (
        levels.length !== 0 &&
        levels[0].chosenItem?.item._id !== searchAccount._id
      ) {
        firstLevelItem.item = searchAccount;
        manageLoadings(true);
        fetchAccounts(
          searchAccount,
          [
            {
              ...initialLevel,
              children: [firstLevelItem],
              chosenItem: firstLevelItem,
              total: 1,
            },
          ],
          1,
        );
      }
    } else if (stateUser.userCompany) {
      setSearchAccountAncestors([]); // Reset the search accounts ancestors, which are used for the breadcrumb
      // Case 1: The user already navigated before without choosing an account to fiter by it's best to keep the navigation as it was
      // Case 2: The user navigated and chose an ancestor account
      //          => we should keep the navigation if the ancestor account is present in the levels we have in the redux state
      // Case 3: The user didn't use it or after removing the search account the levels will be empty
      //          Fetch children accounts using the account of the user as a parent
      //          This will populate the second level of the tree (index 1)
      if (ancestorId) {
        // If we have an ancestorId, we check for its presence in the present levels
        checkAncestorAccount(ancestorId);
      } else if (levels.length === 0) {
        firstLevelItem.item = stateUser.userCompany;
        manageLoadings(true);
        fetchAccounts(
          stateUser.userCompany,
          [
            {
              ...initialLevel,
              children: [firstLevelItem],
              chosenItem: firstLevelItem,
              total: 1,
            },
          ],
          1,
        );
      }
    }
  }, [searchAccount, stateUser.userCompany]);

  // This useEffect is only to help scroll to the far right in case there is a search already in the filter
  useEffect(() => {
    scrollRight();
  }, [containerRef]);

  const handleChooseAccount =
    (index: number, explorerItem: ExplorerItem) => (): void => {
      if (
        !levels[index].chosenItem ||
        (levels[index].chosenItem &&
          levels[index].chosenItem?.item._id !== explorerItem.item._id)
      ) {
        // Creating an object this way as it is necessary for the lodash set function to work properly
        const levelsObject = {
          levels: [...levels],
        };
        // If a different account was chosen in a level remove all sublevels as they are no longer relevant
        while (levelsObject.levels.length > index + 1) {
          levelsObject.levels.pop();
        }
        // update the level where the account was chosen
        set(levelsObject, `levels.${index}.chosenItem`, explorerItem);
        // Add a new level with one index more with no children. This will show the loading in the level.
        set(levelsObject, `levels.${index + 1}`, {
          children: [],
          loading: true,
          page: 1,
        });
        dispatch(setHierarchyLevels(levelsObject.levels));
        // Get the children accounts of the chosen account
        fetchAccounts(explorerItem.item, levelsObject.levels, index + 1);
      }
    };

  const handleCheckboxClick =
    (index: number, levelIndex: number, accountId: string) => (): void => {
      const levelsObject = {
        levels: [...levels],
      };
      const oldValue = get(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selected`,
      );
      onChoosingAccount(accountId);
      set(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selected`,
        !oldValue,
      );
      // if the account was not checked we add it to selectedAccountsIndexes otherwise we remove it
      let selectedAccountsIndexes =
        hierarchyFiltersState.selectedAccountsIndexes;
      if (!oldValue) {
        if (!selectedAccountsIndexes) {
          selectedAccountsIndexes = {};
        }
        selectedAccountsIndexes[accountId] = {
          index,
          levelIndex,
        };
      } else if (
        selectedAccountsIndexes &&
        selectedAccountsIndexes[accountId]
      ) {
        delete selectedAccountsIndexes[accountId];
      }
      dispatch(
        setHierarchyFilters({
          ...hierarchyFiltersState,
          levels: levelsObject.levels,
          selectedAccountsIndexes,
        }),
      );
    };

  const handleSwitchChange =
    (index: number, levelIndex: number, accountId: string) => (): void => {
      const levelsObject = {
        levels: [...levels],
      };
      const oldValue = get(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selectBranch`,
      );
      if (!oldValue) {
        onChoosingAncestor(accountId);
      } else {
        onChoosingAncestor('');
      }
      set(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selectBranch`,
        !oldValue,
      );
      // if the account was not switched as an ancestor filter we add it to ancestorAccountIndexes otherwise we remove it
      let ancestorAccountIndexes = hierarchyFiltersState.ancestorAccountIndexes;
      if (!oldValue) {
        if (!ancestorAccountIndexes) {
          ancestorAccountIndexes = {};
        }
        ancestorAccountIndexes[accountId] = {
          index,
          levelIndex,
        };
      } else if (ancestorAccountIndexes && ancestorAccountIndexes[accountId]) {
        delete ancestorAccountIndexes[accountId];
      }
      dispatch(
        setHierarchyFilters({
          ...hierarchyFiltersState,
          ancestorAccountIndexes,
          levels: levelsObject.levels,
        }),
      );
    };

  const handleLoadMore = (index: number) => (): void => {
    const levelsObject = {
      levels: [...levels],
    };
    set(levelsObject, `levels.${index}.loading`, true);
    dispatch(setHierarchyLevels(levelsObject.levels));
    if (index === 0 && stateUser.userCompany) {
      fetchAccounts(stateUser.userCompany, levelsObject.levels, index, true);
    } else if (levels[index - 1].chosenItem) {
      fetchAccounts(
        (levels[index - 1].chosenItem as ExplorerItem).item,
        levelsObject.levels,
        index,
        true,
      );
    }
  };

  const handleSearchAccountChange = (account: Company | undefined): void => {
    dispatch(setHierarchySearchAccount(account));
    if (account) {
      getAccountAncestors(account._id);
    } else if (searchAccount) {
      // if the user removed the search account then we should reset the explorer levels
      // only if the search account set before is not undefined
      dispatch(setHierarchyLevels([]));
    }
  };

  const accountExplorerProps = {
    ancestorId,
    containerRef,
    handleCheckboxClick,
    handleChooseAccount,
    handleLoadMore,
    handleSwitchChange,
    levels,
    loading,
    searchAccountAncestors,
    totalSelectedAccountsIds: selectedAccountsIds.length,
  };

  return (
    <>
      <div className="my-3">
        <AccountAutocomplete
          isDisabled={false}
          includeParentId={true}
          selectedAccount={searchAccount?._id ?? ''}
          placeHolder="Search for an account to set as top level"
          onAccountChangeModel={handleSearchAccountChange}
        />
      </div>
      <AccountsExplorer {...accountExplorerProps} />
    </>
  );
};

export default AccountsExplorerContainer;
