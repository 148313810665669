import React, { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import {
  Command,
  Commands,
  DeviceTypes,
  Translators,
} from '@edgeiq/edgeiq-api-js';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  setSelectedCommands,
  setOriginalSelectedCommands,
} from '../../../redux/reducers/commands.reducer';
import { setStateTranslators } from '../../../redux/reducers/translators.reducer';
import { errorHighlight } from '../../../app/constants';
import { commandsSenderTypes } from '../../../constants/commands';
import { ContentPageTabProps } from '../../../models/common';
import TabsPage from '../../../components/TabsPage';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import AttachCommandsDrawer from '../../../containers/RightDrawer/AttachCommands/AttachCommandsDrawer';
import useStyles from '../../deviceContent/styles';

const DeviceTypeCommands: React.FC<ContentPageTabProps> = ({ goToItem }) => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCommands = useAppSelector(
    (state: RootState) => state.commands.selectedCommands,
  );
  const originalCommands = useAppSelector(
    (state: RootState) => state.commands.originalSelectedCommands,
  );
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const userCompanies = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );

  const translators = useAppSelector(
    (state: RootState) => state.translators.translators,
  );

  useEffect(() => {
    if (!translators.length) {
      Translators.list().then((response) => {
        dispatch(setStateTranslators(response.translators));
      });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: '_id',
      flex: 0.7,
      headerName: 'ID',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <TypographyWithCopy
          dataCy={`device-type-command-${params.row._id}`}
          text={params.row._id}
          typographyVariant="button"
        />
      ),
    },
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Typography
          noWrap
          variant="button"
          fontWeight={700}
          component="div"
          className={classes.linkButton}
          onClick={goToItem('command', params.row._id)}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'company_id',
      flex: 0.5,
      headerName: 'Account',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            userCompanies.find(
              (company) => company._id === params.row.company_id,
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'sender_type',
      flex: 1,
      headerName: 'Sender Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{commandsSenderTypes[params.row.sender_type]}</strong>
      ),
    },
    {
      field: 'translator_id',
      flex: 0.5,
      headerName: 'Translator',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            translators.find(
              (translator) => translator._id === params.row.translator_id,
            )?.name
          }
        </span>
      ),
    },
  ];

  const handleOpenCommandsDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseCommandsDrawer = (): void => {
    setOpen(false);
  };

  const handleChooseCommands = (newCommands: Command[]): void => {
    dispatch(setSelectedCommands(newCommands));
    handleCloseCommandsDrawer();
    setLoading(true);

    const attachCommands = newCommands.filter((newCommand) =>
      originalCommands.every(
        (originalPolicy) => newCommand._id !== originalPolicy._id,
      ),
    );
    const detachCommands = originalCommands.filter((command) =>
      newCommands.every((newCommand) => command._id !== newCommand._id),
    );

    Promise.all([
      Promise.all(
        attachCommands.map(async (attachCommand) => {
          await Commands.assignToDeviceType(attachCommand._id, id as string);
        }),
      ),
      Promise.all(
        detachCommands.map(async (detachCommand) => {
          await Commands.removeFromDeviceType(detachCommand._id, id as string);
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalSelectedCommands(newCommands));
        dispatch(
          setAlert({
            highlight: 'Managing commands',
            message: 'Commands successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing commands.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      DeviceTypes.getCommands(editableDeviceType._id)
        .then((response) => {
          dispatch(setSelectedCommands(response));
          dispatch(setOriginalSelectedCommands(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  const handleCreateCommand = (): void => {
    goToItem('command')();
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedCommands}
        addButtonLabel="Manage Commands"
        addButtonIcon={false}
        onAddClick={handleOpenCommandsDrawer}
        createButtonLabel="Create Command"
        onCreateClick={handleCreateCommand}
        tableTitle="Commands attached"
        loading={loading}
      />
      <AttachCommandsDrawer
        open={open}
        itemCommands={true}
        isDeviceCommands={false}
        handleCloseDrawer={handleCloseCommandsDrawer}
        handleChooseCommands={handleChooseCommands}
      />
    </Box>
  );
};

export default DeviceTypeCommands;
