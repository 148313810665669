import React from 'react';
import { Grid } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import CodeEditor from '../../../../../components/CodeEditor';

interface ExecuteWorkflowSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
}

const ExecuteWorkflowSection: React.FC<ExecuteWorkflowSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
}) => {
  return (
    <Grid item xs={12}>
      <CodeEditor
        label="Context Template"
        prop={`${actionIndex}.body_template`}
        onInputChange={onInputChange}
        value={action.body_template}
        height={400}
      />
    </Grid>
  );
};

export default ExecuteWorkflowSection;
