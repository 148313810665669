import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

import { StatusTheme } from '../../models/common';
import RightDrawer from '../RightDrawer/RightDrawer';
import ColoredBox from '../ColoredBox';
import UserAvatar from '../UserAvatar';
import useStyles from './styles';

interface DetailsDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  title: string;
  subtitle1?: string;
  subtitle1Label?: string;
  subtitle2?: string;
  subtitle2Label?: string;
  date?: string;
  badgeText?: string;
  badgeColor?: string;
  type?: string;
  userName: string;
  dateLabel?: string;
}
const DetailsDrawer: React.FC<DetailsDrawerProps> = ({
  open,
  handleCloseDrawer,
  title,
  badgeColor = 'info',
  badgeText,
  type = 'command_execution_state',
  date,
  subtitle1,
  subtitle1Label,
  subtitle2,
  subtitle2Label,
  userName,
  dateLabel = 'Executed At',
  children,
}) => {
  const classes = useStyles();

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      titleComponent={
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="h5" marginBottom={0}>
            {`${title}`}
          </Typography>
          {badgeText && (
            <ColoredBox
              colorTheme={badgeColor as StatusTheme}
              type={type}
              value={badgeText}
              className="ml-2"
            />
          )}
        </Box>
      }
      content={
        <Grid container spacing={2}>
          {date && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="custom-label">
                {dateLabel}
              </Typography>
              <Typography
                variant="subtitle2"
                className="custom-label"
                data-testid="date"
              >
                {date}
              </Typography>
            </Grid>
          )}
          {subtitle1 && (
            <Grid item xs={12} md={6}>
              {subtitle1Label && (
                <Typography variant="h6" className="custom-label">
                  {subtitle1Label}
                </Typography>
              )}
              <Typography variant="subtitle2" className={classes.subtitle}>
                <p className="my-1">{subtitle1}</p>
              </Typography>
            </Grid>
          )}
          {subtitle2 && (
            <Grid item xs={12} md={6}>
              {subtitle2Label && (
                <Typography variant="h6" className="custom-label">
                  {subtitle2Label}
                </Typography>
              )}
              <Typography variant="subtitle2" className={classes.subtitle}>
                <p className="my-1">{subtitle2}</p>
              </Typography>
            </Grid>
          )}
          {userName && (
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className="custom-label">
                Created By
              </Typography>
              <UserAvatar name={userName} />
            </Grid>
          )}

          {children}
        </Grid>
      }
    />
  );
};

export default DetailsDrawer;
