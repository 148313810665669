import React, { useCallback, useState } from 'react';

import { Box } from '@mui/system';
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import { MetadataFilter } from '../../../models/common';
import {
  METADATA_ARRAY_OPERATORS,
  METADATA_OPERATORS,
} from '../../../app/constants';
import {
  getMetadataFilterKey,
  parseMetadataFilters,
  updateMetadataFilters,
} from '../../../helpers/metadata';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';

interface DeviceMetadataFilterProps {
  metadataFilters: MetadataFilter[];
  onFiltersChange: (
    prop: string,
    value: string | { [key: string]: string },
  ) => void;
}

const DeviceMetadataFilter: React.FC<DeviceMetadataFilterProps> = ({
  metadataFilters,
  onFiltersChange,
}) => {
  const [metadataItems, setMetadataItems] =
    useState<MetadataFilter[]>(metadataFilters);

  const updateMetadataItems = (metadata: MetadataFilter[]): void => {
    onFiltersChange('metadata', parseMetadataFilters(metadata));
  };

  const updateMetadataDebounce = useCallback(
    debounce(updateMetadataItems, 300),
    [],
  );

  const handleChange = (
    index: number,
    key: string,
    changeValue: string | string[],
  ): void => {
    const auxArray = updateMetadataFilters(
      metadataItems,
      index,
      key,
      changeValue,
    );
    setMetadataItems(auxArray);
    updateMetadataDebounce.cancel();
    updateMetadataDebounce(auxArray);
  };

  const handleRemove = (index: number): void => {
    const auxArray = [...metadataItems];
    auxArray.splice(index, 1);
    setMetadataItems(auxArray);
    onFiltersChange('metadata', parseMetadataFilters(auxArray));
  };

  const addNewMetadata = (): void => {
    setMetadataItems([
      ...metadataItems,
      { key: '', operator: 'eq', value: '' },
    ]);
  };

  const handleInputChange =
    (index: number, prop: string) =>
    (_name: string, changeValue: string | number): void => {
      handleChange(index, prop, changeValue as string);
    };

  const handleRemoveClick = (index: number) => (): void => {
    handleRemove(index);
  };

  const renderRemoveButton = (index: number): JSX.Element => (
    <Grid item xs={1} className="mt-6">
      <IconButton onClick={handleRemoveClick(index)}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  );

  const handleArrayChange =
    (index: number) =>
    (_e: React.SyntheticEvent, value: string[] | null): void => {
      if (value) {
        handleChange(index, 'value', value);
      }
    };

  return (
    <Box>
      {metadataItems.map((item, index) => {
        const isArray = METADATA_ARRAY_OPERATORS.includes(item.operator);
        return (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className={clsx({
              ['mt-2']: index === 0,
            })}
            alignItems="center"
          >
            <Grid item xs={4}>
              <TextInput
                label="Key"
                prop="key"
                value={getMetadataFilterKey(item.key)}
                onInputChange={handleInputChange(index, 'key')}
              />
            </Grid>
            <Grid item xs={2}>
              <SelectInput
                label="Operator"
                prop="operator"
                value={item.operator}
                onSelectChange={handleInputChange(index, 'operator')}
                options={Object.keys(METADATA_OPERATORS).map((operator) => (
                  <MenuItem
                    dense
                    key={`metadata-filter-${operator}-${index}`}
                    value={operator}
                  >
                    {METADATA_OPERATORS[operator]}
                  </MenuItem>
                ))}
              />
            </Grid>
            {!isArray ? (
              <Grid item xs={5}>
                <TextInput
                  label="Value"
                  prop="value"
                  value={item.value as string}
                  onInputChange={handleInputChange(index, 'value')}
                />
              </Grid>
            ) : (
              <Grid item xs={11}>
                <Typography variant="body2" className="custom-label">
                  Values
                </Typography>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  freeSolo
                  value={((item.value as string[]) ?? []).map(
                    (option) => option,
                  )}
                  options={[] as string[]}
                  onChange={handleArrayChange(index)}
                  renderTags={(tagValue: readonly string[], getTagProps) =>
                    tagValue.map((option: string, subIndex: number) => {
                      const { key, ...tagProps } = getTagProps({
                        index: subIndex,
                      });
                      return (
                        <Chip
                          variant="outlined"
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Add values"
                      helperText="Press enter to add value"
                    />
                  )}
                />
              </Grid>
            )}
            {renderRemoveButton(index)}
            {index !== metadataItems.length - 1 && (
              <Grid item xs={12} className="my-2">
                <Divider />
              </Grid>
            )}
          </Grid>
        );
      })}
      <Button
        variant="outlined"
        size="medium"
        className="mt-6"
        onClick={addNewMetadata}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </Box>
  );
};

export default DeviceMetadataFilter;
