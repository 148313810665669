import React from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';

import { INGESTOR_HANDLER_TYPES_MAP } from '../../../constants/ingestors';
import { checkHandlerTypeDisabled } from '../../../containers/Forms/IngestorForm/helper';
import HandlerTypeDynamicSections from '../../../containers/Forms/IngestorForm/HandlerTypeDynamicSections/HandlerTypeDynamicSections';
import SelectInput from '../../../components/SelectInput';

interface NewIngestorHandlerConfigProps {
  newIngestor: IngestorInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  setEnableSubmit?: (args: boolean) => void;
  addRoute: () => void;
  removeRoute: (index: number) => void;
}

const NewIngestorHandlerConfig: React.FC<NewIngestorHandlerConfigProps> = ({
  newIngestor,
  onInputChange,
  addRoute,
  removeRoute,
}) => {
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due the ingestor type and listener type
          compatibility.
        </Typography>
        <SelectInput
          prop="handler_type"
          value={newIngestor.handler_type}
          onSelectChange={onInputChange}
          options={Object.keys(INGESTOR_HANDLER_TYPES_MAP).map((key, index) => (
            <MenuItem
              disabled={checkHandlerTypeDisabled(
                newIngestor.type,
                newIngestor.listener_type,
                key,
              )}
              className="m-4 p-2"
              key={index}
              dense
              value={key}
            >
              {INGESTOR_HANDLER_TYPES_MAP[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <HandlerTypeDynamicSections
        newIngestor={newIngestor}
        onInputChange={onInputChange}
        addRoute={addRoute}
        removeRoute={removeRoute}
      />
    </Grid>
  );
};

export default NewIngestorHandlerConfig;
