import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  PaginationFilter,
  GatewayCommand,
  GatewayCommands,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setSorting } from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setGatewayCommands } from '../../redux/reducers/gatewayCommands';
import { defaultItemsPerPage, errorHighlight } from '../../app/constants';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import {
  getChildExecutions,
  getCommandExecutionStatus,
} from '../../helpers/executionStatuses';
import { OLDEST_NEWEST_SORTING } from '../../constants/sortings';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import { HeartBeatColumns } from '../../pages/messages/gatewayCommandsMessages/columns';
import HeartbeatFilters from '../../pages/messages/gatewayCommandsMessages/GatewayCommandsMessagesFilters';
import useStyles from '../../pages/messages/styles';
import CommandExecutionDrawer from '../CommandExecutionDrawer';

const GatewayCommandsMessages: React.FC = () => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const gatewayCommandsState = useAppSelector(
    (state: RootState) => state.gatewayCommands,
  );

  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.gatewayCommands.sortBy, OLDEST_NEWEST_SORTING),
  );

  const [commands, setCommands] = useState<GatewayCommand[]>(
    gatewayCommandsState.gatewayCommands,
  );

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [selectedGatewayCommand, setSelectedGatewayCommand] = useState<
    undefined | GatewayCommand
  >();

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getCommands = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    GatewayCommands.list(
      parseFilters(
        filters.gatewayCommands.filters ?? {},
        filters.case_sensitive,
      ),
      pagination,
    )
      .then((result) => {
        const results: GatewayCommand[] = addPage
          ? [...commands, ...result.gatewayCommands]
          : result.gatewayCommands;

        setCommands(results);
        dispatch(setGatewayCommands(results));
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getCommands(1);
  }, [filters.gatewayCommands]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getCommands(page + 1, true);
  };
  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'gatewayCommands'));
    setSelectedSorting(option);
  };

  const handleOpenExecutionInformationDrawer = (item: GatewayCommand): void => {
    setSelectedGatewayCommand(item);
    setOpenInfoDrawer(true);
  };

  const handleActionClick = (item: GatewayCommand): void => {
    handleOpenExecutionInformationDrawer(item);
  };

  const handleCloseCommandExecutionDrawer = (): void => {
    setOpenInfoDrawer(false);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <HeartbeatFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={'list'}
        sortingOptions={OLDEST_NEWEST_SORTING}
        viewsOptions={[]}
        itemsSelected={false}
        allSelected={false}
        deleteAction={true}
        xPadding={false}
        sortingCallback={handleSorting}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <SharedTable
            columns={HeartBeatColumns(handleActionClick)}
            rows={commands}
            sortBy={selectedSorting.value}
            sortDirection={
              selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
            }
            allSelected={false}
            loading={loading}
            selectedItemsIds={[]}
            xPadding={false}
            onRequestSort={handleTableSorting}
            viewOnly={true}
          />

          {commands.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      {selectedGatewayCommand && (
        <CommandExecutionDrawer
          open={openInfoDrawer}
          handleCloseDrawer={handleCloseCommandExecutionDrawer}
          childExecutions={getChildExecutions(selectedGatewayCommand)}
          executionStatus={getCommandExecutionStatus(selectedGatewayCommand)}
          executedBy={selectedGatewayCommand.user_id}
          executionDate={selectedGatewayCommand.created_at || ''}
          title="Gateway Command Execution"
          subtitle1={selectedGatewayCommand.device_unique_id}
          subtitle1Label="Device"
          subtitle2={selectedGatewayCommand?.command_type}
          subtitle2Label="Gateway command type"
        />
      )}
    </Grid>
  );
};

export default GatewayCommandsMessages;
